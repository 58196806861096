import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const customerReducer = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomersListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setCustomerData: (state, action) => {
      const index = state.data.findIndex(customer => customer.customerId === action.payload.customerId);
      const customersListData = [...state.data];
      customersListData[index] = action.payload;
      return {
        ...state,
        data: customersListData
      }
    },
    addCustomerData: (state, action) => {
      const customersListData = [...state.data];
      customersListData.push(action.payload);
      return {
        ...state,
        data: customersListData
      }
    },
  },
});

export const {
  setCustomersListData,
  setCustomerData,
  addCustomerData,
} = customerReducer.actions;

export default customerReducer.reducer;