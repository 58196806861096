export const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    // borderColor: '#9e9e9e',
    minHeight: '32px',
    height: '32px',
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '32px',
    padding: '0 6px'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '32px',
  }),
  dropdownIndicator: styles => ({ 
    ...styles, 
    color: '#63686c', 

  })
};

export const wysiwygOptions = ['inline', 'blockType', 'fontSize', 'fontFamily', 'colorPicker', 'link', 'list', 'textAlign', 'history'];
