import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { fetchDashboardStats } from '../../services/dashboardServices';
import { getOutstandingInvoicesByYear, getPaidInvoicesByYear, getTotalCustomersByYear, getUnsettledQuotesByYear } from '../../utils/dashboardUtils';
import moment from 'moment';
import Customers from './charts/Customers';
import Invoice from './charts/Invoice';
import Quotes from './charts/Quotes';
import { 
  DescriptionOutlined,
  PeopleOutlineOutlined,
  RequestQuoteOutlined,
  AccountBalanceWalletOutlined,
} from "@mui/icons-material";


function Dashboard() {
  const userState = useSelector((state) => state.auth.user);
  const [dashboardData, setDashboardData] = useState({});

  const fillDashboardStats = async () => {
    const currentFiscalYear = moment().year();
    const response = await fetchDashboardStats(userState?.accountId);
    setDashboardData({
      customersCount: getTotalCustomersByYear(response.customer, currentFiscalYear),
      unsettledQuotesCount: getUnsettledQuotesByYear(response.quote, currentFiscalYear),
      outstandingInvoicesCount: getOutstandingInvoicesByYear(response.invoice, currentFiscalYear),
      paidInvoicesCount: getPaidInvoicesByYear(response.invoice, currentFiscalYear),
      customerData: response.customer,
      quotesData: response.quote,
    })
  }
  
  useEffect(() => {
    fillDashboardStats();
  }, [])

  return (
    <div className='dashboard-container'>
      {/* Summary Stats */}
      <div className='dashboard-stats-container mb-4'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-3 mb-2'>
            <div className="card shadow-sm">
              <div className="card-body py-3">
                <div className='d-flex align-items-center'>
                  <div className='text-center'>
                    <div className='stats-icon-container d-flex align-items-center justify-content-center'>
                      <PeopleOutlineOutlined/>
                    </div>
                  </div>
                  <div className='ms-3'>
                    <p className="stats-label mb-0">Customers</p>
                    <p className="stats-count mb-0">{dashboardData?.customersCount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-3 mb-2'>
            <div className="card shadow-sm">
              <div className="card-body py-3">
                <div className='d-flex align-items-center'>
                  <div className='ctext-center'>
                    <div className='stats-icon-container d-flex align-items-center justify-content-center'>
                      <DescriptionOutlined/>
                    </div>
                  </div>
                  <div className='ms-3'>
                    <p className="stats-label mb-0">Unsettled Quotes</p>
                    <p className="stats-count mb-0">{dashboardData?.unsettledQuotesCount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-3 mb-2'>
            <div className="card shadow-sm">
              <div className="card-body py-3">
                <div className='d-flex align-items-center'>
                  <div className='text-center'>
                    <div className='stats-icon-container d-flex align-items-center justify-content-center'>
                      <RequestQuoteOutlined/>
                    </div>
                  </div>
                  <div className='ms-3'>
                    <p className="stats-label mb-0">Outstanding Invoices</p>
                    <p className="stats-count mb-0">{dashboardData?.outstandingInvoicesCount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-3 mb-2'>
            <div className="card shadow-sm">
              <div className="card-body py-3">
                <div className='d-flex align-items-center'>
                  <div className='text-center'>
                    <div className='stats-icon-container d-flex align-items-center justify-content-center'>
                      <AccountBalanceWalletOutlined/>
                    </div>
                  </div>
                  <div className='ms-3'>
                    <p className="stats-label mb-0">Placeholder</p>
                    <p className="stats-count mb-0">-</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Charts */}
      <div className='dashboard-charts-container'>
        <div className='row'>
          <div className='col-12 col-md-4 mb-4'>
            <div className="card shadow-sm border border-0">
              <div className="card-body py-3">
                <p className='fw-bold'>Invoice</p>
                <Invoice paidCount={dashboardData?.paidInvoicesCount} unpaidCount={dashboardData?.outstandingInvoicesCount} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-8 mb-4'>
            <div className="card shadow-sm border border-0">
              <div className="card-body py-3">
                <p className='fw-bold'>Customers</p>
                <Customers customers={dashboardData?.customerData}/>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-12 mb-4'>
            <div className="card shadow-sm border border-0">
              <div className="card-body py-3">
                <p className='fw-bold'>New Quotes</p>
                <Quotes quotes={dashboardData?.quotesData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard
