import { get, post, patch } from "./apiService";

export const fetchPayments = async (invoiceId) => {
  return get({ path: `Payment/invoice/${invoiceId}` });
}

export const fetchPaymentsByExpenseId = async (expenseId) => {
  return get({ path: `Payment/expense/${expenseId}` });
}

export const addPayment = async (payload) => {
  return post({ path: 'Payment', body: payload });
}

export const updatePayment = async (payload) => {
  return patch({ path: `Payment/update/${payload.paymentId}`, body: payload });
}