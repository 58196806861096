import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

function PayrollRunModal({
  modal,
  toggle,
  togglePayrollRun,
  runPayroll,
  args
}) {

  return (
    <Modal isOpen={modal} toggle={toggle} {...args}>
      <ModalHeader toggle={toggle}>Run the Payroll</ModalHeader>
      <ModalBody>
        Do you want to run the payroll?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" type="button" onClick={() => runPayroll()}
        >
          Yes
        </Button>{' '}
        <Button color="secondary" type="button" onClick={() => togglePayrollRun()}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default PayrollRunModal