import React, { useState, useRef, useEffect, memo, forwardRef, useImperativeHandle } from 'react';
import { Input } from 'reactstrap';
import { PAYMENT_TYPES } from '../../../constants';

export default memo(forwardRef((props, ref) => {

  const refInput = useRef(null);
  const [value, setValue] = useState(0);
  const originalValue = props.data["Mode of Payment"]

  useEffect(() => {
    // focus on the input
    setTimeout(() => {
      refInput.current.focus()
    }, 100);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {

        if (value === undefined) {
          return parseInt(originalValue) || 0
        }
        return parseInt(value);
      },
    };
  });

  return (
    <div className="cell-editor">
      <Input
        id="payMethodType"
        name="select"
        type="select"
        defaultValue={props?.value || 0}
        innerRef={refInput}
        className="cell-editor-input"
        onChange={(event) => setValue(event.target.value)}
        autoFocus
      >
        <option key="none" value="" hidden>Pay Method Type</option>
        {PAYMENT_TYPES.map((method, index) =>
          <option key={index} value={method.value}>{method.label}</option>
        )}
      </Input>
    </div>
  )
}));
