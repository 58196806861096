import React from 'react'
import { formatCurrency } from '../../utils/formatUtils';
function InvoiceDetailFooter({
  note,
  subtotal,
  discount,
  total,
  tax
}) {

  const isNonEmptyString = (value) => typeof (value) == 'string' && value.length > 0
  return (
    <div className="row quote-footer">
      <div className='col-12'>
        <></>
      </div>
      <div className="col-6">
        <div className="form-group">
          <></>
        </div>
      </div>
      <div className="col-6">
        <div className='row text-center'>
          <div className="col-6">
            <p className='mb-0 fw-bold'>Subtotal</p>
            <p>{formatCurrency(subtotal)}</p>
          </div>
          <div className="col-6">
            <p className='mb-0 fw-bold'>Discount</p>
            <p>{formatCurrency(discount)}</p>
          </div>
          <div className="col-6">
            <p className='mb-0 fw-bold'>Total</p>
            <p>{formatCurrency(total)}</p>
          </div>
          <div className="col-6">
            <p className='mb-0 fw-bold'>Tax</p>
            <p>{formatCurrency(tax)}</p>
          </div>
        </div>
      </div>
      {isNonEmptyString(note) && (
        <div className='col-12'>
          <p className='mb-0 fw-bold'>Notes:</p>
          <div className='notes-container'>
            {note}
          </div>
        </div>
      )}

    </div>
  )
}

export default InvoiceDetailFooter