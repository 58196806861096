import { get, getWithBody, post, patch, remove } from "./apiService";

export const fetchPayrollRuns = async () => {
  return get({ path: 'PayrollRun' });
}

export const fetchPayrollRun = async (payrollRunId) => {
  return get({ path: `PayrollRun/${payrollRunId}` });
}

export const fetchPayrollRunsFiltered = async (accountId) => {
  return get({ path: `PayrollRun/filtered` });
}

export const fetchApprovedPayrollRunsFiltered = async (accountId) => {
  return get({ path: `PayrollRun/approved` });
}

export const deletePayrollRun = async (payrollRunId) => {
  return remove({ path: `PayrollRun/${payrollRunId}` });
}

export const fetchPayrollRunLineItems = async (state) => {
  return get({ path: `PayrollRun/PayrollRunLineItems/${state.payrollRunId}` });
}

export const fetchPayrollRunLineItemsByUserAndDateRange = async (body) => {
  return post({ path: `PayrollRun/PayrollRunLineItemsByUserAndDateRange`, body:body });
}

export const fetchRunPayroll = async (payload, state) => {
  return patch({ path: `PayrollRun/runPayroll/${state.payrollRunId}`, body: payload });
}

export const fetchVoidLastPayrollRun = async (payload) => {
  return patch({ path: `PayrollRun/voidLastPayrollRun`, body: payload });
}

export const fetchVoidPayrollRun = async (id) => {
  return patch({ path: `PayrollRun/voidPayrollRun/${id}` });
}


export const addPayrollRun = async (payload) => {
  return post({ path: 'PayrollRun', body: payload });
}

export const addOneOffPayrollRun = async (payload) => {
  return post({ path: 'PayrollRun/oneOffPayrollRun', body: payload });
}

export const updatePayrollRunLineItem = async (payload, id) => {
  return patch({ path: `PayrollRun/payrollRunLineItem/update/${id}`, body: payload });
}

export const deletePayrollRunLineItem = async (id) => {
  return remove({ path: `PayrollRun/payrollRunLineItem/delete/${id}` });
}

export const fetchPayrollRunLineItemPayItems = async (id) => {
  return get({ path: `PayrollRun/PayrollRunLineItemPayItem/${id}` });
}

export const addPayrollRunLineItemPayItem = async (payload) => {
  return post({ path: `PayrollRun/payrollRunLineItemPayItem/`, body: payload });
}

export const updatePayrollRunLineItemPayItem = async (payload, id) => {
  return patch({ path: `PayrollRun/payrollRunLineItemPayItem/update/${id}`, body: payload });
}

export const deletePayrollRunLineItemPayItem = async (id) => {
  return remove({ path: `PayrollRun/payrollRunLineItemPayItem/delete/${id}` });
}