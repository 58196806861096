import React, { useState } from 'react'
import ContentHeader from '../Commons/Layouts/ContentHeader';
import { isAccountUser } from '../../utils/roleUtils';
import { useSelector } from 'react-redux';
import AccountingSettingsForm from './AccountingSettingsForm';
import AuthorizationError from '../Commons/Authorization/AuthorizationError';
function AccountingSettings() {
  const userType = useSelector((state) => state.auth.user.userType);

  // useStates
  const [accountList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(true);

  return (
    <div>
      {isAccountUser(userType) &&
        (<AuthorizationError/>)
      }
      {!isAccountUser(userType) &&
        <>
          <div className={`content-header-container d-flex flex-row align-items-center`}>
            <div className='me-auto'>
              <ContentHeader title="Accounting Settings" dataCount={accountList.length} hideSearch={true} hideTotal={true} />
            </div>
          </div>
          <div className="content-body-container row">
            <div className={`col-12 mb-2`}>
              <AccountingSettingsForm
                isFormEdit={isFormEdit}
                setIsFormEdit={setIsFormEdit}
              />
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default AccountingSettings