import React from 'react';

const EmployeeNameTooltip = (props) => {

  const itemData = props.data['data'];
  return (
    itemData.length > 1 ?
    <div
      className="ag-custom-tooltip"
    >
        <span>This employee has additional pay line items</span>
    </div>
    :
    <div></div>
  );
};

export default EmployeeNameTooltip