import React, { useEffect } from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import moment from 'moment';
import { showToast } from '../../../utils/toastUtils';
import ReactDatePicker from "react-datepicker";
import { current } from '@reduxjs/toolkit';
import { updateQuote } from '../../../services/quoteServices';

function EditQuoteModal({
  modal,
  toggle,
  currentQuote,
  fillQuoteData,
  fillTableData,
  args
}) {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    setFocus,
    control,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    setValue("quoteDate", moment(currentQuote['quoteDate']).hour(7).minute(0).second(0).toDate());
    setValue("paymentDueDate", moment(currentQuote['paymentDueDate']).hour(7).minute(0).second(0).toDate());
  }, [currentQuote])

  const onSubmit = async (payload) => {
    let response = null;

    var quoteDate = moment(payload.quoteDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    var paymentDueDate = moment(payload.paymentDueDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');

    _.set(payload, 'quoteDate', quoteDate);
    _.set(payload, 'paymentDueDate', paymentDueDate);

    _.set(payload, 'quoteId', currentQuote.quoteId);
    _.set(payload, 'accountId', currentQuote.accountId);

    response = await updateQuote(payload);

    if (response.ok) {
      await fillQuoteData();
      await fillTableData();
      toggle();
    }

    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully updated' : 'Failed to update'} quote.`
    });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size='lg' backdrop='static' {...args}>
      <ModalHeader toggle={toggle}>Edit Quote</ModalHeader>
      <ModalBody>
        <form className="row form-container" id="Quote-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-6 mb-2">
            <label htmlFor="quoteName" className="form-label">Invoice Id</label>
            <input type="text" className="form-control" id="quoteName"
              defaultValue={currentQuote?.quoteName || ''}
              {...register("quoteName", { required: "Invoice No. is required" })}
            />
            <small className='form-error-message'>
              {errors?.quoteName && errors.quoteName.message}
            </small>
          </div>

          <div className="col-6 mb-2">
            <label htmlFor="invoiceDate" className="form-label">Invoice Date:</label>
            <Controller
              defaultValue={new Date()}
              control={control}
              name='quoteDate'
              register={register}
              rules={{ required: "Quote date is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactDatePicker
                  className='datepicker-field'
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  id="quoteDate"
                  dateFormat='yyyy-MM-dd'
                  ref={(elem) => {
                    elem && ref(elem.input);
                  }}
                />
              )}
            />
            <small className='form-error-message'>
              {errors?.quoteDate && errors.quoteDate.message}
            </small>
          </div>

          <div className="col-6 mb-2">
            <label htmlFor="paymentDueDate" className="form-label">Invoice Due Date:</label>
            <Controller
              defaultValue={new Date()}
              control={control}
              name='paymentDueDate'
              register={register}
              rules={{ required: "Invoice due date is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactDatePicker
                  className='datepicker-field'
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  id="paymentDueDate"
                  dateFormat='yyyy-MM-dd'
                  ref={(elem) => {
                    elem && ref(elem.input);
                  }}
                />
              )}
            />
            <small className='form-error-message'>
              {errors?.payrollRunDate && errors.payrollRunDate.message}
            </small>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => { handleSubmit(async (payload) => await onSubmit(payload))() }}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditQuoteModal