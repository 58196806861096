import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { MENU_ITEMS } from '../../constants';
import { isSuperAdmin } from '../../utils/roleUtils'
import Cookies from 'universal-cookie';
import { useIdleTimer } from 'react-idle-timer';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReportsMenu from './ReportsMenu';


function NavMenu() {
  const { logout } = useAuth0();
  const [active, setActive] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const authState = useSelector((state) => state.auth);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const cookies = new Cookies();

  const clearStorages = () => {
    localStorage.clear();
    sessionStorage.clear();
    cookies.remove('token');
  }

  const handleLogout = (event) => {
    event.preventDefault();
    clearStorages();
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI
      }
    })
  }

  const onIdle = () => {
    if (authState?.isAuthenticated) {
      clearStorages();
      setIsModalVisible(true);
    }
  }

  useIdleTimer({
    onIdle,
    timeout: (3600 * 1000),
    throttle: 500,
    crossTab: true,
    stopOnIdle: true
  });

  const handleRedirect = (event) => {
    event.preventDefault();
    navigate("/");
  }

  return (
    <>
      <nav className="navbar bg-white shadow-sm non-printable">
        <div className="container-fluid">
          <a className="navbar-brand" href="/#" onClick={handleRedirect}>
            <img src="/images/logo.png" alt="ABM Tax Logo" />
          </a>

          {(accountState.accountName !== undefined && !isSuperAdmin(authState?.user?.userType)) && (
            <div className="me-5 selected-account ms-auto me-2">
              {accountState.accountName}
            </div>
          )}
          {authState?.isAuthenticated && (
            <>
              <div className="navbar-profile dropdown-center ms-auto me-3 d-none d-lg-block">
                <div className="dropdown-toggle d-flex align-items-center" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa-solid fa-file-export icon me-2"></i> Reports
                </div>
                <ul className="dropdown-menu dropdown-menu-end">
                  <ReportsMenu />
                </ul>
              </div>
              
              <div className="navbar-profile dropdown ms-3 me-4 d-none d-lg-block">
                <div className="dropdown-toggle d-flex align-items-center" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa-solid fa-user icon me-2"></i> {`${authState?.user?.firstName} ${authState?.user?.lastName}`}
                </div>

                <ul className="dropdown-menu dropdown-menu-end">
                  <li><a className="dropdown-item" href="/" onClick={handleLogout}>Logout</a></li>
                </ul>
              </div>
            </>
          )}
          <button className="navbar-toggler ms-auto d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMenuItems" aria-controls="collapseDirectory" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapseMenuItems">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                {MENU_ITEMS.map((menuItem, index) => (
                  <Link className={`nav-link text-center ${menuItem.disabled ? 'disabled' : ''} ${active === index ? 'active' : ''}`}
                    to={menuItem.link}
                    key={index}
                    onClick={() => setActive(index)}
                  >
                    {menuItem.label}
                  </Link>
                ))}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Modal isOpen={isModalVisible} backdrop='static' centered>
        <ModalHeader cssModule={{ 'modal-title': 'w-100 text-center' }}>Session Expired</ModalHeader>
        <ModalBody>
          <p className='text-center my-3'>
            Your session has timedout due to inactivity. <br />
            Please login again to proceed.
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            className={`btn btn-primary`}
            type="submit"
            onClick={handleLogout}
          >
            Confirm
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default NavMenu;