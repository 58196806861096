import React from 'react';

function EmployeeNameRenderer(props) {
  let employee = props.value;
  const data = props.data["data"];

  if(data.length > 1) {
    employee = employee + '*'
  }

  return (
    <>
      <span>{employee}</span>
    </>
  )
}

export default EmployeeNameRenderer