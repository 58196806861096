import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import ContentHeader from '../Commons/Layouts/ContentHeader';
import ProfilePictureRenderer from '../Templates/CellRenderers/Commons/ProfilePictureRenderer';
import { formatCurrency, formatDate, formatDateOnly } from '../../utils/formatUtils';
import _ from 'lodash';
import ExpenseDetails from './ExpenseDetails';
import ExpensePaymentsForm from './ExpensePaymentsForm';
import { useLocation } from 'react-router-dom';
import { isSuperAdmin, isABMUser } from '../../utils/roleUtils';
import { useSelector } from 'react-redux';
import PaymentTypeRenderer from '../Templates/CellRenderers/Payments/PaymentTypeRenderer';
import { fetchExpenses, fetchExpensesFiltered } from '../../services/expenseServices';
import { fetchPaymentsByExpenseId } from '../../services/paymentServices';
import { getExpenseBalance } from '../../utils/paymentUtils';

function ExpensePayments() {
  const { state } = useLocation();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);

  const defaultExpenseColumnDefs = [
    { field: '#', width: 50, minWidth: 50, suppressSizeToFit: true, cellStyle: { fontWeight: 'bold' } },
    { field: 'ProfilePicture', minWidth: 70, headerName: '', cellRenderer: ProfilePictureRenderer, width: 80, suppressSizeToFit: true },
    { field: 'Description', minWidth: 150, getQuickFilterText: params => { return params.value; } },
    { field: 'Total', minWidth: 110, getQuickFilterText: params => { return params.value; } },
    { field: 'Balance', minWidth: 110, getQuickFilterText: params => { return params.value; } },
  ]

  const defaultPaymentColumnDefs = [
    { field: '#', width: 50, minWidth: 50, suppressSizeToFit: true, cellStyle: { fontWeight: 'bold' } },
    { field: 'Amount', minWidth: 110, getQuickFilterText: params => { return params.value; } },
    { field: 'Payment Type', headerName: "Mode of Payment", minWidth: 110, cellRenderer: PaymentTypeRenderer, getQuickFilterText: params => { return params.value; } },
    { field: 'Description', minWidth: 110, getQuickFilterText: params => { return params.value; } },
    { field: 'DatePaid', minWidth: 110, getQuickFilterText: params => { return params.value; } },
  ]

  // useStates
  const [expenseRowData, setExpenseRowData] = useState([]);
  const [expenseColumnDefs] = useState(defaultExpenseColumnDefs);
  const [paymentsRowData, setPaymentsRowData] = useState([]);
  const [paymentsColumnDefs] = useState(defaultPaymentColumnDefs);
  const [paymentList, setPaymentList] = useState([]);
  const [expenseList, setExpenseList] = useState([]);
  const [selectedExpense, setSelectedExpense] = useState({});
  const [selectedExpenseId, setSelectedExpenseId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  
  // useRefs
  const expensesGridRef = useRef();
  const paymentsGridRef = useRef();

  // useEffects
  useEffect(() => {
    fillExpenseList();
  }, [])

  useEffect(() => {
    let data = [];
    _.each(expenseList, (expense, index) => {
      data.push({
        '#': index + 1,
        'ExpenseId': expense.expenseId,
        'ProfilePicture': { url: `images/profile-picture-fallback.png` },
        'Description': expense.description,
        'Total': formatCurrency(expense.total),
        'Balance': formatCurrency(getExpenseBalance(expense))
      });
    });
    setExpenseRowData(data);

    if (!_.isNull(_.get(state, 'expenseId', null))) {
      fillSelectedExpense(state.expenseId);
      fillPaymentList(state.expenseId);
    }
  }, [expenseList])

  useEffect(() => {
    if (!_.isNull(_.get(state, 'expenseId', null))) {
      expensesGridRef?.current?.api?.forEachNode((node) => {
        if (node?.data?.ExpenseId === state.expenseId) {
          node.setSelected(true);
        }
      })
    }

    if (!_.isNull(selectedRow)) {
      expensesGridRef?.current?.api?.forEachNode((node) => {
        if (node?.rowIndex === selectedRow) {
          node.setSelected(true);
        }
      })
    }
  }, [expenseRowData])


  useEffect(() => {
    let data = [];
    _.each(paymentList, (payment, index) => {
      data.push({
        '#': index + 1,
        'Amount': formatCurrency(payment.paymentAmount),
        'Description': payment.paymentDescription,
        "Payment Type": payment.paymentType,
        'DatePaid': formatDateOnly(payment.paymentDate)
      });
    });
    setPaymentsRowData(data);
  }, [paymentList])

  useEffect(() => {
    if (!_.isNull(selectedExpenseId)) {
      fillSelectedExpense(selectedExpenseId);
      fillPaymentList(selectedExpenseId);
    }
  }, [selectedExpenseId]);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    expensesGridRef.current.api.sizeColumnsToFit();
    paymentsGridRef.current.api.sizeColumnsToFit();
  }, []);

  const fillExpenseList = async () => {
    let response = null;
    if(isSuperAdmin(userType)) {
      response = await fetchExpenses();
    } else {
      response = await fetchExpensesFiltered();
    }
    setExpenseList(response);
  }

  const fillPaymentList = async (expenseId) => {
    const response = await fetchPaymentsByExpenseId(expenseId);
    setPaymentList(response);
  }

  const fillSelectedExpense = (id) => {
    const expense = _.find(expenseList, { expenseId: id });
    setSelectedExpense(expense);
  }

  const filterData = (searchQuery) => {
    expensesGridRef.current.api.setQuickFilter(searchQuery);
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  }

  const onCellClicked = (event) => {
    setSelectedRow(event.node.rowIndex);
    event.node.setSelected(true);
    paymentsGridRef.current.api.refreshCells();
    setSelectedExpenseId(event.data.ExpenseId);
  }

  return (
    <div>
      <div className='content-header-container d-flex flex-row align-items-center'>
        <div className='me-auto'>
          <ContentHeader title="Payments" hideTotal={true} filterData={filterData} />
        </div>
      </div>
      <div className="content-body-container row">
        <div className={`col-12 col-lg-5 min-vh-25 mb-5`}>
          <div className='form-title mb-2'>
            Expenses
          </div>
          <div className={`ag-theme-alpine content-section-container`}>
            <AgGridReact
              className='no-header'
              rowData={expenseRowData}
              columnDefs={expenseColumnDefs}
              ref={expensesGridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onColumnSizeChanged={onColumnsSizeChanged}
              onGridSizeChanged={onGridSizeChanged}
              onCellClicked={onCellClicked}
              rowHeight={70}
            >
            </AgGridReact>
          </div>
        </div>
        <div className={`col-12 col-lg-7 mb-2`}>
          <div className='row mb-2'>
            <div className='form-title mb-2'>
              Expense Details
            </div>
            <div className='col-12'>
              <div className={`content-section-container`}>
                <ExpenseDetails selectedExpense={selectedExpense} />
              </div>
            </div>
          </div>
          <div className='row mb-2'>
            <div className={`col-12 min-vh-25 mb-5`}>
              <div className='form-title mb-2'>
                Existing Payments
              </div>
              <div className={`ag-theme-alpine content-section-container`}>
                <AgGridReact
                  className='no-header'
                  rowData={paymentsRowData}
                  columnDefs={paymentsColumnDefs}
                  ref={paymentsGridRef}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  onColumnSizeChanged={onColumnsSizeChanged}
                  onGridSizeChanged={onGridSizeChanged}
                  rowHeight={50}
                  overlayNoRowsTemplate={_.isEmpty(selectedExpense) ? 'No payments on this expense' : 'No payments on this expense'}
                >
                </AgGridReact>
              </div>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='form-title mb-2'>
              Submit Payment
            </div>
            <div className='col-12'>
              <div className={`content-section-container min-vh-25`}>
                <ExpensePaymentsForm
                  setIsFormDirty={setIsFormDirty}
                  selectedExpense={selectedExpense}
                  fillPaymentList={fillPaymentList}
                  fillExpenseList={fillExpenseList}
                  modal={modal}
                  setModal={setModal}
                  toggle={toggle} 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpensePayments
