import React, { useState, useRef, useEffect, memo, forwardRef, useImperativeHandle } from 'react'
import { Input } from 'reactstrap'
import { provinceCheck } from '../../../utils/provinceUtils';
import { TAX_TYPES } from '../../../constants';

export default memo(forwardRef((props, ref) => {

  const refInput = useRef(null);
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    // Focus on the input when component mounts
    setTimeout(() => {
      if (refInput.current) {
        refInput.current.focus();
      }
    }, 1000);
  }, []);

  // Function to calculate tax rate based on province and value
  const calculateTaxRate = (province, value) => {
    return provinceCheck(province, value);
  };

  // Handle value change in the Input
  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  // Handle setting province and updating tax rate
  const setProvince = (province, value) => {
    const taxRate = calculateTaxRate(province, value);
    props.data["Tax Rate"] = taxRate;
  };

  // Expose getValue method through useImperativeHandle
  useImperativeHandle(ref, () => ({
    getValue() {
      const selectedProvince = props.province(); // Assuming props.province() returns the selected province
      const parsedValue = parseInt(value); // Parse value to integer if needed
      return parsedValue !== undefined ? parsedValue : parseInt(props.data["Tax Type"]);
    }
  }));

  return (
    <div className="cell-editor">
      <Input
        id="taxable"
        name="select"
        type="select"
        value={value}
        innerRef={refInput}
        className="cell-editor-input"
        onChange={handleValueChange}
        autoFocus
      >
        <option key="none" value="" hidden>Default Tax Code</option>
        {TAX_TYPES.map((taxCode, index) =>
          <option key={index} value={taxCode.value}>{taxCode.label}</option>
        )}
      </Input>
    </div>
  );
}));
