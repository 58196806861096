import React from 'react';

function QuoteTaxableRenderer(props) {
  const taxType = props.data["Tax Type"];
  var display = "tax rate...";
  switch (parseInt(taxType)) {
    case 0:
        display = 'None';
        break;
    case 1:
      display = 'GST';
      break;
    case 2:
      display = 'PST';
      break;
    case 3:
      display = 'HST';
      break;
    case 4:
      display = 'GST/PST';
      break;
    case 5:
      display = 'Other';
      break;
    default:
      display = 'None';
      break;
  }

  return (
    <>
      <span>{display}</span>
    </>
  )
}

export default QuoteTaxableRenderer