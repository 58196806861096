import React from 'react';

function UnitsOfPayRenderer(props) {
  let unitsOfPay = props.value;
  const payMethodType = props.data["Pay Method Type"];
  
  if(payMethodType === 0) {
    unitsOfPay = "n/a"
  } 

  return (
    <>
      <span>{unitsOfPay}</span>
    </>
  )
}

export default UnitsOfPayRenderer