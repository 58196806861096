import React from 'react'
import { formatCurrency } from '../../utils/formatUtils';
function QuoteDetailFooter({
  updateNoteStatus,
  note,
  editable,
  subtotal,
  discount,
  total,
  tax,
  printMode
}) {
  return (
    <div className="row quote-footer">
      <div className={`col-12 ${(!editable || printMode) ? 'd-none' : ''}`}>
        <p className='mb-0 fw-bold non-printable'>Notes:</p>
      </div>
      <div className="col-6">
        <div className={`form-group non-printable ${(!editable || printMode) ? 'd-none' : ''}`}>
          <textarea className="form-control" id="notes" rows="3" onBlur={updateNoteStatus} defaultValue={note} disabled={!editable}></textarea>
        </div>
      </div>
      <div className="col-6">
        <div className='row text-center'>
          <div className="col-6">
            <p className='mb-0 fw-bold'>Subtotal</p>
            <p>{formatCurrency(subtotal)}</p>
          </div>
          <div className="col-6">
            <p className='mb-0 fw-bold'>Discount</p>
            <p>{formatCurrency(discount)}</p>
          </div>
          <div className="col-6">
            <p className='mb-0 fw-bold'>Total</p>
            <p>{formatCurrency(total)}</p>
          </div>
          <div className="col-6">
            <p className='mb-0 fw-bold'>Tax</p>
            <p>{formatCurrency(tax)}</p>
          </div>
        </div>
      </div>
      {(printMode || !editable) && (
        <div className='col-12'>
          <p className='mb-0 fw-bold'>Notes:</p>
          <div className='notes-container'>
            {note || '-'}
          </div>
        </div>
      )}
    </div>
  )
}

export default QuoteDetailFooter