import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import EmployeeForm from './EmployeeForm';
// import './css/contacts.css'
import ContentHeader from '../Commons/Layouts/ContentHeader';
import NameAndEmailRenderer from '../Templates/CellRenderers/Contacts/NameAndEmailRenderer';
import ProfilePictureRenderer from '../Templates/CellRenderers/Commons/ProfilePictureRenderer';
import PhoneAndMobileRenderer from '../Templates/CellRenderers/Commons/PhoneAndMobileRenderer';
import EditFormRenderer from '../Templates/CellRenderers/Commons/EditFormRenderer';
import { fetchUsers, fetchUsersFiltered } from '../../services/userServices';
import { formatPhoneNumber } from '../../utils/formatUtils';
import _ from 'lodash';
import { isSuperAdmin, isABMUser, isAccountAdmin, isAccountUser } from '../../utils/roleUtils';
import { useSelector } from 'react-redux';
import AuthorizationError from '../Commons/Authorization/AuthorizationError';

function Employees() {
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  
  const toggleFormDisplay = (isEdit = false, userId = null) => {
    if (isFormHidden) {
      setSelectedUserId(userId);
    } else {
      setSelectedUserId(null);
    }

    if (modal) {
      setModal(!modal)
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 50);
  }

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    if (isFormDirty) {
      toggle()
      return
    }
    toggleFormDisplay()
  }

  const defaultColumnDefs = [
    { field: '#', width: 50, minWidth: 50, suppressSizeToFit: true, cellClass: 'no-borders', cellStyle: { fontWeight: 'bold' } },
    { field: 'ProfilePicture', minWidth: 70, headerName: '', cellRenderer: ProfilePictureRenderer, width: 80, suppressSizeToFit: true, cellClass: 'no-borders' },
    { field: 'Name', minWidth: 150, cellClass: 'no-borders', getQuickFilterText: params => { return params.value; } },
    { field: 'Account', minWidth: 150, cellClass: 'no-borders', getQuickFilterText: params => { return params.value; } },
    { field: 'Phone', minWidth: 150, cellClass: 'no-borders', valueFormatter: params => { return formatPhoneNumber(params.value) }, getQuickFilterText: params => { return params.value; } },
    { field: 'Mobile', minWidth: 150, cellClass: 'no-borders', valueFormatter: params => { return formatPhoneNumber(params.value) }, getQuickFilterText: params => { return params.value; } },
    { field: 'Email', minWidth: 150, cellClass: 'no-borders', getQuickFilterText: params => { return params.value; } },
    {
      field: 'Edit',
      minWidth: 150,
      headerName: '',
      cellClass: 'd-flex flex-row-reverse',
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay }
    },
  ]

  const columnDefsWithRow = [
    { field: '#', width: 50, minWidth: 50, suppressSizeToFit: true },
    { field: 'ProfilePicture', minWidth: 70, headerName: '', cellRenderer: ProfilePictureRenderer, width: 80, suppressSizeToFit: true },
    { field: 'NameAndEmail', minWidth: 150, headerName: 'Name', cellRenderer: NameAndEmailRenderer },
    { field: 'PhoneAndMobile', minWidth: 150, headerName: 'Contacts', cellRenderer: PhoneAndMobileRenderer },
  ];


  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [userList, setUserList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    fillUserList();
    if(!isAccountUser(userType)) {
      focusOnOpenOrCloseButton();
    }
    
  }, [])

  useEffect(() => {
    setColumnDefs(isFormHidden ? defaultColumnDefs : columnDefsWithRow);
  }, [isFormHidden]);

  useEffect(() => {
    let data = [];
    _.each(userList, (user, index) => {
      data.push({
        '#': index + 1,
        'ProfilePicture': { url: `images/profile-picture-fallback.png` },
        'Name': `${user.firstName} ${user.lastName}`,
        'Email': user.email,
        'Account': user.account.accountName,
        'Phone': user.phone,
        'Mobile': user.cell,
        // 'Customer': user.customer.customerName,
        'PhoneAndMobile': { phone: user.phone, mobile: user.cell },
        'NameAndEmail': { name: `${user.firstName} ${user.lastName}`, email: user.email },
        'Edit': { id: user.userId }
      });
    });
    setRowData(data);
  }, [userList])

  useEffect(() => {
    fillSelectedUser(selectedUserId);
  }, [selectedUserId]);

  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    if (isAccountAdmin(userType)) {
      params.columnApi.setColumnVisible("Account", false);
    }
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const fillUserList = async () => {
    let response = null;
    if (isSuperAdmin(userType)) {
      response = await fetchUsers();
    } else if (isABMUser(userType)) {
      if (selectedAccountState.accountId != null) {
        response = await fetchUsersFiltered(selectedAccountState.accountId);
      } else {
        response = await fetchUsersFiltered(accountId);
      }

    } else if (isAccountAdmin(userType)) {
      response = await fetchUsersFiltered(accountId);
    }

    setUserList(response);
  }

  const fillSelectedUser = (id) => {
    const user = _.find(userList, { userId: id });
    setSelectedUser(user);
  }

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  }

  const focusOnOpenOrCloseButton = () => {
    openOrCloseButtonRef.current.focus();
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  }

  return (
    <div>
      {isAccountUser(userType) &&
        (<AuthorizationError />)
      }
      {!isAccountUser(userType) && <>
        <div className='content-header-container d-flex flex-row align-items-center'>
          <div className='me-auto'>
            <ContentHeader title="Employees" dataCount={userList.length} filterData={filterData} />
          </div>
          <button
            className={`btn btn-lg ${isFormHidden ? 'btn-primary' : 'btn-secondary'}`}
            type="submit"
            onClick={() => toggleFormCancelDisplay()}
            ref={openOrCloseButtonRef}
          >
            {isFormHidden ? 'Add Employee' : 'Close'}
          </button>
        </div>
        <div className="content-body-container row">
          <div className={`${isFormHidden ? 'col-12' : 'col-12 col-lg-5 min-vh-25'} mb-2`}>
            <div className={`ag-theme-alpine content-section-container`} >
              <AgGridReact
                className='no-header'
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={70}
              >
              </AgGridReact>
            </div>
          </div>
          <div className={`col-12 col-lg-7 ${isFormHidden ? 'd-none' : ''}`}>
            <EmployeeForm
              isFormEdit={isFormEdit}
              selectedUser={selectedUser}
              isFormHidden={isFormHidden}
              fillUserList={fillUserList}
              toggleFormDisplay={toggleFormDisplay}
              setIsFormDirty={setIsFormDirty}
              modal={modal}
              setModal={setModal}
              toggle={toggle}
              focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
            />
          </div>
        </div>
      </>}
    </div>
  )
}

export default Employees
