import React from 'react'

function ProfilePictureRenderer(props) {
    const data = props.data["ProfilePicture"]
    return (
        <div className="profile-picture-renderer-container">
            <img src={data['url']} alt="User" className="profile-picture" />
        </div>
    )
}

export default ProfilePictureRenderer