import React from 'react'

function NameAndEmailRenderer(props) {
  const data = props.data["NameAndEmail"]
  return (
    <div className="name-and-email-renderer-container">
      <div className="name">
        {data['name']}
      </div>
      <div className="email">
        {data['email']}
      </div>
    </div>
  )
}

export default NameAndEmailRenderer