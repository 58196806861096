import React from "react";

// Created to show ellipses when overflowing
const NoteRenderer = ({ data, value }) => {
  var noteValue = ""
  if (data["Notes"] === "" || data["Notes"] === null || data["Notes"] === undefined) {
    noteValue =  data["Pay Method Type"] === 3 ? "Edit Note..." : "n/a"
  } else {
    noteValue = value;
  } 

  return (
    <div>
      {noteValue}
    </div>
  );
};

export default NoteRenderer;