import React, {useRef} from 'react'
import { useEffect } from 'react';

function ItemsContentHeader({ page, dataCount, pageChange, filterData, clearFilter, toggleHeaderFormDisplay }) {
    const inputRef = useRef()
    const selectRef = useRef()
    const handleChange = (e) => {
        filterData(e.target.value);
    }

    useEffect(() => {
        inputRef.current.value = ""
        clearFilter()
    }, [page])

    const onPageChange = (event) => {
        const value = event.target.value
        pageChange(value)
        toggleHeaderFormDisplay()
    }

    return (
        <div className="content-header-container d-flex flex-row align-items-center">
            <div className="search-input-field-container" ref={selectRef} >
                    <select className="form-select select-header-text search-input-field shadow-none" id="Header" onChange={onPageChange}>
                        <option value="expense">Expense</option>
                        <option value="product">Product Material</option>
                        <option value="labour">Labour</option>
                    </select>
            </div>
            <div className="divider ms-4 me-3"></div>
            <div className="content-data-count me-4">
                {dataCount} Total
            </div>
            <div className="search-input-field-container">
                <div className="input-group">
                    <input
                        type="text"
                        className="search-input-field form-control shadow-none"
                        placeholder="Search..."
                        name="search"
                        onChange={(e) => handleChange(e)}
                        ref={inputRef}
                        id="search"
                    />
                    <span className="input-group-text" id="basic-addon1">
                        <i className="fa-solid fa-search"></i>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ItemsContentHeader