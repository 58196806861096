import React from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';

function OverpayModal({
  modal,
  toggle,
  submitPayment,
  args
}) {

  const onSubmit = () => {
    submitPayment();
    toggle();
  }

  return (
    <Modal isOpen={modal} toggle={toggle} {...args}>
      <ModalHeader toggle={toggle}>Payment warning</ModalHeader>
      <ModalBody>
        Your payment amount is higher than the balance.
        Do you still want to proceed?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSubmit}>
          Yes
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default OverpayModal