import React from 'react';

function TypeRenderer(props) {
  const status = props.data["Type"];

  let backgroundColor = '#334d6e';
  let color = "#ffffff"
  let label = ""
  let padding = 15;
  switch (status) {
    case 1:
      backgroundColor = '#334D6E'
      label = "Periodic";
    
      break;
    case 2:
      backgroundColor = "#0dcaf0";
      label = 'One-Off';
        padding = 16;
      break;
    default:
      backgroundColor = '#334D6E';
      label = "Periodical";
      break;
  }

  return (
    <div style={{ backgroundColor: backgroundColor, color: color, paddingLeft: padding, paddingRight: padding }}>
      <p className='mb-0 fw-bold'>{label.toUpperCase()}</p>
    </div>
  )
}

export default TypeRenderer