import React from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line } from 'recharts';
import { getTotalQuotesByMonth } from '../../../utils/dashboardUtils';

function Quotes({quotes}) {
  // Months are zero-indexed, so January starts with 0
  const data = [
    {
      name: 'Jan',
      count: getTotalQuotesByMonth(quotes, 0)
    },
    {
      name: 'Feb',
      count: getTotalQuotesByMonth(quotes, 1)
    },
    {
      name: 'Mar',
      count: getTotalQuotesByMonth(quotes, 2)
    },
    {
      name: 'Apr',
      count: getTotalQuotesByMonth(quotes, 3)
    },
    {
      name: 'May',
      count: getTotalQuotesByMonth(quotes, 4)
    },
    {
      name: 'Jun',
      count: getTotalQuotesByMonth(quotes, 5)
    },
    {
      name: 'Jul',
      count: getTotalQuotesByMonth(quotes, 6)
    },
    {
      name: 'Aug',
      count: getTotalQuotesByMonth(quotes, 7)
    },
    {
      name: 'Sep',
      count: getTotalQuotesByMonth(quotes, 8)
    },
    {
      name: 'Oct',
      count: getTotalQuotesByMonth(quotes, 9)
    },
    {
      name: 'Nov',
      count: getTotalQuotesByMonth(quotes, 10)
    },
    {
      name: 'Dec',
      count: getTotalQuotesByMonth(quotes, 11)
    },
  ];
  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" style={{ fontSize: 12 }} />
        <YAxis style={{ fontSize: 12 }} />
        <Tooltip />
        <Line type="monotone" dataKey="count" stroke="#02B3C5" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default Quotes
