import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import _ from 'lodash';
import { addPayrollRun } from '../../services/payrollRunServices';
import { showToast } from '../../utils/toastUtils';
import { useSelector } from 'react-redux';
import ReactDatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import { nextPayrollDateRange } from '../../utils/payrollRunUtils';
import { isABMUser } from '../../utils/roleUtils';
import { sendSingleEmail } from '../../services/emailServices';
import moment from 'moment';


function PayrollRunForm({
  isFormEdit = false,
  isFormHidden,
  payrollRunList,
  payrollSettings,
}) {
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, clearErrors, reset, setFocus, control, formState: { errors } } = useForm();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const settingsState = useSelector((state) => state.settings);
  const currentUserState = useSelector((state) => state.auth.user);

  const [minDate, setMinDate] = useState([]);
  // const [payrollSettings, setPayrollSettings] = useState();

  // // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    fillDateRange();
  }, [payrollSettings])

  useEffect(() => {
    clearErrors();
    reset();
    fillDateRange();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("payrollRunDate");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    fillMinDate()
  }, [payrollRunList])

  const onSubmit = async (payload) => {
    let response = null;

    var startDate = moment(payload.startDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    var endDate = moment(payload.endDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    var payrollRunDate = moment(payload.payrollRunDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    var processingDate = moment().clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    _.set(payload, 'startDate', startDate);
    _.set(payload, 'endDate', endDate);
    _.set(payload, 'payrollRunDate', payrollRunDate);
    _.set(payload, 'processingDate', processingDate);

    _.set(payload, 'payrollRunId', 0);
    _.set(payload, 'payrollRunType', 1);
    _.set(payload, 'accountId', isABMUser(userType) && selectedAccountState.accountId !== null ? selectedAccountState.accountId : accountId);
    response = await addPayrollRun(payload);
    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully added' : 'Failed to add'} Payroll Run.`
    });

    if (response.ok) {
      if (!isFormEdit) {
        const data = await response.json();
        const newPayrollRunId = data.payrollRunId;

        navigate("/edit-payroll-run", { state: { payrollRunId: newPayrollRunId, status: false } });

        // Send email notification to default email
        await sendPayrollRunEmailNotification({
          toEmail: settingsState.accountSettings.defaultEmail || currentUserState.email,
          subject: `Payroll Run Created: ${selectedAccountState?.accountName || currentUserState?.account?.accountName}`,
          body: `Payroll Notification   : ${selectedAccountState?.accountName || currentUserState?.account?.accountName}\n` +
            `Payroll Run Date      : ${moment(payload.startDate).format("YYYY-MM-DD")} - ${moment(payload.endDate).format("YYYY-MM-DD")}\n` +
            `Created By               : ${currentUserState.firstName} ${currentUserState.lastName}\n` +
            `Total Paystubs          : ${data.payrollRunLineItem?.length}`
        });

        // Send email notification to payroll notification email
        if (!_.isNil(settingsState.accountSettings.payrollNotificationEmail)) {
          await sendPayrollRunEmailNotification({
            toEmail: settingsState.accountSettings.payrollNotificationEmail,
            subject: `Payroll Run Created: ${selectedAccountState?.accountName || currentUserState?.account?.accountName}`,
            body: `Payroll Notification   : ${selectedAccountState?.accountName || currentUserState?.account?.accountName}\n` +
              `Payroll Run Date      : ${moment(payload.startDate).format("YYYY-MM-DD")} - ${moment(payload.endDate).format("YYYY-MM-DD")}\n` +
              `Created By               : ${currentUserState.firstName} ${currentUserState.lastName}\n` +
              `Total Paystubs          : ${data.payrollRunLineItem?.length}`
          });
        }
      }
    }
  }

  const sendPayrollRunEmailNotification = async (payload) => {
    await sendSingleEmail(payload);
  }

  const fillMinDate = async () => {
    const payrollSettingsCheck = payrollSettings !== null && payrollSettings !== undefined && !_.isEmpty(payrollSettings);
    const payrollRunListCheck = payrollRunList !== null && payrollRunList !== undefined && payrollRunList.length > 0;
    if (payrollRunListCheck) {
      var periodicalPayrollRuns = payrollRunList.filter((payrollRun) => {return payrollRun.payrollRunType === 1});
      const lastPayroll = periodicalPayrollRuns.reduce((prev, current) => {
        return (prev.endDate > current.endDate) ? prev : current
      });
      const minDate = new Date(lastPayroll.endDate);
      setMinDate(minDate.setDate(minDate.getDate() + 1))
    }
    else if (payrollSettingsCheck) {
      setMinDate(new Date(payrollSettings.payrollStartDate));
    }
  }

  const fillDateRange = () => {
    const payrollSettingsCheck = payrollSettings !== null && payrollSettings !== undefined && !_.isEmpty(payrollSettings);
    const payrollRunListCheck = payrollRunList !== null && payrollRunList !== undefined && payrollRunList.length > 0;
    var dateRange = [null, null]
    if (payrollRunListCheck) {
      var periodicalPayrollRuns = payrollRunList.filter((payrollRun) => {return payrollRun.payrollRunType === 1});

      const lastPayroll = periodicalPayrollRuns.reduce((prev, current) => {
        return (prev.endDate > current.endDate) ? prev : current
      });
      dateRange = nextPayrollDateRange(moment(lastPayroll.endDate).format('YYYY-MM-DD'), payrollSettings.payrollPeriod);
      setValue('startDate', new Date(dateRange[0]));
      setValue('endDate', dateRange[1]);
    }
    else if (payrollSettingsCheck) {
      const payrollStartDate = moment(payrollSettings.payrollStartDate).format('YYYY-MM-DD');

      dateRange = nextPayrollDateRange(payrollStartDate, payrollSettings.payrollPeriod, true);
      setValue('startDate', dateRange[0]);
      setValue('endDate', dateRange[1]);
    }
  }

  return (
    <>
      <div className='content-section-container'>
        <div className='d-flex flex-row align-items-center mb-5'>
          <div className='form-title me-auto'>
            {`${isFormEdit ? 'Update' : 'Add'} Payroll Run`}
          </div>
          <button className="btn btn-primary" form='customer-form' type="submit">Create Payroll Run</button>
        </div>
        <form className="row form-container" id="customer-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="startDate" className="form-label">Start Date:</label>
            <Controller
              control={control}
              name='startDate'
              register={register}
              rules={{ required: "Start date is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactDatePicker
                  className='datepicker-field'
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  dateFormat='yyyy-MM-dd'
                  id="startDate"
                  readOnly={true}
                  disabled={true}
                />
              )}
            />
            <small className='form-error-message'>
              {errors?.startDate && errors.startDate.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="endDate" className="form-label">End Date:</label>
            <Controller
              control={control}
              name='endDate'
              register={register}
              rules={{ required: "End date is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactDatePicker
                  className='datepicker-field'
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  dateFormat='yyyy-MM-dd'
                  id="endDate"
                  readOnly={true}
                  disabled={true}
                />
              )}
            />
            <small className='form-error-message'>
              {errors?.endDate && errors.endDate.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="payrollRunDate" className="form-label">Payroll Run Date:</label>
            <Controller
              defaultValue={new Date()}
              control={control}
              name='payrollRunDate'
              register={register}
              rules={{ required: "Payroll run date is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactDatePicker
                  className='datepicker-field'
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  id="payrollRunDate"
                  dateFormat='yyyy-MM-dd'
                  minDate={minDate}
                  ref={(elem) => {
                    elem && ref(elem.input);
                  }}
                />
              )}
            />
            <small className='form-error-message'>
              {errors?.payrollRunDate && errors.payrollRunDate.message}
            </small>
          </div>

        </form>
      </div>
    </>
  )
}

export default PayrollRunForm
