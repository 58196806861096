import React from 'react';

function PaymentTypeRenderer(props) {
  const paymentType = props.data["Payment Type"];
  var display = "mode of payment...";
  switch (parseInt(paymentType)) {
    case 0:
      display = 'On Credit'
      break;
    case 1:
      display = 'Credit Card'
      break;
    case 2:
      display = 'Cheque'
      break;
    case 3:
      display = 'Cash'
      break;
    default:
      display = 'Not set'
      break;
  }

  return (
    <>
      <span>{display}</span>
    </>
  )
}

export default PaymentTypeRenderer