import React, { useState } from "react";
import _ from 'lodash';
import NavMenu from './components/Navigation/NavMenu'
import SidebarPage from './components/Navigation/Sidebar';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { ToastContainer } from 'react-toastify';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayrollSettings } from "./services/payrollSettingsServices";
import { setAccountingSettingsData, setPayrollSettingsData, setAccountSettingsData, setLoadingData } from "./redux/settings/settingsReducer";
import { fetchAccountingSettings } from "./services/accountingSettingsServices";
import { fetchAccountSettings } from "./services/accountSettingsServices";
import { fetchCustomersFiltered } from "./services/customerServices";
import { isABMUser } from "./utils/roleUtils";
import { setCustomersListData } from "./redux/customer/customerReducer";
import LoadingPage from "./components/Commons/Authorization/LoadingPage";

function Layout(props) {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const userType = useSelector((state) => state.auth.user.userType);
  // const [loadingData, setLoadingData] = useState(true);
  const loadingData = useSelector((state) => state.settings.loading);

  // Fetch needed data on app load only if user is authenticated
  useEffect(() => {
    if (!_.isNil(accountId)) {
      init();
    }
  }, [accountId, selectedAccountState.accountId]);

  const init = async () => {
    dispatch(setLoadingData(true));

    const payrollSettingsData = await fetchPayrollSettings();
    dispatch(setPayrollSettingsData(payrollSettingsData));

    const accountingSettingsData = await fetchAccountingSettings();
    dispatch(setAccountingSettingsData(accountingSettingsData));

    const customersListData = await fetchCustomersFiltered();
    dispatch(setCustomersListData(customersListData));

    const accountSettingsData = await fetchAccountSettings();
    dispatch(setAccountSettingsData(accountSettingsData))

    dispatch(setLoadingData(false));
  }

  return (
    <div style={{ height: '100%' }}>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="colored"
      />
      <NavMenu />
      <div className='main-container' >
        <ProSidebarProvider>
          <SidebarPage />
        </ProSidebarProvider>
        <div className="layout-container">
        {loadingData ? <LoadingPage/> : props.children}
        </div>
      </div>
    </div>
  );
}

export default Layout