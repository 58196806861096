import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const glCodeReducer = createSlice({
  name: "glCode",
  initialState,
  reducers: {
    setGLCodesListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setGLCodeData: (state, action) => {
      const index = state.data.findIndex(glCode => glCode.glCodeId === action.payload.glCodeId);
      const glCodesListData = [...state.data];
      glCodesListData[index] = action.payload;
      return {
        ...state,
        data: glCodesListData
      }
    },
    addGLCodeData: (state, action) => {
      const glCodesListData = [...state.data];
      glCodesListData.push(action.payload);
      return {
        ...state,
        data: glCodesListData
      }
    },
  },
});

export const {
  setGLCodesListData,
  setGLCodeData,
  addGLCodeData,
} = glCodeReducer.actions;

export default glCodeReducer.reducer;