import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  payrollSettings: {},
  accountingSettings: {},
  accountSettings: {},
  loading: false,
};

const settingsReducer = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setPayrollSettingsData: (state, action) => {
      return {
        ...state,
        payrollSettings: action.payload
      }
    },
    setAccountingSettingsData: (state, action) => {
      return {
        ...state,
        accountingSettings: action.payload
      }
    },
    setAccountSettingsData: (state, action) => {
      return {
        ...state,
        accountSettings: action.payload
      }
    },
    setLoadingData: (state, action) => {
      return {
        ...state,
        loading: action.payload, // Update the loading state
      };
    },
  },
});

export const {
  setPayrollSettingsData,
  setAccountingSettingsData,
  setAccountSettingsData,
  setLoadingData
} = settingsReducer.actions;

export default settingsReducer.reducer;