import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Link } from 'react-router-dom';
import { isSuperAdmin, isABMUser } from '../../utils/roleUtils';
import { displayStatus } from '../../utils/quoteUtils'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useSelector } from 'react-redux';
import { QUOTE_STATUS } from '../../constants';

function QuoteContentHeader({ title, updateQuoteStatus, status, editable, args }) {
  const userType = useSelector((state) => state.auth.user.userType);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [currentStatus, setCurrentStatus] = useState(0)

  const onChangeToggle = (event) => {
    const value = event.target.value
    setModal(!modal)
    setCurrentStatus(value)
  }

  const quoteStatusUpdate = () => {
    updateQuoteStatus(currentStatus)
    setModal(!modal)
  }

  const toolTipTitle = () => {
    let title = ""
    if (isSuperAdmin(userType) || isABMUser(userType)) {
      title = "Only account users can edit quotes"
    } else {
      title = `Cannot edit a quote that's already ${displayStatus(status).toLowerCase()}`
    }

    return title
  }

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <div className=' me-3'>
          <Link className="back-button" to='/quotes'>
            <i className="fa-solid fa-arrow-left"></i>
          </Link>
        </div>
        <div className="content-title">
          {title}
        </div>
        <div className="divider ms-4 me-3"></div>
        <form className='form-container'>
          {editable ?
            <select className="form-select" id="Header" onChange={onChangeToggle} value={status}>
              {QUOTE_STATUS.map((status, index) =>
                <option key={index} value={status.value}>{status.label}</option>
              )}
            </select>
            :
            <div className="d-flex flex-row">
              <div className="me-2">
                {displayStatus(status)}
              </div>
              <div className="icon">
                <i id="quote-status-tooltip" className="fa-solid fa-lock"></i>
              </div>
              <ReactTooltip
                anchorId="quote-status-tooltip"
                className='tooltip-container'
                place="top"
                content={toolTipTitle}
              />
            </div>
          }
        </form>
      </div>
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Change Quote Status</ModalHeader>
        <ModalBody>
          Are you sure you want to change the status of this quote?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={quoteStatusUpdate}>
            Yes
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default QuoteContentHeader