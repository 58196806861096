import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const contactReducer = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setContactsListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setContactData: (state, action) => {
      const index = state.data.findIndex(contact => contact.contactId === action.payload.contactId);
      const contactsListData = [...state.data];
      contactsListData[index] = action.payload;
      return {
        ...state,
        data: contactsListData
      }
    },
    addContactData: (state, action) => {
      const contactsListData = [...state.data];
      contactsListData.push(action.payload);
      return {
        ...state,
        data: contactsListData
      }
    },
  },
});

export const {
  setContactsListData,
  setContactData,
  addContactData,
} = contactReducer.actions;

export default contactReducer.reducer;