import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getTotalCustomersByMonth } from '../../../utils/dashboardUtils';

function Customers({customers}) {
  // Months are zero-indexed, so January starts with 0
  const data = [
    {
      name: 'Jan',
      count: getTotalCustomersByMonth(customers, 0)
    },
    {
      name: 'Feb',
      count: getTotalCustomersByMonth(customers, 1)
    },
    {
      name: 'Mar',
      count: getTotalCustomersByMonth(customers, 2)
    },
    {
      name: 'Apr',
      count: getTotalCustomersByMonth(customers, 3)
    },
    {
      name: 'May',
      count: getTotalCustomersByMonth(customers, 4)
    },
    {
      name: 'Jun',
      count: getTotalCustomersByMonth(customers, 5)
    },
    {
      name: 'Jul',
      count: getTotalCustomersByMonth(customers, 6)
    },
    {
      name: 'Aug',
      count: getTotalCustomersByMonth(customers, 7)
    },
    {
      name: 'Sep',
      count: getTotalCustomersByMonth(customers, 8)
    },
    {
      name: 'Oct',
      count: getTotalCustomersByMonth(customers, 9)
    },
    {
      name: 'Nov',
      count: getTotalCustomersByMonth(customers, 10)
    },
    {
      name: 'Dec',
      count: getTotalCustomersByMonth(customers, 11)
    },
  ];

  return (
    <ResponsiveContainer width="100%" height={300}>
    <BarChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" style={{ fontSize: 12 }} />
      <YAxis style={{ fontSize: 12 }} allowDecimals={false}/>
      <Tooltip cursor={{fill: "transparent"}} />
      <Bar dataKey="count" fill="#02B3C5" />
    </BarChart>
    </ResponsiveContainer>
  )
}

export default Customers
