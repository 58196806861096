// Gets the url like based on the page and user type
export function getUrl(account, page, userType, selectedWorkingAccount = false) {
    const expenseDefaultURL = 'ExpenseCategory/expenseItem'
    const expenseFilteredUrl = 'ExpenseCategory/expenseItem/filtered'
    const productDefaultUrl = 'ExpenseCategory/productMaterial'
    const productFilteredUrl = 'ExpenseCategory/productMaterial/filtered'
    const labourDefaultUrl = 'ExpenseCategory/labour'
    const labourFilteredUrl = 'ExpenseCategory/labour/filtered'
    var url = {
      'expense': {
        1: expenseDefaultURL,
        2: expenseFilteredUrl,
        3: expenseFilteredUrl,
        4: expenseFilteredUrl,
      },
      'product': {
        1: productDefaultUrl,
        2: productFilteredUrl,
        3: productFilteredUrl,
        4: productFilteredUrl
      },
      'labour': {
        1: labourDefaultUrl,
        2: labourFilteredUrl,
        3: labourFilteredUrl,
        4: labourFilteredUrl
      }
    }

    return url[page][userType];
}