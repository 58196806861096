import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash';
import { showToast } from '../../utils/toastUtils';
import { isABMUser } from '../../utils/roleUtils';
import { useSelector } from 'react-redux';
import { addImageSettings, fetchImageSettings, updateImageSettings } from '../../services/imageSettingsServices';
import { AddPhotoAlternateOutlined } from '@mui/icons-material';

function ImageSettingsForm({
  isFormEdit,
  setIsFormEdit
}) {
  const { register, handleSubmit, clearErrors, setError, setFocus, formState: { errors } } = useForm();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const [logoData, setLogoData] = useState(null);
  const [imageSettings, setImageSettings] = useState();
  const [newLogoCheck, setNewLogoCheck] = useState(false);
  const fileInputRef = useRef(null);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);

  useEffect(() => {
    register("logo");
    setFocus("logoInput")
  }, [])

  // useEffects
  useEffect(() => {
    clearErrors();
    fillImageSettings();
    fileInputRef.current = document.getElementById('logoInput');
    fileInputRef.current.focus()
  }, []);


  useEffect(() => {
    if (!_.isNil(imageSettings)) {
      setLogoData(imageSettings.logo);
    }
  }, [imageSettings])

  const onSubmit = async (payload) => {
    let response = null;
    if (!_.isNil(logoData)) {
      _.set(payload, 'logo', logoData);
    }
    // return;
    if (isFormEdit) {
      _.set(payload, 'imageSettingsId', imageSettings.imageSettingsId);
      _.set(payload, 'accountId', imageSettings.accountId);

      response = await updateImageSettings(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully updated' : 'Failed to update'} image settings.`
      });
    } else {
      _.set(payload, 'imageSettingsId', 0);
      if (isABMUser(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }
      response = await addImageSettings(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully added' : 'Failed to add'} image settings.`
      });
    }
    if (response.ok) {
      setIsFormEdit(true);
      fileInputRef.current.focus()
    }
  }

  const handleCancelButtonClick = () => {
    // Reset the file input value without triggering onChange event
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
      if (!_.isNil(imageSettings)) {
        setLogoData(imageSettings.logo);
      } else
      {
        setLogoData(null);
      }
      setNewLogoCheck(false);
    }
  };


  const onLogoChange = async (event) => {
    const file = event.target.files[0];
    clearErrors();
    if (file) {
      if (file.type.startsWith('image')) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          // Set the base64 value to the imageData state variable
          const base64String = reader.result.split(',')[1];

          const resizedBase64 = await resizeImage(base64String, 300);

          const resizedBase64String = resizedBase64.split(',')[1];
          setLogoData(resizedBase64String);
          // setLogoData(base64String);
        };
      reader.readAsDataURL(file);
      setNewLogoCheck(true);
      }
      else {
        setError('logo', {
          message: 'The file must be an image',
        });
        setLogoData(!_.isNil(imageSettings.logo) ? imageSettings.logo : null);
        setNewLogoCheck(false);
      }
    } else {
      setLogoData(!_.isNil(imageSettings.logo) ? imageSettings.logo : null);
      setNewLogoCheck(false);
    }
  }

  const resizeImage = async(base64String, newDimension) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = `data:image/jpeg;base64,${base64String}`;

      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const newWidth = aspectRatio >= 1 ? newDimension : Math.floor(newDimension * aspectRatio);
        const newHeight = aspectRatio >= 1 ? Math.floor(newDimension / aspectRatio) : newDimension;

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = 'rgba(0, 0, 0, 0)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        const resizedBase64 = canvas.toDataURL('image/png');
        resolve(resizedBase64);
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  }

  const fillImageSettings = async () => {
    
    var data = null
    if(isABMUser(userType))
    {
      data = selectedAccountState.accountId != null ? await fetchImageSettings(selectedAccountState.accountId) : await fetchImageSettings(accountId);
    }   
    else
    {
      data = await fetchImageSettings(accountId)
    }
    setImageSettings(data);
    if (!_.isEmpty(data)) {

      setIsFormEdit(true);
    }
  }

  return (
    <>
      <div className='content-section-container'>
        <form className="d-flex flex-row align-items-center mx-auto form-container" id="image-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-6 col-sm-4 mb-2">
            <div className="row">
              <div className='col-1'>
                <label htmlFor="logoInput" className="form-label">Logo:</label>
              </div>
              <div className='col'>
                <input className="form-control" type="file" id="logoInput" accept="image/*" onChange={onLogoChange}></input>
                <small className='form-error-message mb-2'>
                  {errors?.logo && errors.logo.message}
                </small>
              </div>
              
            </div>
          </div>
          
          <div className="col-6 col-sm-4 mb-2 d-flex justify-content-center">
            <div className="row me-2">
              <div className="col">
                <button className="btn btn-primary" form='image-form' type="button" disabled={!newLogoCheck} onClick={handleCancelButtonClick}> Cancel </button>
              </div>

            </div>
            <div className="row">
               <div className="col">
                <button className="btn btn-primary" form='image-form' type="submit" disabled={!newLogoCheck}> Upload </button>
               </div> 
            </div>
          </div>
          <div className="col-6 col-sm-4 mb-2 d-flex">
            {(logoData) ? (
              <img src={`data:image/jpeg;base64,${logoData}`} alt="image preview" className="logo-viewer"/>
            )
            :
            (
              <AddPhotoAlternateOutlined className="logo-viewer" fontSize='150px'/>
            )
            }
          </div>
        </form>
      </div>
    </>
  )
}

export default ImageSettingsForm