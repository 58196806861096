import moment from 'moment';
import _ from 'lodash';
import { getInvoiceBalance } from './paymentUtils';

export function getTotalCustomersByYear(customers, year) {
  const filteredCustomers = _.filter(customers, (customer) => {
    return moment(customer?.createdTimeStamp).year() === year;
  });

  return _.size(filteredCustomers);
}

export function getTotalCustomersByMonth(customers, month) {
  const filteredCustomers = _.filter(customers, (customer) => {
    return moment(customer?.createdTimeStamp).month() === month;
  });

  return _.size(filteredCustomers);
}

export function getUnsettledQuotesByYear(quotes, year) {
  const unsettledQuotes = _.filter(quotes, (quote) => {
    return quote.status === 0 && moment(quote?.createdTimeStamp).year() === year;
  });

  return _.size(unsettledQuotes);
}

export function getTotalQuotesByMonth(quotes, month) {
  const filteredQuotes = _.filter(quotes, (quote) => {
    return moment(quote?.createdTimeStamp).month() === month;
  });

  return _.size(filteredQuotes);
}

export function getOutstandingInvoicesByYear(invoices, year) {
  const outstandingInvoices = _.filter(invoices, (invoice) => {
    const invoiceBalance = getInvoiceBalance(invoice);
    return invoiceBalance > 0 && moment(invoice?.invoiceDate).year() === year;
  });

  return _.size(outstandingInvoices);
}

export function getPaidInvoicesByYear(invoices, year) {
  const paidInvoices = _.filter(invoices, (invoice) => {
    const invoiceBalance = getInvoiceBalance(invoice);
    return invoiceBalance <= 0 && moment(invoice?.invoiceDate).year() === year;
  });

  return _.size(paidInvoices);
}