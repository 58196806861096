import React, { forwardRef, useState, useEffect, useImperativeHandle, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const ExpenseDateEditor = forwardRef((props, ref) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const inputRef = useRef(null);
  const [inputStyle, setInputStyle] = useState({});

  useEffect(() => {
    if (props.value) {
      setSelectedDate(moment(props.data["Expense Date"]).clone().hour(7).minute(0).second(0).toDate());
    }
  }, [props.value]);

  useImperativeHandle(ref, () => ({
    getValue() {
      return selectedDate;
    },
    
  }));

  const handleChange = (date) => {
    setSelectedDate(date);
  };

  const handleSelect = () => {
    handleFocus();
  }

  const handleFocus = () => {
    if(inputRef.current && inputRef.current.input) {
      setTimeout(() => {
        inputRef.current.input.focus(); 
      }, [200])
    }
  }

  return (
    <div>
      <ReactDatePicker
        portalId="root"
        className="datepicker-field cell-renderer-date-picker"
        onChange={handleChange}
        selected={selectedDate}
        dateFormat="yyyy-MM-dd"
        id="expenseDate"
        disabled={props.isFormEdit}
        ref={inputRef}
        showMonthDropdown={true}
        autoFocus={true}
        shouldCloseOnSelect={true}
        onSelect={handleSelect}
        startOpen={false}
      />
    </div>

  );
});

export default ExpenseDateEditor;