import React from 'react'
import { formatDate, formatDateOnly, formatCurrency, formatTaxType } from '../../utils/formatUtils';
import { getExpenseBalance } from '../../utils/paymentUtils';

function ExpenseDetails({ selectedExpense }) {
  return (
    <div className='row invoice-details-container'>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>Expense ID</p>
        <p>{selectedExpense?.expenseId ?? '-'}</p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>Description</p>
        <p>{selectedExpense?.description ?? '-'}</p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>Reference ID</p>
        <p>{selectedExpense?.referenceId ?? '-'}</p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>GL Code</p>
        <p>{selectedExpense?.glCodeId ?? '-'}</p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>Date</p>
        <p>{selectedExpense?.expenseDate !== undefined ? formatDateOnly(selectedExpense?.expenseDate) : '-'}</p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>Quantity</p>
        <p>{selectedExpense?.quantity !== undefined ? formatCurrency(selectedExpense?.quantity) : '-'}</p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>Unit Price</p>
        <p>{selectedExpense?.rate !== undefined ? formatCurrency(selectedExpense?.rate) : '-'}</p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>Tax Type</p>
        <p>{selectedExpense?.taxType !== undefined ? formatTaxType(selectedExpense?.taxType) : '-'}</p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>Tax Rate</p>
        <p>{selectedExpense?.taxRate !== undefined ? selectedExpense?.taxRate : '-'}</p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>Discount</p>
        <p>{selectedExpense?.discount !== undefined ? formatCurrency(selectedExpense?.discount) : '-'}</p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>Subtotal</p>
        <p>{selectedExpense?.subtotal !== undefined ? formatCurrency(selectedExpense?.subtotal) : '-'}</p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>Total</p>
        <p>{selectedExpense?.total !== undefined ? formatCurrency(selectedExpense?.total) : '-'}</p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>Total Tax</p>
        <p>{selectedExpense?.totalTax !== undefined ? formatCurrency(selectedExpense?.totalTax) : '-'}</p>
      </div>
      <div className="col-6 col-lg-3">
        <p className='mb-0 fw-bold'>Balance</p>
        <p>{selectedExpense !== undefined ? formatCurrency(getExpenseBalance(selectedExpense)) : '-'}</p>
      </div>
    </div>
  )
}

export default ExpenseDetails