import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import _ from 'lodash';
import { showToast } from '../../utils/toastUtils';
import { isABMUser } from '../../utils/roleUtils';
import { useDispatch, useSelector } from 'react-redux';
import ReactDatePicker from "react-datepicker";
import { addAccountingSettings } from '../../services/accountingSettingsServices';
import moment from 'moment';
import { setAccountingSettingsData } from '../../redux/settings/settingsReducer';

function AccountingSettingsForm({
  isFormHidden
}) {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, clearErrors, reset, setFocus, control, formState: { errors } } = useForm();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const settingsState = useSelector((state) => state.settings);
  const [accountingSettings, setAccountingSettings] = useState();
  const userType = useSelector((state) => state.auth.user.userType);
  const [fiscalDateCheck, setFiscalDateCheck] = useState(false);
  const accountState = useSelector((state) => state.account.selectedAccount);
  useEffect(() => {
    if (accountingSettings !== undefined) {
      setValue("fiscalStartDate", moment(accountingSettings.fiscalStartDate).clone().hour(7).minute(0).second(0).toDate());
    }
  }, [accountingSettings])

  // useEffects
  useEffect(() => {
    clearErrors();
    setFocus("fiscalStartDate");
  }, []);

  useEffect(() => {
    clearErrors();
    reset();
  }, [isFormHidden]);

  useEffect(() => {
    setAccountingSettings(settingsState.accountingSettings);
    if (!_.isEmpty(settingsState.accountingSettings)) {
      setFiscalDateCheck(true);
    }
  }, [settingsState.accountingSettings]);

  const onSubmit = async (payload) => {
    let response = null;
    var fiscalStartDate = moment(payload.fiscalStartDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    _.set(payload, 'fiscalStartDate', fiscalStartDate);
    _.set(payload, 'accountingSettingsId', 0);
    if(isABMUser(userType)) {
      _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
    } else {
      _.set(payload, 'accountId', accountId);
    }
    response = await addAccountingSettings(payload);
    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully added' : 'Failed to add'} accounting settings.`
    });

    if (response.ok) {
      dispatch(setAccountingSettingsData(await response.json()));
    }
  }
  
  return (
    <>
      <div className='content-section-container'>
        <div className='d-flex flex-row align-items-center mb-5'>
          <div className='form-title me-auto'>

          </div>
          <button className="btn btn-primary" form='customer-form' type="submit" disabled={fiscalDateCheck}>Save</button>
        </div>
        <form className="row form-container" id="customer-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="fiscalStartDate" className="form-label">Fiscal Start Date:</label>
            <Controller
              control={control}
              name='fiscalStartDate'
              register={register}
              rules={{ required: "Fiscal start date is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactDatePicker
                  className='datepicker-field'
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  id="fiscalStartDate"
                  dateFormat='yyyy-MM-dd'
                  disabled={fiscalDateCheck}
                  ref={(elem) => {
                    elem && ref(elem.input);
                  }}
                />
              )}
            />
            <small className='form-error-message'>
              {errors?.fiscalStartDate && errors.fiscalStartDate.message}
            </small>
          </div>
        </form>
      </div>
    </>
  )
}

export default AccountingSettingsForm
