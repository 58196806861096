import React, { useCallback, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { deletePayrollRun, fetchVoidPayrollRun } from '../../../../services/payrollRunServices';
import { showToast } from '../../../../utils/toastUtils';
import { formatDateOnly } from '../../../../utils/formatUtils';
import moment from 'moment';
import { ConfirmationModal } from '../../../Commons/Modals/ConfirmationModal';

function EditPayrollRunRenderer({
  data,
  api,
  gridRef,
  rowData,
  setRowData,
  toggleFormDisplay,
  fillPayrollRunList,
  oneOffMinDate = null
}, args) {
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);
  const [voidOneOffModal, setVoidOneOffModal] = useState(false);
  const voidOneOffToggle = () => setVoidOneOffModal(!voidOneOffModal);

  const removeSelected = useCallback(async () => {
    const selectedNode = gridRef.current.api.getSelectedNodes();
    const response = await deletePayrollRun(selectedNode[0].data["id"])
    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully deleted' : 'Failed to delete'} payroll run.`
    });
    if (response.ok) {
      await fillPayrollRunList();
      setDeleteModal(false);
    }
  }, [rowData]);

  const voidOneOffPayrollRun = async () => {
    const response = await fetchVoidPayrollRun(data?.id)
    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully voided' : 'Failed to void'} payroll run.`
    });
    if (response.ok) {
      await fillPayrollRunList();
      setVoidOneOffModal(false);
    }
  }

  return (
    <div className='cell-renderer-content'>
      <Link to="/edit-payroll-run" state={{ payrollRunId: data.id, status: data.Status }}>
        <button className={`btn btn-primary btn-sm`}><i className={`fa-solid ${data['Status'] === true ? 'fa-magnifying-glass' : 'fa-pen'}`}></i></button>
      </Link>
      {
        (data?.Type === 2 && data?.Status === true && data["End Date"] > moment(data.minDate).subtract(1, 'days').format('YYYY-MM-DD'))
        && <button className="btn btn-sm-md btn-danger" onClick={voidOneOffToggle}>Void</button>
      }
      {(data['Status'] === false) && <button onClick={deleteToggle} className={`btn btn-primary btn-sm`}><i className="fa-solid fa-x"></i></button>}

      <ConfirmationModal
        isOpen={deleteModal}
        toggle={deleteToggle}
        onClick={removeSelected}
        headerText="Delete Payroll Run"
        bodyText="Are you sure you want to delete this item?"
        confirmButtonText="Yes"
        cancelButtonText="No"
        buttonColor="danger"
      />

      <ConfirmationModal
        isOpen={voidOneOffModal}
        toggle={voidOneOffToggle}
        onClick={voidOneOffPayrollRun}
        headerText="Void One-Off Payroll Run"
        bodyText="Are you sure you want to void this item?"
        confirmButtonText="Yes"
        cancelButtonText="No"
        buttonColor="danger"
      />
    </div>
  )
}

export default EditPayrollRunRenderer