import React, { useState } from 'react'
import ContentHeader from '../Commons/Layouts/ContentHeader';
import { isAccountUser } from '../../utils/roleUtils';
import { useSelector } from 'react-redux';
import PayrollSettingsForm from './PayrollSettingsForm';
import AuthorizationError from '../Commons/Authorization/AuthorizationError';

function PayrollSettings() {
  const userType = useSelector((state) => state.auth.user.userType);

  // useStates
  const [isFormEdit, setIsFormEdit] = useState(false);

  return (
    <div>
      {isAccountUser(userType) &&
        (<AuthorizationError/>)
      }
      {!isAccountUser(userType) &&
        <>
          <div className={`content-header-container d-flex flex-row align-items-center`}>
            <div className='me-auto'>
              <ContentHeader title="Payroll Settings" hideSearch='true' hideTotal='true' />
            </div>
          </div>
          <div className="content-body-container row">
            <div className={`col-12 mb-2`}>
              <PayrollSettingsForm
                isFormEdit={isFormEdit}
                setIsFormEdit={setIsFormEdit}
              />
            </div>
          </div>
        </>}
    </div>
  )
}

export default PayrollSettings