import React from 'react'
import { formatDateOnly, formatCurrency, formatPhoneNumber, dateDisplayFormat } from '../../utils/formatUtils';
import { useNavigate } from 'react-router-dom';

function QuoteDetails({
  name,
  email,
  phone,
  address,
  estimateNo,
  estimateName,
  estimateDate,
  estimateDueDate,
  subtotal,
  discount,
  total,
  tax,
  invoiceId,
  printMode,
  toggle,
  editable
}) {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (editable) {
      toggle();
    } else {
      navigate('/invoices/edit-invoice', { state: { invoiceId: invoiceId } });
    }
  }

  return (
    <div className='content-header-container quote-details-container row'>
      <div className={`${printMode ? 'col-6' : 'col-12'} col-lg-6 mb-2`}>
        <div className='quote-details-container shadow-sm'>
          <div className="content-title mb-3">
            Customer
          </div>
          <div className='row quote-details'>
            <div className="col-6">
              <p className='mb-0 fw-bold'>Name</p>
              <p>{name}</p>
            </div>
            <div className="col-6">
              <p className='mb-0 fw-bold'>Email</p>
              <p>{email}</p>
            </div>
            <div className="col-6">
              <p className='mb-0 fw-bold'>Phone</p>
              <p>{formatPhoneNumber(phone)}</p>
            </div>
            <div className="col-6">
              <p className='mb-0 fw-bold'>Address</p>
              <p>{address}</p>
            </div>
          </div>
        </div>
      </div>

      <div className={`${printMode ? 'col-6' : 'col-12'} col-lg-6 mb-2`}>
        <div className="quote-details-container shadow-sm">
          <div className='d-flex justify-content-between'>
            <div className="content-title mb-3">
              Quote Details
            </div>
            <div className="content-title mb-3">
              <button className={`btn btn-primary ${editable ? 'btn-sm' : 'btn-md'}`} type="submit" onClick={handleOnClick}>
                {
                  editable ? 
                  <i className='fa-solid fa-pen'></i>
                  :
                  "Invoice"
                }
              </button>
            </div>
          </div>

          <div className='row quote-details'>
            {/* <div className="col-3">
              <p className='mb-0 fw-bold'>Estimate No.</p>
              <p>{estimateNo}</p>
            </div> */}
            <div className="col-3">
              <p className='mb-0 fw-bold'>Invoice No.</p>
              <p>{estimateName}</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Invoice Date</p>
              <p>{dateDisplayFormat(estimateDate)}</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Invoice Due Date</p>
              <p>{dateDisplayFormat(estimateDueDate)}</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Created by</p>
              <p>Account User</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Subtotal</p>
              <p>{formatCurrency(subtotal)}</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Discount</p>
              <p>{formatCurrency(discount)}</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Total</p>
              <p>{formatCurrency(total)}</p>
            </div>
            <div className="col-3">
              <p className='mb-0 fw-bold'>Tax</p>
              <p>{formatCurrency(tax)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuoteDetails