import React from 'react';
import { formatCurrency } from '../../../utils/formatUtils';

function VacationPayRenderer(props) {
  let vacationPay = formatCurrency(props.value);
  const vacationType = props.data["Vacation Type"];
  
  if(vacationType === 1) {
    vacationPay = "n/a";
  }

  return (
    <>
      <span>{vacationPay}</span>
    </>
  )
}

export default VacationPayRenderer