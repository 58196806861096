import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import DeletePayrollRunLineItemModal from '../../../Commons/Modals/DeletePayrollRunLineItemModal';
import EditPayrollRunLineItemModal from '../../../Commons/Modals/EditPayrollRunLineItemModal';
import { useDispatch } from 'react-redux';
import { setPayrollRunLineItem } from '../../../../redux/payrollRunLineItems/payrollRunLineItemsReducer';
function ActionRenderer(props, args) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [body, setBody] = useState("edit")
  const [updatedPayrollRunLineItem, setUpdatedPayrollRunLineItem] = useState([]);
  const toggle = () => setModal(!modal);

  const toggleEdit = () => {
    setModal(!modal);
    setBody("edit");
  };

  const toggleDelete = () => {
    setModal(!modal);
    setBody("delete");
  }

  const toggleClose = () => {
    setModal(!modal);
    dispatch(setPayrollRunLineItem(updatedPayrollRunLineItem));
  }

  useEffect(() => {
    props.refreshCell();
  }, [])

  return (
    <div className='cell-renderer-content'>
      <button onClick={toggleEdit} className="btn btn-primary btn-sm"><i className="fa-solid fa-ellipsis"></i></button>
      {props.editable === true && <button onClick={toggleDelete} className="btn btn-primary btn-sm"><i className="fa-solid fa-x"></i></button>}
          <Modal isOpen={modal} size={`${body === "edit" ? 'lg' : ''}`} toggle={toggleClose} keyboard={body === "edit" ? false : true} backdrop={body === "edit" ? 'static' : true} fade={true} {...args}>
            {body === "edit" && <EditPayrollRunLineItemModal toggle={toggle} toggleClose={toggleClose} lineItemGridRef={props.lineItemGridRef} editable={props.editable} onUpdate={setUpdatedPayrollRunLineItem} />}
            {body === "delete" && <DeletePayrollRunLineItemModal toggle={toggle} refreshCell={props.refreshCell} removeSelected={props.removeSelected}/>}
          </Modal>
    </div>
  )
}

export default ActionRenderer