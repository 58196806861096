import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import ContentHeader from '../Commons/Layouts/ContentHeader';
import EditFormRenderer from '../Templates/CellRenderers/Commons/EditFormRenderer';
import _ from 'lodash';
import { fetchGLCodesFiltered } from '../../services/glCodeServices';
import GLCodeForm from './GLCodeForm';
import { currencyTypeValueGetter, defaultTaxCodeValueGetter } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import AuthorizationError from '../Commons/Authorization/AuthorizationError';
import { isAccountUser, isABMUser } from '../../utils/roleUtils';
import { setGLCodesListData } from '../../redux/glCode/glCodeReducer';

function GLCodes() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const glCodeState = useSelector((state) => state.glCode);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const toggleFormDisplay = (isEdit = false, glCodeId = null) => {
    if (isFormHidden) {
      setSelectedGLCodeId(glCodeId);
    } else {
      setSelectedGLCodeId(null)
    }
    if (modal) {
      setModal(!modal)
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 50);
  }

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    if (isFormDirty) {
      toggle()
      return
    }
    toggleFormDisplay()
  }

  const defaultColumnDefs = [
    { field: '#', width: 50, minWidth: 50, suppressSizeToFit: true, cellClass: 'no-borders', cellStyle: { fontWeight: 'bold' } },
    { field: 'Name', minWidth: 150, width: 80, cellClass: 'no-borders' },
    { field: 'Description', minWidth: 150, width: 80, cellClass: 'no-borders' },
    { field: 'Account Type', minWidth: 150, width: 80, cellClass: 'no-borders' },
    { field: 'Currency Type', minWidth: 150, valueGetter: currencyTypeValueGetter, width: 80, cellClass: 'no-borders' },
    { field: 'Default Tax Code', minWidth: 150, valueGetter: defaultTaxCodeValueGetter, cellClass: 'no-borders', getQuickFilterText: params => { return params.value; } },
    { field: 'Notes', minWidth: 150, cellClass: 'no-borders', getQuickFilterText: params => { return params.value; } },
    {
      field: 'Edit',
      minWidth: 150,
      headerName: '',
      cellClass: 'd-flex flex-row-reverse',
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay }
    },
  ]

  const columnDefsWithRow = [
    { field: '#', width: 50, minWidth: 50, suppressSizeToFit: true, cellClass: 'no-borders', cellStyle: { fontWeight: 'bold' } },
    { field: 'Name', minWidth: 150, width: 80, cellClass: 'no-borders' },
    { field: 'Description', minWidth: 150, width: 80, cellClass: 'no-borders' },
  ];


  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [glCodeList, setGLCodeList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedGLCode, setSelectedGLCode] = useState({});
  const [selectedGLCodeId, setSelectedGLCodeId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton()
  }, []);
  
  useEffect(() => {
    setGLCodeList(glCodeState.data);
  }, [glCodeState.data]);

  useEffect(() => {
    setColumnDefs(isFormHidden ? defaultColumnDefs : columnDefsWithRow);
  }, [isFormHidden]);

  useEffect(() => {
    let data = [];
    _.each(glCodeList, (gl, index) => {
      data.push({
        '#': index + 1,
        'Name': gl.glCodeName,
        'Description': gl.description,
        'Account Type': gl.accountType.accountTypeName,
        'Currency Type': gl.currencyType,
        'Default Tax Code': gl.defaultTaxCode,
        'Notes': gl.notes,
        'Edit': { id: gl.glCodeId }
      });
    });
    setRowData(data);
  }, [glCodeList])

  useEffect(() => {
    fillSelectedGLCode(selectedGLCodeId);
  }, [selectedGLCodeId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);
  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const init = async () => {
    const response = await getGLCodeData();
    dispatch(setGLCodesListData(response));
  }

  const getGLCodeData = async () => {
    if (isABMUser(userType)) {
      return selectedAccountState.accountId === null ? await fetchGLCodesFiltered(accountId) : await fetchGLCodesFiltered(selectedAccountState.accountId);
    }
    else {
      return fetchGLCodesFiltered(accountId);
    }
  }

  const fillSelectedGLCode = (id) => {
    const gl = _.find(glCodeList, { glCodeId: id });
    setSelectedGLCode(gl);
  }

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  }

  const focusOnOpenOrCloseButton = () => {
    openOrCloseButtonRef.current.focus();
  }

  return (
    <div>

      {isAccountUser(userType) &&
        (<AuthorizationError />)
      }
      {!isAccountUser(userType) &&
        <>
          <div className='d-flex flex-row align-items-center content-header-container '>
            <div className='me-auto'>
              <ContentHeader title="GL Code" dataCount={glCodeList.length} filterData={filterData} />
            </div>
            <button
              className={`btn btn-lg ${isFormHidden ? 'btn-primary' : 'btn-secondary'}`}
              type="submit"
              onClick={() => toggleFormCancelDisplay()}
              ref={openOrCloseButtonRef}
            >
              {isFormHidden ? 'Add GL Code' : 'Close'}
            </button>
          </div>
          <div className="content-body-container row">
            <div className={`${isFormHidden ? 'col-12' : 'col-12 col-lg-5 min-vh-25'} mb-2`}>
              <div className={`ag-theme-alpine content-section-container`} >
                <AgGridReact
                  className='no-header'
                  rowData={rowData}
                  columnDefs={columnDefs}
                  ref={gridRef}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  onColumnSizeChanged={onColumnsSizeChanged}
                  onGridSizeChanged={onGridSizeChanged}
                  rowHeight={70}
                >
                </AgGridReact>
              </div>
            </div>
            <div className={`col-12 col-lg-7 ${isFormHidden ? 'd-none' : ''}`}>
              <GLCodeForm
                isFormEdit={isFormEdit}
                selectedGLCode={selectedGLCode}
                isFormHidden={isFormHidden}
                toggleFormDisplay={toggleFormDisplay}
                setIsFormDirty={setIsFormDirty}
                modal={modal}
                setModal={setModal}
                toggle={toggle}
                focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
              />
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default GLCodes
