import React, { useState } from 'react'
import ContentHeader from '../Commons/Layouts/ContentHeader';
import ImagesForm from './ImageSettingsForm';

function ImageSettings() {

  // useStates
  const [accountList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);

  return (
    <div>
        <>
          <div className={`content-header-container d-flex flex-row align-items-center`}>
            <div className='me-auto'>
              <ContentHeader title="Image Settings" dataCount={accountList.length} hideSearch={true} hideTotal={true} />
            </div>
          </div>
          <div className="content-body-container row">
            <div className={`col-12 mb-2`}>
              <ImagesForm
                isFormEdit={isFormEdit}
                setIsFormEdit={setIsFormEdit}
              />
            </div>
          </div>
        </>
    </div>
  )
}

export default ImageSettings
