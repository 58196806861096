import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      navigate('/dashboard');
    }
  }, [])

  return (
    <div className='d-flex justify-content-center align-items-center mt-5'>
      <div className='small-container shadow rounded'>
        <div className='title text-center mb-4'>
          Authentication Required
        </div>
        <p className='text-center'>
          You need to be authenticated to proceed. <br />
          Please login <span className='text-link' onClick={() => loginWithRedirect()}>here.</span>
        </p>
      </div>
    </div>
  )
}

export default Login
