import React from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function DeletePayrollRunLineItemModal(props, args) {

  const removeSelected = () => {
    props.removeSelected();
  }
  return (
    <>
      <ModalHeader toggle={props.toggle}>Delete Payroll Run Line Item</ModalHeader>
      <ModalBody>
        Are you sure you want to delete this item?
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={removeSelected}>
          Yes
        </Button>{' '}
        <Button color="secondary" onClick={props.toggle}>
          No
        </Button>
      </ModalFooter>
    </>

  )
}

export default DeletePayrollRunLineItemModal