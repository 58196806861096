import { get, post, patch } from "./apiService";

export const fetchAccountSettings = async (accountId, setIsFormEdit) => {
  return get({ path: `AccountSettings` }).catch(e => {
    setIsFormEdit(false);
  });
}

export const fetchAccountSettingsEmployee = async (accountId, setAccountSettings) => {
  const response =  get({ path: `AccountSettings` }).catch(e => {
    setAccountSettings();
  });

  return response;
}

export const fetchAccountSettingsCreation = async (accountId, setIsFormEdit) => {
  return get({ path: `AccountSettings` })
}

export const addAccountSettings = async (payload) => {
  return post({ path: 'AccountSettings', body: payload });
}

export const updateAccountSettings = async (payload) => {
  return patch({ path: `AccountSettings/update/${payload.accountSettingsId}`, body: payload });
}