import { get, post, patch } from "./apiService";

export const fetchAccounts = async () => {
  return get({ path: 'Account' });
}

export const fetchAccountById = async (id) => {
  return get({ path: `Account/filtered` });
}

export const addAccount = async (payload) => {
  return post({ path: 'Account', body: payload });
}

export const updateAccount = async (payload) => {
  return patch({ path: `Account/update`, body: payload });
}