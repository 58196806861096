import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const invoiceReducer = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    setInvoicesListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
  },
});

export const {
  setInvoicesListData,
} = invoiceReducer.actions;

export default invoiceReducer.reducer;
