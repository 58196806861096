import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducers from "./rootReducers";

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer (persistConfig, rootReducers)

export default persistedReducer