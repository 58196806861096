import { removeNonDigits } from "./formatUtils";
import { isSuperAdmin, isABMUser } from "./roleUtils";
import _ from 'lodash';

//
/*
 * Gets the id based on the user type
 * payloadAccountId: account ID from the dropdown
 * selectedAccountId: account ID of the masqueraded account
 * accountId: accountID for logged in user
 */ 
export const getAccountIdForEmployee = (payloadAccountId, selectedAccountId, accountId, userType) =>
{
  if(isSuperAdmin(userType))
  {
    return payloadAccountId.value;
  }
  else if(isABMUser(userType))
  {
    return selectedAccountId === null ? accountId : selectedAccountId
  }
  else 
  {
    return accountId
  }
}

export function isValidSIN(sin) {
  // Check if no input
  if (_.isNil(sin)) {
    return false;
  }

  // Remove non-digit characters from the input
  sin = removeNonDigits(sin);

  // Check if the input is exactly 9 digits long
  if (sin.length !== 9) {
    return false;
  }

  // Convert the SIN into an array of digits
  const sinArray = sin.split('').map(Number);

  // Apply the Luhn algorithm to validate the SIN
  let sum = 0;
  for (let i = 0; i < sinArray.length; i++) {
    let digit = sinArray[i];
    if (i % 2 === 1) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
  }

  return sum % 10 === 0;
}