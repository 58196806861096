import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useSelector } from 'react-redux';
import { createPayrollReportFile, emailPayrollReport } from '../../services/reportServices';
import { showToast } from '../../utils/toastUtils';
import { printPreviewReportWithBase64 } from '../../utils/payrollRunUtils';
import _ from 'lodash';

function PayrollReportMenuModal({
  modal,
  toggle,
  payrollRunId,
  args
}) {
  const accountId = useSelector((state) => state.auth.user.accountId);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const email = useSelector((state) => state.auth.user.email);
  const settingsState = useSelector((state) => state.settings);

  const sendPayrollReport = async () => {
    const payload = {
      payrollRunId: payrollRunId,
      accountId: selectedAccountState.accountId === null ? accountId : selectedAccountState.accountId,
      toEmail: settingsState.accountSettings.payrollNotificationEmail || email,
      subject: 'Payroll Report',
      body: 'Report attached here',
      attachmentFileName: 'Payroll Report'
    };

    const response = await emailPayrollReport(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      showToast({
        type: 'error',
        message: data.errorMessage
      });
      return;
    }

    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully emailed' : 'Failed email'} payroll report.`
    });
  }

  const printPayrollReport = async () => {
    const payload = {
      payrollRunId,
      accountId: selectedAccountState.accountId === null ? accountId : selectedAccountState.accountId
    };

    const response = await createPayrollReportFile(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      showToast({
        type: 'error',
        message: data.errorMessage
      });
      return;
    }
    printPreviewReportWithBase64(data.base64);
  }

  return (
    <Modal isOpen={modal} toggle={toggle} {...args}>
      <ModalHeader toggle={toggle}>
        Payroll Report Menu
      </ModalHeader>
      <ModalBody>
        <div className='modal-header-container d-flex flex-row-reverse align-items-center'>
          <button className="btn btn-md btn-primary w-50" onClick={printPayrollReport}>Print</button>
          <button className="btn btn-md btn-primary me-2 w-50" onClick={sendPayrollReport}>Email</button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default PayrollReportMenuModal