import { showToast } from "./toastUtils";

export const expenseEditRules = (params) => {
  if (params.node.rowPinned === 'top') {
    if (params.colDef.field === "Tax Rate") {
      return params.data['Tax Type'] === 5;
    }
    return true;
  }

  if (params.node.rowPinned !== 'top') {

    if (params.api.getPinnedTopRowCount() > 0) {
      if (params.data['Payments'].length < 1) {
        showToast({
          type: "warning",
          message: `Cannot update an expense during creation.`,
          id: "edit-warning"
        });
      } else {
        showToast({
          type: "warning",
          message: `Cannot update an expense that has payments.`,
          id: "edit-warning"
        });
      }
      return false;
    }

    if (params.data['Payments'].length > 0) {
      showToast({
        type: "warning",
        message: `Cannot update an expense that has payments.`,
        id: "edit-warning"
      });
    }

    if (params.colDef.field === "Tax Rate") {
      return params.data['Tax Type'] === 5 && params.data['Payments'].length < 1;
    }

    return params.data['Payments'].length < 1;
  }

  return false;
}

export const expenseEditClass = (params) => {
  if(params.node.rowPinned === 'top') {
    return 'grid-column top-row';
  }

  if(params.node.rowPinned !== 'top' && params.data?.['FormUpdated']) {
    return 'grid-column dirty-row';
  }

  return 'grid-column';
}