export const TAX_CLAIMS = [
  {
    name: 'Alberta (AB)',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '(No claim amount) 0',
      },
      {
        label: 'Claim Code 1',
        value: '(Basic claim) 21,003.00',
      },
      {
        label: 'Claim Code 2',
        value: '21,003.01 - 24,007.00',
      },
      {
        label: 'Claim Code 3',
        value: '24,007.01 - 27,011.00',
      },
      {
        label: 'Claim Code 4',
        value: '27,011.01 - 30,015.00',
      },
      {
        label: 'Claim Code 5',
        value: '30,015.01 - 33,019.00',
      },
      {
        label: 'Claim Code 6',
        value: '33,019.01 - 36,023.00',
      },
      {
        label: 'Claim Code 7',
        value: '36,023.01 - 39,027.00',
      },
      {
        label: 'Claim Code 8',
        value: '39,027.01 - 42,031.00',
      },
      {
        label: 'Claim Code 9',
        value: '42,031.01 - 45,035.00',
      },
      {
        label: 'Claim Code 10',
        value: '45,035.01 - 48,039.00',
      },
    ]
  },
  {
    name: 'British Columbia (BC)',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '(No claim amount) 0',
      },
      {
        label: 'Claim Code 1',
        value: '(Basic claim) 11,981.00',
      },
      {
        label: 'Claim Code 2',
        value: '11,981.01 - 14,677.00',
      },
      {
        label: 'Claim Code 3',
        value: '14,677.01 - 17,373.00',
      },
      {
        label: 'Claim Code 4',
        value: '17,373.01 - 20,069.00',
      },
      {
        label: 'Claim Code 5',
        value: '20,069.01 - 22,765.00',
      },
      {
        label: 'Claim Code 6',
        value: '22,765.01 - 25,461.00',
      },
      {
        label: 'Claim Code 7',
        value: '25,461.01 - 28,157.00',
      },
      {
        label: 'Claim Code 8',
        value: '28,157.01 - 30,853.00',
      },
      {
        label: 'Claim Code 9',
        value: '30,853.01 - 33,549.00',
      },
      {
        label: 'Claim Code 10',
        value: '33,549.01 - 36,245.00',
      },
    ]
  },
  {
    name: 'Manitoba (MB)',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '(No claim amount) 0',
      },
      {
        label: 'Claim Code 1',
        value: '(Basic claim) 10,855.00',
      },
      {
        label: 'Claim Code 2',
        value: '10,855.01 - 12,548.00',
      },
      {
        label: 'Claim Code 3',
        value: '12,548.01 - 14,241.00',
      },
      {
        label: 'Claim Code 4',
        value: '14,241.01 - 15,934.00',
      },
      {
        label: 'Claim Code 5',
        value: '15,934.01 - 17,627.00',
      },
      {
        label: 'Claim Code 6',
        value: '17,627.01 - 19,320.00',
      },
      {
        label: 'Claim Code 7',
        value: '19,320.01 - 21,013.00',
      },
      {
        label: 'Claim Code 8',
        value: '21,013.01 - 22,706.00',
      },
      {
        label: 'Claim Code 9',
        value: '22,706.01 - 24,399.00',
      },
      {
        label: 'Claim Code 10',
        value: '24,399.01 - 26,092.00',
      },
    ]
  },
  {
    name: 'New Brunswick (NB)',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '(No claim amount) 0',
      },
      {
        label: 'Claim Code 1',
        value: '(Basic claim) 12,458.00',
      },
      {
        label: 'Claim Code 2',
        value: '12,458.01 - 15,009.00',
      },
      {
        label: 'Claim Code 3',
        value: '15,009.01 - 17,560.00',
      },
      {
        label: 'Claim Code 4',
        value: '17,560.01 - 20,111.00',
      },
      {
        label: 'Claim Code 5',
        value: '20,111.01 - 22,662.00',
      },
      {
        label: 'Claim Code 6',
        value: '22,662.01 - 25,213.00',
      },
      {
        label: 'Claim Code 7',
        value: '25,213.01 - 27,764.00',
      },
      {
        label: 'Claim Code 8',
        value: '27,764.01 - 30,315.00',
      },
      {
        label: 'Claim Code 9',
        value: '30,315.01 - 32,886.00',
      },
      {
        label: 'Claim Code 10',
        value: '32,886.00 - 35,417.00',
      }
    ]
  },
  {
    name: 'Newfoundland/Labrador (NL)',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '(No claim amount) 0',
      },
      {
        label: 'Claim Code 1',
        value: '(Basic claim) 10,382.00',
      },
      {
        label: 'Claim Code 2',
        value: '10,382.01 - 12,624.00',
      },
      {
        label: 'Claim Code 3',
        value: '12,624.01 - 14,866.00',
      },
      {
        label: 'Claim Code 4',
        value: '14,866.01 - 17,108.00',
      },
      {
        label: 'Claim Code 5',
        value: '17,108.01 - 19,350.00',
      },
      {
        label: 'Claim Code 6',
        value: '19,350.01 - 21,592.00',
      },
      {
        label: 'Claim Code 7',
        value: '21,592.01 - 23,834.00',
      },
      {
        label: 'Claim Code 8',
        value: '23,834.01 - 26,076.00',
      },
      {
        label: 'Claim Code 9',
        value: '26,076.01 - 28,318.00',
      },
      {
        label: 'Claim Code 10',
        value: '28,318.01 - 30,560.00',
      }
    ]
  },
  {
    name: 'Northwest Territories (NT)',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '(No claim amount) 0',
      },
      {
        label: 'Claim Code 1',
        value: '(Basic claim) 16,593.00',
      },
      {
        label: 'Claim Code 2',
        value: '16,593.01 - 19,446.00',
      },
      {
        label: 'Claim Code 3',
        value: '19,446.01 - 22,299.00',
      },
      {
        label: 'Claim Code 4',
        value: '22,299.01 - 25,152.00',
      },
      {
        label: 'Claim Code 5',
        value: '25,152.01 - 28,005.00',
      },
      {
        label: 'Claim Code 6',
        value: '28,005.01 - 30,858.00',
      },
      {
        label: 'Claim Code 7',
        value: '30,858.01 - 33,711.00',
      },
      {
        label: 'Claim Code 8',
        value: '33,711.01 - 36,564.00',
      },
      {
        label: 'Claim Code 9',
        value: '36,564.01 - 39,417.00',
      },
      {
        label: 'Claim Code 10',
        value: '39,417.01 - 42,270.00',
      }
    ]
  },
  {
    name: 'Nova Scotia (NS)',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '(No claim amount) 0',
      },
      {
        label: 'Claim Code 1',
        value: '(Basic claim) 8,481.00',
      },
      {
        label: 'Claim Code 2',
        value: '8,481.01 - 10,081.00',
      },
      {
        label: 'Claim Code 3',
        value: '10,081.01 - 11,681.00',
      },
      {
        label: 'Claim Code 4',
        value: '11,681.01 - 13,281.00',
      },
      {
        label: 'Claim Code 5',
        value: '13,281.01 - 14,881.00',
      },
      {
        label: 'Claim Code 6',
        value: '14,881.01 - 16,481.00',
      },
      {
        label: 'Claim Code 7',
        value: '16,481.01 - 18,081.00',
      },
      {
        label: 'Claim Code 8',
        value: '18,081.01 - 19,681.00',
      },
      {
        label: 'Claim Code 9',
        value: '19,681.01 - 21,281.00',
      },
      {
        label: 'Claim Code 10',
        value: '21,281.01 - 22,881.00',
      }
    ]
  },
  {
    name: 'Nunavut (NU)',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '(No claim amount) 0',
      },
      {
        label: 'Claim Code 1',
        value: '(Basic claim) 17,925.00',
      },
      {
        label: 'Claim Code 2',
        value: '17,925.01 - 20,824.00',
      },
      {
        label: 'Claim Code 3',
        value: '20,824.01 - 23,723.00',
      },
      {
        label: 'Claim Code 4',
        value: '23,723.01 - 26,622.00',
      },
      {
        label: 'Claim Code 5',
        value: '26,622.01 - 29,521.00',
      },
      {
        label: 'Claim Code 6',
        value: '29,521.01 - 32,420.00',
      },
      {
        label: 'Claim Code 7',
        value: '32,420.01 - 35,319.00',
      },
      {
        label: 'Claim Code 8',
        value: '35,319.01 - 38,218.00',
      },
      {
        label: 'Claim Code 9',
        value: '38,218.01 - 41,117.00',
      },
      {
        label: 'Claim Code 10',
        value: '41,117.01 - 44,016.00',
      }
    ]
  },
  {
    name: 'Ontario (ON)',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '(No claim amount) 0',
      },
      {
        label: 'Claim Code 1',
        value: '(Basic claim) 11,865.00',
      },
      {
        label: 'Claim Code 2',
        value: '11,865.01 - 14,421.00',
      },
      {
        label: 'Claim Code 3',
        value: '14,421.01 - 16,977.00',
      },
      {
        label: 'Claim Code 4',
        value: '16,977.01 - 19,533.00',
      },
      {
        label: 'Claim Code 5',
        value: '19,533.01 - 22,089.00',
      },
      {
        label: 'Claim Code 6',
        value: '22,089.01 - 24,645.00',
      },
      {
        label: 'Claim Code 7',
        value: '24,645.01 - 27,201.00',
      },
      {
        label: 'Claim Code 8',
        value: '27,201.01 - 29,757.00',
      },
      {
        label: 'Claim Code 9',
        value: '29,757.01 - 32,313.00',
      },
      {
        label: 'Claim Code 10',
        value: '32,313.01 - 34,869.00',
      }
    ]
  },
  {
    name: 'Prince Edward Island (PE)',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '(No claim amount) 0',
      },
      {
        label: 'Claim Code 1',
        value: '(Basic claim) 12,000.00',
      },
      {
        label: 'Claim Code 2',
        value: '12,000.01 - 13,600.00',
      },
      {
        label: 'Claim Code 3',
        value: '13,600.01 - 15,200.00',
      },
      {
        label: 'Claim Code 4',
        value: '15,200.01 - 16,800.00',
      },
      {
        label: 'Claim Code 5',
        value: '16,800.01 - 18,400.00',
      },
      {
        label: 'Claim Code 6',
        value: '18,400.01 - 20,000.00',
      },
      {
        label: 'Claim Code 7',
        value: '20,000.01 - 21,600.00',
      },
      {
        label: 'Claim Code 8',
        value: '21,600.01 - 23,200.00',
      },
      {
        label: 'Claim Code 9',
        value: '23,200.01 - 24,800.00',
      },
      {
        label: 'Claim Code 10',
        value: '24,800.01 - 26,400.00',
      }
    ]
  },
  {
    name: 'Quebec (QC)',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '17,183',
      },
    ]
  },
  {
    name: 'Saskatchewan (SK)',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '(No claim amount) 0',
      },
      {
        label: 'Claim Code 1',
        value: '(Basic claim) 17,661.00',
      },
      {
        label: 'Claim Code 2',
        value: '17,661.01 - 19,934.00',
      },
      {
        label: 'Claim Code 3',
        value: '19,934.01 - 22,207.00',
      },
      {
        label: 'Claim Code 4',
        value: '22,207.01 - 24,480.00',
      },
      {
        label: 'Claim Code 5',
        value: '24,480.01 - 26,753.00',
      },
      {
        label: 'Claim Code 6',
        value: '26,753.01 - 29,026.00',
      },
      {
        label: 'Claim Code 7',
        value: '29,026.01 - 31,299.00',
      },
      {
        label: 'Claim Code 8',
        value: '31,299.01 - 33,572.00',
      },
      {
        label: 'Claim Code 9',
        value: '33,572.01 - 35,845.00',
      },
      {
        label: 'Claim Code 10',
        value: '35,845.01 - 38,118.00',
      }
    ]
  },
  {
    name: 'Yukon (YT)',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '(No claim amount) 0',
      },
      {
        label: 'Claim Code 1',
        value: '(Basic claim) 15,000.00',
      },
      {
        label: 'Claim Code 2',
        value: '15,000.01 - 17,583.00',
      },
      {
        label: 'Claim Code 3',
        value: '17,583.01 - 20,166.00',
      },
      {
        label: 'Claim Code 4',
        value: '20,166.01 - 22,749.00',
      },
      {
        label: 'Claim Code 5',
        value: '22,749.01 - 25,332.00',
      },
      {
        label: 'Claim Code 6',
        value: '25,332.01 - 27,915.00',
      },
      {
        label: 'Claim Code 7',
        value: '27,915.01 - 30,498.00',
      },
      {
        label: 'Claim Code 8',
        value: '30,498.01 - 33,081.00',
      },
      {
        label: 'Claim Code 9',
        value: '33,081.01 - 35,664.00',
      },
      {
        label: 'Claim Code 10',
        value: '35,664.01 - 38,247.00',
      }
    ]
  },
  {
    name: 'Federal',
    claimCodes: [
      {
        label: 'Claim Code 0',
        value: '(No claim amount) 0',
      },
      {
        label: 'Claim Code 1',
        value: '(Basic claim) 15,000.00',
      },
      {
        label: 'Claim Code 2',
        value: '15,000.01 - 17,583.00',
      },
      {
        label: 'Claim Code 3',
        value: '17,583.01 - 20,166.00',
      },
      {
        label: 'Claim Code 4',
        value: '20,166.01 - 22,749.00',
      },
      {
        label: 'Claim Code 5',
        value: '22,749.01 - 25,332.00',
      },
      {
        label: 'Claim Code 6',
        value: '25,332.01 - 27,915.00',
      },
      {
        label: 'Claim Code 7',
        value: '27,915.01 - 30,498.00',
      },
      {
        label: 'Claim Code 8',
        value: '30,498.01 - 33,081.00',
      },
      {
        label: 'Claim Code 9',
        value: '33,081.01 - 35,664.00',
      },
      {
        label: 'Claim Code 10',
        value: '35,664.01 - 38,247.00',
      },
    ]
  },
];