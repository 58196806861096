import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { isAccountUser } from '../../utils/roleUtils';
import { useSelector } from 'react-redux';
import { SETTINGS_ITEMS } from '../../constants';

function Settings() {
  const userType = useSelector((state) => state.auth.user.userType);

  return (
    <div>
      <div className='d-flex flex-row align-items-center'>
        <div className='me-auto'>
          <div className="content-header-container d-flex flex-row align-items-center">
            <div className="content-title">
              Settings
            </div>
          </div>
        </div>
      </div>
      <div className="content-body-container">
        <div className="content-section-container min-vh-65">
          <div className='settings-container'>
            <div className='settings-stats-container mb-4'>
              <div className='row'>
                {SETTINGS_ITEMS.map((item, index) =>
                (((!item.authorizationCheck || (item.authorizationCheck && !isAccountUser(userType))) &&
                  (<Fragment key={index}>
                    <div className='col-12 col-md-6 col-xl-6 mb-2' >
                      <Link to={item.link} style={{ textDecoration: 'none' }}>
                        <div className="card shadow-sm">
                          <div className="card-body py-3">
                            <div className='d-flex align-items-center'>
                              <div className='text-center'>
                                <div className='stats-icon-container d-flex align-items-center justify-content-center'>
                                  {item.icon}
                                </div>
                              </div>
                              <div className='ms-3'>
                                <span className='setting-label'>{item.label}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Fragment>)
                )))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Settings;
