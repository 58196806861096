import { toast } from 'react-toastify';

export function showToast({ type, message, id = null }) {
  const options = id != null ? { toastId: id } : {};
  switch (type) {
    case 'success':
      toast.success(message, options);
      break;
    case 'info':
      toast.info(message, options);
      break;
    case 'warning':
      toast.warning(message, options);
      break;
    case 'error':
      toast.error(message, options);
      break;
    default:
      toast(message, options);
  }
}