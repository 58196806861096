import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { ModalHeader, ModalBody } from 'reactstrap'
import { AgGridReact } from 'ag-grid-react';
import PayMethodTypeRenderer from '../../Templates/CellRenderers/PayMethodTypeRenderer';
import PayMethodTypeEditor from '../../Templates/Editor/PayMethodTypeEditor';
import { formatCurrency } from '../../../utils/formatUtils';
import UnitsOfPayRenderer from '../../Templates/CellRenderers/UnitsOfPayRenderer';
import UnitsOfPayTooltip from '../Tooltips/UnitsOfPayTooltip';
import ActionRendererPayItem from '../../Templates/CellRenderers/PayrollRuns/ActionRendererPayItem';
import { addPayrollRunLineItemPayItem, 
  fetchPayrollRunLineItemPayItems, 
  updatePayrollRunLineItemPayItem, 
  deletePayrollRunLineItemPayItem 
} from '../../../services/payrollRunServices';
import { overtimeValueGetter, 
  overtimeCellClass, 
  editOvertimePayUnit,
  noteCellClass,
  noteValueFormatter,
  notesToolTipValueGetter,
  unitsOfPayCellClass
} from '../../../utils/payrollRunUtils';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { showToast } from '../../../utils/toastUtils';
import { NumericCellEditor } from '../Editor/NumericCellEditor';
import _ from "lodash";
import { formatEllipsis } from '../../../utils/formatUtils';
import NoteRenderer from '../../Templates/CellRenderers/PayrollRuns/NoteRenderer';
import PayrollRunNotesTooltip from '../Tooltips/PayrollRunNotesToolTip';

function EditPayrollRunLineItemModal(props, args) {
  const accountId = useSelector((state) => state.auth.user.accountId);
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const selectedNode = props.lineItemGridRef.current.api.getSelectedNodes();

  const editPayMethod = (params, isEditable) => {

    if (params.data['Pay Method Type'] === 0) {
      return false;
    }

    return isEditable;
  }

  const [columnDefs, setColumnDefs] = useState();

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      resizable: true,
      cellHeight: 10,
    };
  }, []);

  useEffect(() => {
    setColumnDefs(columnDefinitions(props.editable))
  }, [])

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  useEffect(() => {
    var payrollRunLineItemData = selectedNode[0].data
    var payrollRunLineItemDataitemsData = selectedNode[0].data["data"]
    let data = [];
    _.each(payrollRunLineItemDataitemsData, (item, index) => {
      data.push({
        '#': index + 1,
        'id': item.payrollRunLineItemPayItemId,
        "Employee": selectedNode[0].data["Employee"],
        "User Id": item.userId,
        "First Pay Item Id": item.payrollRunLineItemPayItemId,
        "Pay Method Type": item.payMethodType,
        "Notes": item.notes,
        "Units of Pay": item.unitsOfPay,
        "Pay Per Unit": item.payPerUnit,
        "Overtime Pay Unit": item.overtimePayUnit,
        "Payroll Run Id": payrollRunLineItemData["Payroll Run Id"],
        "Payroll Run Line Item Id": item.payrollRunLineItemId,
        "Account Id": payrollRunLineItemData["Account Id"]
      });
    });

    setRowData(data);

  }, [selectedNode[0].data["data"]])

  useEffect(() => {
    if (gridRef.current.columnApi !== undefined) {
      gridRef.current.columnApi.setColumnVisible("Action", props.editable)
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [columnDefs, props.editable])

  const columnDefinitions = (isEditable, isPrintableLayout = false, hideActionButtonOnly = false) => {
    const defs = [
      {
        field: '#',
        width: 50,
        minWidth: 50,
        suppressSizeToFit: true,
        cellClass: ['no-borders', 'payroll-column-value'],
        cellStyle: { fontWeight: 'bold' },
        headerClass: 'payroll-column-header'
      },
      {
        field: 'Employee',
        minWidth: 150,
        width: 150,
        headerName: 'Employee',
        suppressSizeToFit: true,
        cellClass: ['no-borders', 'payroll-column-value'],
        headerClass: 'payroll-column-header'
      },
      {
        field: 'Pay Method Type',
        headerName: 'Pay Method',
        minWidth: 110,
        width: 110,
        suppressSizeToFit: true,
        cellClass: ['no-borders', 'payroll-column-value'],
        cellRendererSelector: (params) => {
          if (params.data['Pay Method Type'] !== undefined) {
            return {
              component: PayMethodTypeRenderer
            }
          } else {
            return undefined;
          }
        },
        cellEditor: PayMethodTypeEditor,
        cellEditorParams: {
          gridRef: gridRef,
        },
        editable: isEditable,
        headerClass: 'payroll-column-header',
      },
      {
        field: 'Notes',
        minWidth: 110,
        editable: (params)=> { 
          return isEditable && params.data["Pay Method Type"] === 3
        },
        cellClass: noteCellClass,
        getQuickFilterText: params => { return params.value; },
        headerClass: 'payroll-column-header',
        cellRenderer: NoteRenderer,
        tooltipValueGetter: notesToolTipValueGetter,
        cellEditorParams: {
          maxLength: 50
        }
      },
      {
        field: 'Units of Pay',
        minWidth: 110,
        width: 110,
        cellClass: unitsOfPayCellClass,
        cellRenderer: UnitsOfPayRenderer,
        getQuickFilterText: params => { return params.value; },
        editable: params => { return editPayMethod(params, isEditable) },
        cellEditor: NumericCellEditor,
        headerClass: 'payroll-column-header',
        tooltipComponent: UnitsOfPayTooltip,
        tooltipField: 'Units of Pay',
        tooltipComponentParams: { isEditable: isEditable }
      },
      {
        field: 'Pay Per Unit',
        minWidth: 110,
        width: 110,
        cellClass: ['no-borders', 'payroll-column-value'],
        valueFormatter: params => { return formatCurrency(params.value) },
        getQuickFilterText: params => { return formatCurrency(params.value); },
        cellEditor: NumericCellEditor,
        editable: isEditable,
        headerClass: 'payroll-column-header'
      },
      {
        field: 'Overtime Pay Unit',
        headerName: 'OT Unit',
        minWidth: 90,
        width: 90,
        cellClass: overtimeCellClass,
        getQuickFilterText: params => { return params.value; },
        valueGetter: (params) => overtimeValueGetter(params),
        cellEditor: NumericCellEditor,
        editable: params => { return editOvertimePayUnit(params, isEditable) },
        headerClass: 'payroll-column-header'
      },
      {
        field: 'Action',
        headerName: '',
        minWidth: 100,
        width: 120,
        cellRenderer: ActionRendererPayItem,
        cellRendererParams: {
          removeSelected: removeSelected,
          gridRef: gridRef
        },
        autoHeight: true,
        headerClass: 'payroll-column-header-header',
        cellClass: 'd-flex flex-row-reverse',
      },
    ]

    return defs;
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  }

  const removeSelected = useCallback(async (params) => {
    const selectedNode = gridRef.current.api.getSelectedNodes();

    const response = await deletePayrollRunLineItemPayItem(selectedNode[0].data["id"])

    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully deleted' : 'Failed to delete'} pay item.`
    });

    if (response.ok) {
      fillPayrollRunLineItemPayItemList();
      props.onUpdate(await response.json());
      setColumnDefs(columnDefinitions(props.editable))
    }

  }, [rowData]);

  const onCellKeyDown = useCallback(
    async (params) => {
      if (params.event.shiftKey && params.event.keyCode === 9 && params.column.getColDef().field === 'Pay Method Type') {
        if (params.value === "" || params.value === ".") return
        submitRowEdit(params)
      }

      if (params.event.keyCode === 9 &&
        params.column.getColDef().field === 'Overtime Pay Unit') {
        if (params.value === "" || params.value === ".") return
        submitRowEdit(params)

        return false;
      }
    },
  );

  const submitRowEdit = async (params) => {
    let response = null;

    const payload = {
      payMethodType: parseInt(params.data["Pay Method Type"]),
      notes: params.data["Notes"],
      unitsOfPay: parseInt(params.data["Pay Method Type"]) === 0 ? 1 : params.data["Units of Pay"],
      payPerUnit: params.data["Pay Per Unit"],
      overtimePayUnit: params.data["Overtime Pay Unit"],
      payrollRunId: selectedNode[0].data['Payroll Run Id'],
      payrollRunLineItemId: params.data["Payroll Run Line Item Id"],
      payrollRunLineItemPayItemId: params.data["id"],
      accountId: accountId,
    };

    response = await updatePayrollRunLineItemPayItem(payload, params.data["id"]);

    if (response.ok) {
      const responseData = await response.json()
      props.onUpdate(responseData);
      setColumnDefs(columnDefinitions(props.editable));
      params.data["Notes"] = payload.payMethodType === 3 ? payload.notes : null;
    }

    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully updated' : 'Failed to update'} pay item.`
    });
  };

  const onCellEditingStopped = useCallback(
    async (params) => {
      // return
      const editingCells = params.api.getEditingCells();

      if ((params.newValue === "" || params.newValue === ".") && params.column.colId !== "Notes") {
        const toastId = "invalid-value-error"
        if (!toast.isActive(toastId)) {
          showToast({
            type: 'error',
            message: 'Please enter a valid value',
            id: toastId
          });
        }

        params.api.stopEditing(false);
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.column.getId()
        });
        params.node.setDataValue(params.colDef.field, params.oldValue);
        return;
      }

      // if (!params.valueChanged) return
      if (((params.value === "" || params.value === ".") && params.column.colId !== "Notes") || !_.isEmpty(editingCells)) return
      submitRowEdit(params)
    },
  );

  const onCellDoubleClicked = (params) => {
    const editingCells = params.api.getEditingCells();
    setTimeout(() => {
      if (params.value === "" || params.value === "." || _.isEmpty(editingCells)) {
        params.api.stopEditing(false);
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.column.getId()
        });
      }
    }, 500);
  }

  const fillPayrollRunLineItemPayItemList = async () => {
    let response = null;
    response = await fetchPayrollRunLineItemPayItems(selectedNode[0].data["id"])
    // const items = response.slice(1);

    let data = [];
    _.each(response, (item, index) => {
      data.push({
        '#': index + 1,
        'id': item.payrollRunLineItemPayItemId,
        "Employee": selectedNode[0].data["Employee"],
        "User Id": item.userId,
        "First Pay Item Id": item.payrollRunLineItemPayItemId,
        "Pay Method Type": item.payMethodType,
        "Notes": item.notes,
        "Units of Pay": item.unitsOfPay,
        "Pay Per Unit": item.payPerUnit,
        "Overtime Pay Unit": item.overtimePayUnit,
        "Payroll Run Line Item Id": item.payrollRunLineItemId,
      });
    });

    setRowData(data);
  }

  const createPayrollRunLineItemPayItem = useCallback(async (params) => {
    
    const payload = {
      payrollRunLineItemPayItemId: 0,
      payMethodType: 0,
      unitsOfPay: 0,
      payPerUnit: 0,
      overtimePayUnit: 0,
      accountId: selectedNode[0].data['Account Id'],
      userId: selectedNode[0].data['User Id'],
      payrollRunId: selectedNode[0].data['Payroll Run Id'],
      payrollRunLineItemId: selectedNode[0].data['id'],
    }

    const response = await addPayrollRunLineItemPayItem(payload);

    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully added' : 'Failed to add'} pay item.`
    });

    if (response.ok) {
      fillPayrollRunLineItemPayItemList();
      setColumnDefs(columnDefinitions(props.editable))
    }

  }, []);

  return (
    <>
      <ModalHeader toggle={props.toggleClose}>
        Pay Items
      </ModalHeader>
      <ModalBody>
        {(props.editable === true) &&
          <div className='modal-header-container d-flex flex-row-reverse align-items-center'>
            <button className="btn btn-md btn-primary me-2" onClick={createPayrollRunLineItemPayItem}>Add Pay Item</button>
          </div>
        }

        <div className="content-body-container row">
          <div className={`col-12`}>
            <div className={`ag-theme-alpine content-section-container`} >
              <AgGridReact
                className='no-header'
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={70}
                rowSelection={'single'}
                onCellEditingStopped={onCellEditingStopped}
                onCellKeyDown={onCellKeyDown}
                onCellDoubleClicked={onCellDoubleClicked}
                tooltipShowDelay={1000}
                tooltipHideDelay={100000}
              >
              </AgGridReact>

            </div>

          </div>
        </div>
        <div className='d-flex flex-row-reverse align-items-center mt-3'>
          <button className="btn btn-primary" type="button" onClick={props.toggleClose}>Close</button>
        </div>
      </ModalBody>
    </>
  )
}

export default EditPayrollRunLineItemModal