import React from 'react'
import { Link } from 'react-router-dom'

function SetupSettings({payrollSettingsIsEmpty, accountingSettingsIsEmpty}) {
  const getMessage = () => {
    var message = ""

    if(payrollSettingsIsEmpty && accountingSettingsIsEmpty)
    {
      message = <>
        <Link to="/settings/accounting-settings">Accounting</Link> and <Link to="/settings/payroll-settings">Payroll</Link> settings
      </>
    }
    else if(payrollSettingsIsEmpty)
    {
      message = <>
        <Link to="/settings/payroll-settings">Payroll</Link> settings
      </>
    }
    else {
      message = <>
        <Link to="/settings/accounting-settings">Accounting</Link> settings
      </>
    }
    return message;
  }

  return (
    <div className='d-flex justify-content-center align-items-center mt-5'>
      <div className='small-container shadow rounded'>
        <div className='title text-center mb-4'>
          Additional Setup Required
        </div>
          <p className='text-center'>
            The {getMessage()} for this account have not been configured.
          </p>
      </div>
    </div>
  )
}

export default SetupSettings