import numberRenderer from '../components/Templates/CellRenderers/Quotes/numberRenderer';
import { provinceCheck } from './provinceUtils';

export const displayStatus = (status) => {
  const obj = {
    0: "Draft",
    1: "Approved",
    2: "Archived"
  }
  return obj[status]
}

export const statusValueGetter = (params) => {
  const obj = {
    0: "Draft",
    1: "Approved",
    2: "Archived"
  }
  return obj[params.data.Status]
}

export function statusFilter(gridRef) {
  var filterComponent = gridRef.current.api.getFilterInstance('Status');
  filterComponent.setModel({
    filterType: 'text',
    type: 'notContains',
    filter: 'archived',
  });
  gridRef.current.api.onFilterChanged();
}

export const currencyRendererSelector = (params) => {
  const title = params.colDef.field
  const selector = params.data[title] !== undefined ? { component: numberRenderer } : undefined
  return selector
}

export const calculateQuote = (params, province, isPinnedRowDataCompleted) => {

  const header = params.colDef.field

  if (header === "QTY") {
    params.data["QTY"] = parseFloat(params.newValue) || 0;
  }

  if (header === "Unit Price") {
    params.data["Unit Price"] = parseFloat(params.newValue) || 0;
  }

  if (header === "Discount") {
    params.data["Discount"] = parseFloat(params.newValue) || 0;
  }

  if (header === "Tax Type") {
    params.data["Tax Type"] = params.newValue
    const taxRate = provinceCheck(province, params.newValue);
    params.data["Tax Rate"] = taxRate;
  }

  if (header === "Tax Rate") {
    params.data["Tax Rate"] = params.newValue || 0;
    if(params.data["Tax Type"] === 5) {
      params.data["Tax Rate"] = params.newValue / 100;
    } else {
      const taxRate = provinceCheck(province, params.newValue);
    params.data["Tax Rate"] = taxRate;
    }
  }

  // Perform calculations
  const subtotal = params.data["QTY"] * params.data["Unit Price"] - params.data["Discount"];
  const totalTax = subtotal * params.data["Tax Rate"];
  const total = subtotal + totalTax;
  const balance = subtotal + totalTax;

  params.data["Subtotal"] = subtotal;
  params.data["Total Tax"] = totalTax;
  params.data["Total"] = total;
  params.data["Balance"] = balance;

  if (params.newValue !== params.oldValue) {
    const paramsCopy = { ...params.data };
    paramsCopy["FormUpdated"] = true;
    paramsCopy["FormCompleted"] = params?.node?.rowPinned === 'top' ? (isPinnedRowDataCompleted(params) ? true : false) : true;
    params.node.setData(paramsCopy);
  }

  return true;
}

export const updatedRowCheck = (params, isPinnedRowDataCompleted) => {
  if (params.newValue !== params.oldValue) {
    const paramsCopy = { ...params.data };
    paramsCopy[params.colDef.field] = params.newValue;
    paramsCopy["FormUpdated"] = true;
    paramsCopy["FormCompleted"] = params?.node?.rowPinned === 'top' ? (isPinnedRowDataCompleted(params) ? true : false) : true;
    params.node.setData(paramsCopy);
  }
  return true;
}