import React from 'react'

function TwoRowLabelRenderer(props) {
  const data = props.data["Customer"]
  return (
    <div className="two-row-label-renderer-container">
      <div className="label1">
        {data['label1']}
      </div>
      <div className="label2">
        {data['label2']}
      </div>
    </div>
  )
}

export default TwoRowLabelRenderer