import React from 'react'

function LoadingPage(props) {
  return (
    <div className='d-flex justify-content-center align-items-center mt-5'>
      <div className='small-container shadow rounded'>
        <div className='title text-center mb-4'>
          Loading Data
        </div>
        <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
      </div>
    </div>
  )
}

export default LoadingPage