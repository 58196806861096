export const processCSVExportCellCallback = (params) => {
    if (
      params.column.getColDef().field === 'Tax Type'
    ) {
      switch (params.value) {
        case 0:
          return 'none';
        case 1:
          return 'GST';
        case 2:
          return 'PST';
        case 3:
          return 'HST';
        case 4:
          return 'GST/PST';
        default:
          return 'None'; // Convert to string for other cases or invalid values
      }
    }
    return params.value
  };