import React, { useEffect } from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import moment from 'moment';
import { showToast } from '../../../utils/toastUtils';
import { updateInvoice } from '../../../services/invoiceServices';
import ReactDatePicker from "react-datepicker";
import { current } from '@reduxjs/toolkit';

function EditInvoiceModal({
  modal,
  toggle,
  currentInvoice,
  fillInvoiceData,
  fillTableData,
  args
}) {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    setFocus,
    control,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    setValue("invoiceDate", moment(currentInvoice['invoiceDate']).hour(7).minute(0).second(0).toDate());
    setValue("paymentDueDate", moment(currentInvoice['paymentDueDate']).hour(7).minute(0).second(0).toDate());
  }, [currentInvoice])

  const onSubmit = async (payload) => {
    let response = null;

    var invoiceDate = moment(payload.invoiceDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    var paymentDueDate = moment(payload.paymentDueDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');

    _.set(payload, 'invoiceDate', invoiceDate);
    _.set(payload, 'paymentDueDate', paymentDueDate);

    _.set(payload, 'invoiceId', currentInvoice.invoiceId);
    _.set(payload, 'accountId', currentInvoice.accountId);

    response = await updateInvoice(payload);

    if (response.ok) {
      await fillInvoiceData();
      await fillTableData();
      toggle();
    }
    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully updated' : 'Failed to update'} invoice.`
    });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size='lg' backdrop='static' {...args}>
      <ModalHeader toggle={toggle}>Edit Invoice</ModalHeader>
      <ModalBody>
        <form className="row form-container" id="invoice-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-6 mb-2">
            <label htmlFor="quoteName" className="form-label">Invoice Id</label>
            <input type="text" className="form-control" id="quoteName"
              defaultValue={currentInvoice?.quoteName || ''}
              {...register("quoteName", { required: "Invoice No. is required" })}
            />
            <small className='form-error-message'>
              {errors?.quoteName && errors.quoteName.message}
            </small>
          </div>

          <div className="col-6 mb-2">
            <label htmlFor="invoiceDate" className="form-label">Invoice Date:</label>
            <Controller
              defaultValue={new Date()}
              control={control}
              name='invoiceDate'
              register={register}
              rules={{ required: "Invoice date is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactDatePicker
                  className='datepicker-field'
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  id="invoiceDate"
                  dateFormat='yyyy-MM-dd'
                  ref={(elem) => {
                    elem && ref(elem.input);
                  }}
                />
              )}
            />
            <small className='form-error-message'>
              {errors?.invoiceDate && errors.invoiceDate.message}
            </small>
          </div>

          <div className="col-6 mb-2">
            <label htmlFor="paymentDueDate" className="form-label">Invoice Due Date:</label>
            <Controller
              defaultValue={new Date()}
              control={control}
              name='paymentDueDate'
              register={register}
              rules={{ required: "Invoice due date is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactDatePicker
                  className='datepicker-field'
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  id="paymentDueDate"
                  dateFormat='yyyy-MM-dd'
                  ref={(elem) => {
                    elem && ref(elem.input);
                  }}
                />
              )}
            />
            <small className='form-error-message'>
              {errors?.paymentDueDate && errors.paymentDueDate.message}
            </small>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => { handleSubmit(async (payload) => await onSubmit(payload))() }}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditInvoiceModal