import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import './../Customers/css/customers.css'
import EditFormRenderer from '../Templates/CellRenderers/Commons/EditFormRenderer';
import ExpenseItemForm from './ExpenseItemForm';
import LabourForm from './LabourForm'
import ProductMaterialForm from './ProductMaterialForm'
import QuoteTaxableRenderer from '../Templates/CellRenderers/QuoteTaxableRenderer';
import ItemsContentHeader from '../Commons/Layouts/ItemsContentHeader';
import { fetchItems, fetchAccount } from '../../services/expenseCategoryServices';
import { getUrl } from '../../utils/expenseCategoryUtils';
import _ from 'lodash';
import { isABMUser, isSuperAdmin } from '../../utils/roleUtils';
import { formatTaxRate } from '../../utils/formatUtils';
import { useSelector } from 'react-redux';
import { fetchAccountById } from '../../services/accountServices';

function ExpenseCategories() {
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const [formType] = useState("none");
  const [province, setProvince] = useState(-1);
  const [addButton, setAddButton] = useState("Add Expense Item")
  const [page, setPage] = useState("expense")
  const [isFormHidden, setIsFormHidden] = useState(true);

  const toggleFormDisplay = (isEdit = false, itemId = null) => {
    if (isFormHidden) {
      setSelectedItemId(itemId);
    } else {
      setSelectedItemId(null)
    }
    if (modal) {
      setModal(!modal)
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 50);
  }

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    if (isFormDirty) {
      toggle()
      return
    }
    toggleFormDisplay()
  }

  const toggleHeaderFormDisplay = (isEdit = false, itemId = null) => {
    if (isFormDirty) {
      toggle()
      return
    }
    if (isFormHidden) {
      setSelectedItemId(itemId);
    } else {
      setSelectedItemId(null)
    }
    if (modal) {
      setModal(false)
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(true);
    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 50);
  }

  useEffect(() => {
    fillAccountData()
    fillItemList('expense')
    focusOnOpenOrCloseButton()
  }, [])

  const fillItemList = async (page) => {
    let response = null;
    var selectedWorkingAccount = isABMUser(userType) && selectedAccountState.accountId !== null
    if(selectedWorkingAccount) {
      response = await fetchItems(getUrl(selectedAccountState.accountId, page, userType, selectedWorkingAccount))
    } else {
      response = await fetchItems(getUrl(accountId, page, userType))
    }

    setItemList(response);
  }

  const fillAccountData = async (page) => {
    // const data = await fetchAccount("Account/" + accountId)
    const data = await fetchAccountById("Account/" + accountId)

    setProvince(data['province'])
  }

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  }

  const fillSelectedItem = (id) => {
    var item = null
    switch (page) {
      case "expense":
        item = _.find(itemList, { expenseItemId: id });
        setSelectedItem(item);
        break;
      case "product":
        item = _.find(itemList, { productMaterialId: id });
        setSelectedItem(item);
        break;
      case "labour":
        item = _.find(itemList, { labourId: id });
        setSelectedItem(item);
        break;
      default:
        item = _.find(itemList, { expenseItemId: id });
        setSelectedItem(item);
        break;
    }
  }

  const clearFilter = () => {
    if (gridRef.current.api !== undefined) {
      gridRef.current.api.setQuickFilter(undefined);
    }
  }

  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColumnDefs = [
    { field: 'index', width: 60, minWidth: 60, cellClass: 'no-borders' },
    { field: 'Title', minWidth: 150, },
    { field: 'Description', minWidth: 150, },
    { field: 'Tax Type', minWidth: 100, cellRenderer: QuoteTaxableRenderer },
    { field: 'Default Rate', minWidth: 100, valueGetter: params => formatTaxRate(params.data["Default Rate"]) },
    { field: 'GL Code', minWidth: 150, },
    {
      field: 'Edit',
      minWidth: 150,
      headerName: '',
      cellClass: 'd-flex flex-row-reverse',
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay }
    },
  ]

  const columnDefsWithRow = [
    { field: 'index', width: 50, minWidth: 50 },
    { field: 'Title', minWidth: 100 },
    { field: 'Description', headerName: "Description", minWidth: 100 },
  ]

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [itemList, setItemList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  useEffect(() => {
    let data = [];


    _.each(itemList, (item, index) => {
      data.push({
        'index': index + 1,
        'Item': item.labourId != null ? item.labourId : item.productMaterialId != null ? item.productMaterialId : item.expenseItemId,
        'Title': item.labourTitle != null ? item.labourTitle : item.expenseTitle,
        'Description': item.labourDescription != null ? item.labourDescription : item.expenseDescription,
        'Tax Type': item.taxType,
        'Default Rate': item.defaultRate,
        'GL Code': item.glCode.description,
        'Account': item.accountId,
        'Notes': item.notes,
        'Active': item.active,
        'Edit': { id: item.labourId != null ? item.labourId : item.productMaterialId != null ? item.productMaterialId : item.expenseItemId }
      });
    });
    setRowData(data);
  }, [itemList])

  useEffect(() => {
    setColumnDefs(isFormHidden ? defaultColumnDefs : columnDefsWithRow);
  }, [isFormHidden])

  useEffect(() => {
    fillSelectedItem(selectedItemId);
  }, [selectedItemId]);

  function formSwitch() {
    const props = {
      isFormEdit,
      selectedItem,
      isFormHidden,
      fillItemList,
      toggleFormDisplay,
      setIsFormDirty,
      modal,
      setModal,
      toggle,
      province,
      focusOnOpenOrCloseButton,
    };

    switch (page) {
      case "expense":
        return <ExpenseItemForm  {...props} />
      case "product":
        return <ProductMaterialForm {...props} />
      case "labour":
        return <LabourForm {...props}/>
      default:
        return <ExpenseItemForm {...props} />
    }
  }

  // Resizes the ag-grid columns to fit the component everytime the form component gets opened/closed
  useEffect(() => {
    if (typeof gridRef.current.api != "undefined") {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [formType])

  function setPageData(header, button, value) {
    setPage(value)
    setAddButton(button)
    fillItemList(value)
  }

  function pageChange(pageName) {
    switch (pageName) {
      case "expense":
        setPageData("Expense Items", "Add Expense Item", pageName);
        break;
      case "product":
        setPageData("Product Materials", "Add Product Material", pageName);
        break;
      case "labour":
        setPageData("Labour", "Add Labour Item", pageName);
        break;
      default:
        setPageData("Expense Items", "Add Expense Item", 'expense');
        break;
    }
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  }

  const focusOnOpenOrCloseButton = () => {
    openOrCloseButtonRef.current.focus();
  }

  return (
    <div >
      <div className='d-flex flex-row align-items-center content-header-container'>
        <div className='me-auto'>
          <ItemsContentHeader page={page} dataCount={rowData.length} pageChange={(event) => pageChange(event)} gridRef={gridRef} filterData={filterData} clearFilter={clearFilter} toggleHeaderFormDisplay={toggleHeaderFormDisplay}/>
        </div>
        {!isSuperAdmin(userType) ?
          <button
            className={`btn btn-lg ${isFormHidden ? 'btn-primary' : 'btn-secondary'}`}
            type="submit"
            onClick={toggleFormCancelDisplay}
            ref={openOrCloseButtonRef}
          >
            {isFormHidden ? addButton : 'Close'}
          </button>
          :
          <button
            className={`btn btn-lg ${isFormHidden ? 'd-none' : 'btn-secondary'}`}
            type="submit"
            onClick={toggleFormCancelDisplay}
          >
            {isFormHidden ? '' : 'Close'}
          </button>
        }
      </div>
      <div className="me-auto col-2">
      </div>
      <div className='content-body-container row'>
        <div className={`${isFormHidden ? 'col-12' : 'col-12 col-lg-5 min-vh-25'} mb-2`}>
          <div className={`ag-theme-alpine content-section-container`} >
            {/* <h4>{header}</h4> */}
            <AgGridReact
              className='no-header'
              // headerHeight={headerHeight}
              rowData={rowData}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onColumnSizeChanged={onColumnsSizeChanged}
              onGridSizeChanged={onGridSizeChanged}
              rowSelection={'single'}
              rowHeight={70}
            >
            </AgGridReact>
          </div>
        </div>
        <div className={`col-12 col-lg-7 ${isFormHidden ? 'd-none' : ''}`}>
          {formSwitch()}
        </div>

      </div>
    </div>
  )
}

export default ExpenseCategories
