import React from 'react'
import { TAX_CLAIMS } from '../../claimCodesValues';

function ClaimCodesSheet() {
  return (
    <div>
      <div className='d-flex flex-row align-items-center'>
        <div className='me-auto'>
          <div className="content-header-container d-flex flex-row align-items-center">
            <div className="content-title">
              Claim Codes
            </div>
          </div>
        </div>
      </div>
      <div className="content-body-container">
        <div className="content-section-container py-2">
          <div className='row'>
            {TAX_CLAIMS.map((taxClaim, index) =>
              <div key={index} className='col-12 col-sm-3 border pt-3'>
                <p className='text-black fw-bold mb-2'>{taxClaim.name}</p>
                <div className='row'>
                  {taxClaim.claimCodes.map((claimCode, index) =>
                    <>
                      <div key={index} className='col-4'>
                        <p className='text-decoration-underline mb-1'>{claimCode.label}</p>
                      </div>
                      <div key={index} className='col-8'>
                        <p className='mb-1 text-center'>{claimCode.value}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClaimCodesSheet
