import { applyMiddleware, compose } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import persistedReducer from './persistedReducer';

const reduxDevTool =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(thunk.withExtraArgument()))
    : compose(applyMiddleware(thunk.withExtraArgument()));

const store = configureStore({
  reducer: persistedReducer,
  devTools: reduxDevTool,
  middleware: [thunk]
});
const persistor = persistStore(store)

export { store, persistor }
