import React from 'react';

function StatusRenderer(props) {
  const status = props.data["Status"];

  let backgroundColor = '#334d6e';
  let color = "#ffffff"
  let label = ""
  let padding = 15;
  switch (status) {
    case false:
      backgroundColor = "#ffd542";
      label = "Draft";
      padding = 32;
      break;
    case true:
      backgroundColor = '#2fd47a'
      label = 'Committed';
      break;
    default:
      backgroundColor = "#ffd542";
      label = "Draft";
      padding = 32;
      break;
  }

  return (
    <div style={{ backgroundColor: backgroundColor, color: color, paddingLeft: padding, paddingRight: padding }}>
      <p className='mb-0 fw-bold'>{label.toUpperCase()}</p>
    </div>
  )
}

export default StatusRenderer