import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import _ from 'lodash';
import { showToast } from '../../utils/toastUtils';
import { isSuperAdmin, isABMUser, isAccountAdmin } from '../../utils/roleUtils';
import ModalRender from '../Commons/ModalRender';
import { provinceCheck } from '../../utils/provinceUtils';
import { addExpenseItem, updateExpenseItem } from '../../services/expenseCategoryServices';
import { fetchGLCodesByUserType } from '../../services/glCodeServices';
import { NumericFormat } from 'react-number-format';
import { removeNumberFormat } from '../../utils/formatUtils';
import { useSelector } from 'react-redux';
import { TAX_TYPES } from '../../constants';

function ExpenseItemForm({
  isFormEdit = false,
  selectedItem = {},
  isFormHidden,
  fillItemList,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  province,
  focusOnOpenOrCloseButton
}) {
  const { register, handleSubmit, setValue, clearErrors, reset, setFocus, control, formState: { errors, isDirty } } = useForm();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const [gLCodeList, setGLCodeList] = useState([]);
  const [gLCode, setGLCode] = useState("");
  const [defaultTaxCode, setDefaultTaxCode] = useState("")
  const accountState = useSelector((state) => state.account.selectedAccount);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  
  useEffect(() => {
    if (isFormEdit) {
      setDefaultTaxCode(parseInt(selectedItem['taxType']));
      setGLCode(parseInt(selectedItem['glCodeId']));
      setValue("taxType", parseInt(selectedItem['taxType']));
      setValue("glCodeId", parseInt(selectedItem['glCodeId']));
      setValue("active", selectedItem['active'])
      setValue("defaultRate", (parseFloat(selectedItem["defaultRate"]) * 100).toFixed(3))
    } else {
      setDefaultTaxCode("");
      setGLCode("");
      reset()
    }

  }, [selectedItem["expenseItemId"]])

  // useEffects
  useEffect(() => {
    clearErrors();
    fillGLCode(accountId);
  }, []);

  useEffect(() => {
    clearErrors();
    reset();
    if(!isFormHidden){
      setTimeout(() => {
        setFocus("expenseTitle");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    setIsFormDirty(isDirty)
  }, [isDirty])

  const fillGLCode = async (id) => {
    const accountTypeId = parseInt(process.env.REACT_APP_ACCOUNT_TYPE_COST_OF_GOODS_SOLD);
    let response = await fetchGLCodesByUserType(userType, selectedAccountState.accountId, accountId, accountTypeId)
    setGLCodeList(response);
  }

  const getActiveDefaultValue = () => {
    if (selectedItem.length <= 0) {
      return 'true';
    }

    return selectedItem.active ? 'true' : '';
  }

  const onSubmit = async (payload) => {
    let response = null;

    _.set(payload, 'defaultRate', removeNumberFormat(payload.defaultRate) / 100);

    if (isFormEdit) {
      _.set(payload, 'expenseItemId', selectedItem.expenseItemId);
      _.set(payload, 'accountId', selectedItem.accountId);
      response = await updateExpenseItem(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully updated' : 'Failed to update'} expense item.`
      });
    } else {
      _.set(payload, 'expenseItemId', 0);
      if(isABMUser(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }
      response = await addExpenseItem(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully added' : 'Failed to add'} expense item.`
      });
    }

    if (response.ok) {
      fillItemList("expense");
      toggleFormDisplay();

      if (isDirty) {
        setModal(false)
      }
      setIsFormDirty(false)
      focusOnOpenOrCloseButton();
    }

  }

  function setDefaultRate(e) {
    const value = e.target.value
    setValue('defaultRate', (provinceCheck(province, value) * 100).toFixed(3))
  }

  useEffect(() => {
    if((isSuperAdmin || isABMUser(userType)) && isFormEdit && !isFormHidden && selectedItem["accountId"] !== undefined) {
      fillGLCode(selectedItem["accountId"]);
    }
  }, [selectedItem])

  return (
    <>
      <div className='content-section-container'>
        <div className='d-flex flex-row align-items-center mb-5'>
          <div className='form-title me-auto'>
            {`${isFormEdit ? 'Update' : 'Add'} Expense Item`}
          </div>
          <button className="btn btn-primary" form='expense-item-form' type="submit" tabIndex={8}>Save</button>
        </div>
        <form className="row form-container" id="expense-item-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="expenseTitle" className="form-label">Title</label>
            <input type="text" className="form-control" id="expenseTitle" tabIndex={1}
              defaultValue={isFormEdit ? selectedItem?.expenseTitle || '' : ''}
              {...register("expenseTitle", { required: "Title is required" })}
            />
            <small className='form-error-message'>
              {errors?.expenseTitle && errors.expenseTitle.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="expenseDescription" className="form-label">Description</label>
            <input type="text" className="form-control" id="expenseDescription" tabIndex={2}
              defaultValue={isFormEdit ? selectedItem?.expenseDescription || '' : ''}
              {...register("expenseDescription", { required: "Description is required" })}
            />
            <small className='form-error-message'>
              {errors?.expenseDescription && errors.expenseDescription.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="taxType" className="form-label">Tax Type:</label>
            <select className="form-select" id="taxType" defaultValue={defaultTaxCode} tabIndex={3} {...register("taxType", { onChange: (e) => { setDefaultTaxCode(e); setDefaultRate(e) }, valueAsNumber: true, required: "Tax Type is required" })}>
              <option key="none" value="" hidden>Tax Type</option>
              {TAX_TYPES.map((tax, index) =>
                <option key={index} value={tax.value}>{tax.label}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.taxType && errors.taxType.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="defaultRate" className="form-label">Default Rate:</label>
            <Controller
              control={control}
              name='defaultRate'
              register={register}
              rules={{ valueAsNumber: true }}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <NumericFormat value={value} name={name} onChange={onChange} onBlur={onBlur} tabIndex={4} className='form-control' suffix="%" thousandsGroupStyle="thousand" thousandSeparator="," id="defaultRate" mask="_" />
              )}
            />
            <small className='form-error-message'>
              {errors?.defaultRate && errors.defaultRate.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="glCodeId" className="form-label">GL Code:</label>
            <select className="form-select" id="glCodeId" value={gLCode} tabIndex={5} {...register("glCodeId", { valueAsNumber: true, onChange: (e) => setGLCode(e.target.value), required: "GL Code is required" })}>
              <option key="none" value="" hidden>GL Code</option>
              {gLCodeList.map((gl, index) =>
                <option key={index} value={gl.glCodeId}>{gl.glCodeName} - {gl.description}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.glCodeId && errors.glCodeId.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="notes" className="form-label">Notes</label>
            <input type="text" className="form-control" id="notes" tabIndex={6}
              defaultValue={isFormEdit ? selectedItem?.notes || '' : ''}
              {...register("notes")} />
            <small className='form-error-message'>
              {errors?.notes && errors.notes.message}
            </small>
          </div>
          {
            (isSuperAdmin(userType) || isAccountAdmin(userType)) &&
            <div className="col-12 mb-2 mt-3">
              <div className="form-check">
                <input className="form-check-input align-middle" type="checkbox" value="" id="active" tabIndex={7}
                  defaultChecked={getActiveDefaultValue}
                  {...register("active")}
                />
                <label className="form-check-label align-middle " htmlFor="active"> Active</label>
                <small className='form-error-message'>
                  {errors?.active && errors.active.message}
                </small>
              </div>
            </div>
          }
        </form>
        <ModalRender modal={modal} handleSubmit={handleSubmit} onSubmit={onSubmit} setModal={setModal} toggle={toggle} isFormEdit={isFormEdit} formType={"expense-item"} toggleFormDisplay={toggleFormDisplay} />
      </div>
    </>
  )
}

export default ExpenseItemForm