import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Input, Button, Label, Toast, ToastHeader, ToastBody,  } from 'reactstrap';

function ChooseAccount() {

  const [accountList, setAccountList] = useState([])
  const [userTypeList, setUserTypeList] = useState([])
  const [account, setAccount] = useState();
  const [userType, setUserType] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [accountMessage, setAccountMessage] = useState("");
  const [userTypeMessage, setUserTypeMessage] = useState("");

  function handleToast() {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const fetchAccounts = async () => {
      const response = await fetch('Account/Dropdown', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Token ${token['mytoken']}`,
      },
      })

      const data = await response.json()
      setAccountList(data);
    }

    const fetchUserType = async () => {
      const response = await fetch('Account/userType', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Token ${token['mytoken']}`,
      },
      })

      const data = await response.json()
      setUserTypeList(data);
    }

    fetchAccounts()
    fetchUserType()
  },[])

  function onSubmit(event) {
    event.preventDefault()
    if(account === undefined || userType === '') {
      if(account === undefined) setAccountMessage("Please select an account")
      if(userType === '') setUserTypeMessage("Please select a user type")
      setIsOpen(true)
    } else {
      setAccountMessage("The account id has been set to: " + account)
      setUserTypeMessage("The role has been set to: " + userType)
      setIsOpen(true)
      sessionStorage.setItem('accountId', parseInt(account))
      sessionStorage.setItem('userType', parseInt(userType))
    }
  }

  // Auto closes the toast after 20 secconds
  useEffect(() => {
    setTimeout(() => setIsOpen(false), 20000)
  }, [isOpen])

  return (
    <>
      <Toast isOpen={isOpen}>
        <ToastHeader toggle={() => handleToast()}>
          Account Id
        </ToastHeader>
        <ToastBody>
          {accountMessage}<br/>
          {userTypeMessage}
        </ToastBody>
      </Toast>
      <Form onSubmit={(event) => onSubmit(event)}>
        <h3>Select an account and a user type (Development)</h3>
        <FormGroup>
          <Label for="exampleSelectMulti">Select an account</Label>
          
          <Input type="select" on name="selectMulti" onChange={(event) => setAccount(event.target.value)} id="exampleSelectMulti" multiple>
            {accountList.map((account, index) =>
              <option key={account.accountId} value={account.accountId}>{account.accountName} ({account.accountId})</option>
            )}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="exampleSelect">Select a role</Label>
          <Input type="select" name="select" id="exampleSelect" defaultValue="''" onChange={(event) => setUserType(event.target.value)}>
            <option key="none" value="''" disabled>Choose Role</option>
            {userTypeList.map((type, index) =>
              <option key={type.key} value={type.key}>{type.value} ({type.key})</option>
            )}
          </Input>
        </FormGroup>
        <Button >Submit</Button>

      </Form>
    </>
    
    

  );
}

export default ChooseAccount;