import { get, post, patch } from "./apiService";

export const fetchImageSettings = async (accountId) => {
  return get({ path: `ImageSettings` });
}

export const addImageSettings = async (payload) => {
  return post({ path: 'ImageSettings', body: payload });
}

export const updateImageSettings = async (payload) => {
  return patch({ path: `ImageSettings/update/${payload.imageSettingsId}`, body: payload });
}