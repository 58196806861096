import React from 'react'
import { formatDate, formatDateOnly, formatCurrency, formatTaxType } from '../../utils/formatUtils';
import { getInvoiceBalance } from '../../utils/paymentUtils';

function InvoiceDetails({ selectedInvoice }) {
  return (
    <div className='row invoice-details-container'>
      <div className="col-6 col-lg-4">
        <p className='mb-0 fw-bold'>Invoice ID</p>
        <p>{selectedInvoice?.invoiceId ?? '-'}</p>
      </div>
      <div className="col-6 col-lg-4">
        <p className='mb-0 fw-bold'>Invoice Name</p>
        <p>{selectedInvoice?.quoteName ?? '-'}</p>
      </div>
      <div className="col-6 col-lg-4">
        <p className='mb-0 fw-bold'>Customer</p>
        <p>{selectedInvoice?.customer?.customerName ?? '-'}</p>
      </div>
      <div className="col-6 col-lg-4">
        <p className='mb-0 fw-bold'>Invoice Date</p>
        <p>{selectedInvoice?.invoiceDate !== undefined ? formatDateOnly(selectedInvoice?.invoiceDate) : '-'}</p>
      </div>
      <div className="col-6 col-lg-4">
        <p className='mb-0 fw-bold'>Due Date</p>
        <p>{selectedInvoice?.paymentDueDate !== undefined ? formatDateOnly(selectedInvoice?.paymentDueDate) : '-'}</p>
      </div>
      <div className="col-6 col-lg-4">
        <p className='mb-0 fw-bold'>Tax Type</p>
        <p>{selectedInvoice?.taxType !== undefined ? formatTaxType(selectedInvoice?.taxType) : '-'}</p>
      </div>
      <div className="col-6 col-lg-4">
        <p className='mb-0 fw-bold'>Tax</p>
        <p>{selectedInvoice?.totalTax !== undefined ? formatCurrency(selectedInvoice?.totalTax) : '-'}</p>
      </div>
      <div className="col-6 col-lg-4">
        <p className='mb-0 fw-bold'>Discount</p>
        <p>{selectedInvoice?.discount !== undefined ? formatCurrency(selectedInvoice?.discount) : '-'}</p>
      </div>
      <div className="col-6 col-lg-4">
        <p className='mb-0 fw-bold'>SubTotal</p>
        <p>{selectedInvoice?.subtotal !== undefined ? formatCurrency(selectedInvoice?.subtotal) : '-'}</p>
      </div>
      <div className="col-6 col-lg-4">
        <p className='mb-0 fw-bold'>Total</p>
        <p>{selectedInvoice?.totalInvoice !== undefined ? formatCurrency(selectedInvoice?.totalInvoice) : '-'}</p>
      </div>
      <div className="col-6 col-lg-4">
        <p className='mb-0 fw-bold'>Balance</p>
        <p>{formatCurrency(getInvoiceBalance(selectedInvoice))}</p>
      </div>
    </div>
  )
}

export default InvoiceDetails