import React from 'react'

function StatusRenderer(props) {
  const status = props.data["Status"]

  let backgroundColor = '#334d6e';
  let color = "#ffffff"
  let label = ""
  let padding = 15;
  switch (status) {
    case 0:
      backgroundColor = "#ffd542";
      label = "Draft";
      padding = 27;
      break;
    case 1:
      backgroundColor = '#2fd47a'
      label = 'Approved';
      break;
    case 2:
      backgroundColor = "#f7685b"
      label = 'Archived';
      break;
    default:
      backgroundColor = "#ffd542";
      label = "Draft";
      padding = 27;
      break;
  }

  return (
    <div className='w-100' style={{ backgroundColor: backgroundColor, color: color, paddingLeft: padding, paddingRight: padding }}>
      <p className='mb-0 fw-bold'>{label.toUpperCase()}</p>
    </div>
  )
}

export default StatusRenderer