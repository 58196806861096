export function provinceCheck(province, rate, other = 0) {
    /*
        Tax Rates:
        None - 0
        GST - 1
        PST - 2
        HST - 3
        GSTPST - 4
        OTHER - 5
    */
    var data = [
        {
            //Alberta - 0
            0: 0,
            1: 0.05,
            2: 0,
            3: 0,
            4: 0.05,
            5: other
        },
        {
            //British Columbia - 1
            0: 0,
            1: 0.05,
            2: 0.07,
            3: 0,
            4: 0.12,
            5: other
        },
        {
            //Manitoba - 2
            0: 0,
            1: 0.05,
            2: 0.07,
            3: 0,
            4: 0.12,
            5: other
        },
        {
            //New Brunswick - 3
            0: 0,
            1: 0,
            2: 0,
            3: 0.15,
            4: 0.15,
            5: other
        },
        {
            //Newfoundland and Labrador - 4
            0: 0,
            1: 0,
            2: 0,
            3: 0.15,
            4: 0.15,
            5: other
        },
        {
            //Northwest Territories - 5
            0: 0,
            1: 0.05,
            2: 0,
            3: 0,
            4: 0.05,
            5: other
        },
        {
            //Nova Scotia - 6
            0: 0,
            1: 0,
            2: 0,
            3: 0.15,
            4: 0.15,
            5: other
        },
        {
            //Nunavut - 7
            0: 0,
            1: 0.05,
            2: 0,
            3: 0,
            4: 0.05,
            5: other
        }, 
        {
            //Ontario - 8
            0: 0,
            1: 0,
            2: 0,
            3: 0.13,
            4: 0.13,
            5: other
        },
        {
            //Prince Edward Island - 9
            0: 0,
            1: 0,
            2: 0,
            3: 0.15,
            4: 0.15,
            5: other
        },
        {
            // Quebec - 10
            0: 0.05,
            1: 0,
            2: 0.09975,
            3: 0,
            4: 0.14975,
            5: other
        },
        {
            // Saskatchewan - 11
            0: 0,
            1: 0.05,
            2: 0.06,
            3: 0,
            4: 0.11,
            5: other
        },
        {
            // Yukon - 12
            0: 0,
            1: 0.05,
            2: 0,
            3: 0,
            4: 0.05,
            5: other
        },
    ]

    return data[province][rate]
}

export function displayProvince(province) {
    var data =
        {
            0: "AB",
            1: "BC",
            2: "MB",
            3: "NB",
            4: "NL",
            5: "NT",
            6: "NS",
            7: "NU",
            8: "ON",
            9: "PE",
            10: "QC",
            11: "SK",
            12: "YT"
        }

    return data[province]
}