import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const ConfirmationModal = ({
  isOpen,
  toggle,
  onClick,
  headerText,
  bodyText,
  confirmButtonText,
  cancelButtonText,
  buttonColor,
  focusOnConfirm = false,
}) => {
  const confirmButtonRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (isOpen && focusOnConfirm) {

      
        confirmButtonRef?.current?.focus();
      }
    }, 100);
  }, [isOpen, confirmButtonRef.current]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{headerText}</ModalHeader>
      <ModalBody>{bodyText}</ModalBody>
      <ModalFooter>
        <Button color={buttonColor} onClick={onClick} innerRef={confirmButtonRef}>
          {confirmButtonText}
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>
          {cancelButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  )
};