import { combineReducers } from 'redux';
import accountReducer from './account/accountReducer';
import authReducer from './auth/authReducer';
import customerReducer from './customer/customerReducer';
import contactReducer from './contact/contactReducer';
import quoteReducer from './quote/quoteReducer';
import invoiceReducer from './invoice/invoiceReducer';
import payrollRunLineItemsReducer from './payrollRunLineItems/payrollRunLineItemsReducer';
import glCodeReducer from './glCode/glCodeReducer';
import settingsReducer from './settings/settingsReducer';
import reportTemplateReducer from './reportTemplate/reportTemplateReducer';

const rootReducers = combineReducers({
  auth: authReducer,
  account: accountReducer,
  customer: customerReducer,
  contact: contactReducer,
  quote: quoteReducer,
  invoice: invoiceReducer,
  payrollRunLineItems: payrollRunLineItemsReducer,
  glCode: glCodeReducer,
  settings: settingsReducer,
  reportTemplate: reportTemplateReducer,
});

export default rootReducers;
