import React, { useCallback, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Link } from 'react-router-dom'
import { deleteQuote } from '../../../services/quoteServices';
import { showToast } from '../../../utils/toastUtils';
import { isSuperAdmin } from '../../../utils/roleUtils';
import { useDispatch, useSelector } from 'react-redux';
import { deleteQuoteData } from '../../../redux/quote/quoteReducer';

function ActionRenderer(props, args) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const userType = useSelector((state) => state.auth.user.userType);
  const removeSelected = useCallback(async () => {
    const selectedNode = props.gridRef.current.api.getSelectedNodes();
    const quoteId = selectedNode[0].data["id"];

    const response = await deleteQuote(quoteId)
    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully deleted' : 'Failed to delete'} quote item.`
    });
    if (response.ok) {
      dispatch(deleteQuoteData(quoteId));
      setModal(false)
    }
  }, [props.rowData]);
  return (
    <div className='cell-renderer-content'>
      {/* Update to use link depending on what page it comes from. (Pass url and id as param) Url router is found in App.js */}
      <Link to="/quotes/edit-quote" state={{ quoteId: props.data.id }}>
        <button className={`btn btn-primary btn-sm`}><i className={`fa-solid ${props.data['Status'] !== 0 || (isSuperAdmin(userType)) ? 'fa-magnifying-glass' : 'fa-pen'}`}></i></button>
      </Link>
      {
        !(props.data['Status'] !== 0 || (isSuperAdmin(userType))) && <button onClick={toggle} className={`btn btn-primary btn-sm`}><i className="fa-solid fa-x"></i></button>
      }
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Delete Quote</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this item?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={removeSelected}>
            Yes
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ActionRenderer