import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const payrollRunLineItemsReducer = createSlice({
  name: "payrollRunLineItems",
  initialState,
  reducers: {
    setPayrollRunLineItemsData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setPayrollRunLineItem: (state, action) => {
      const index = state.data.findIndex(payrollRunLineItem => payrollRunLineItem.payrollRunLineItemId === action.payload.payrollRunLineItemId);
      const payrollRunLineItems = [...state.data];
      payrollRunLineItems[index] = action.payload;
      return {
        ...state,
        data: payrollRunLineItems
      }
    }
  },
});

export const { setPayrollRunLineItemsData, setPayrollRunLineItem } = payrollRunLineItemsReducer.actions;

export default payrollRunLineItemsReducer.reducer;