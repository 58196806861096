import { USER_TYPES } from "../constants";

export function isSuperAdmin(userType) {
  return userType === USER_TYPES.SUPER_ADMIN;
}

export function isABMUser(userType) {
  return userType === USER_TYPES.ABM_USER;
}

export function isAccountAdmin(userType) {
  return userType === USER_TYPES.ACCOUNT_ADMIN;
}

export function isAccountUser(userType) {
  return userType === USER_TYPES.ACCOUNT_USER;
}

export const getUserTypeList = (selectedAccountId, accountId, userType) => {

  const superAdminId = parseInt(process.env.REACT_APP_ACCOUNT_SUPER_ADMIN);
  const organizationId = parseInt(process.env.REACT_APP_ACCOUNT_ORGANIZATION);
  const superAdmin = [
    { key: 1, value: "Super Admin" },
  ];

  const abmUser = [
    { key: 2, value: "ABM User" },
  ]

  const accountAdmin = [
    { key: 3, value: "Account Admin" },
    { key: 4, value: "Account User" }
  ]

  var accountTypes = accountAdmin;

  if (isSuperAdmin(userType)) {
    if (accountId === superAdminId) {
      accountTypes = superAdmin;
    } else if (accountId === organizationId) {
      accountTypes = abmUser;
    } else {
      accountTypes = accountAdmin;
    }
  }

  if (isABMUser(userType)) {
    const abmAccountCheck = selectedAccountId === organizationId && selectedAccountId !== null ? abmUser : accountAdmin
    accountTypes = selectedAccountId === null ? abmUser : abmAccountCheck
  }

  return accountTypes;
}