import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function ActionRendererPayItem(props, args) {
  const [modal, setModal] = useState(false);
  const [body] = useState("edit");
  const toggle = () => setModal(!modal);
  const rowIndex = props.node.rowIndex;
  const removeSelected = () => {
    props.removeSelected();
    setModal(false);
  }

  return (
    <div className='cell-renderer-content'>
      {
        rowIndex !== 0 && 
        <button onClick={toggle} className="btn btn-primary btn-sm"><i className="fa-solid fa-x"></i></button>
      }
      <Modal isOpen={modal} size={`${body === "edit" ? 'lg' : ''}`} toggle={toggle} {...args}>
        <ModalHeader toggle={props.toggle}>Delete Payroll Run Line Item Pay Item</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this item?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={removeSelected}>
            Yes
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ActionRendererPayItem