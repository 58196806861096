import React from 'react'

function AuthorizationError(props) {
  return (
    <div className='d-flex justify-content-center align-items-center mt-5'>
      <div className='small-container shadow rounded'>
        <div className='title text-center mb-4'>
          Authorization Error
        </div>
        <p className='text-center'>
          You do not have the permission to access this page.
        </p>
      </div>
    </div>
  )
}

export default AuthorizationError