import _ from 'lodash';

export const getGLCodefromPaymentType = (paymentType, gLCodeList) => {
  var glCode = 0;
  if (paymentType == 1) {
    glCode = _.find(gLCodeList, (gLCode) => gLCode.glCodeName === "2025");

  }

  if (paymentType == 2) {
    glCode = _.find(gLCodeList, (gLCode) => gLCode.glCodeName === "1000");

  }

  if (paymentType == 3) {
    glCode = _.find(gLCodeList, (gLCode) => gLCode.glCodeName === "1050");
  }
  return glCode
}