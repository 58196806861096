
import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Layout from "./Layout";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "./ag-grid-custom-theme.css";
import './custom.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "react-tooltip/dist/react-tooltip.css";
import './scss/global.scss';
import "react-datepicker/dist/react-datepicker.css";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Auth0ProviderWithNavigate } from './providers/auth0-provider-with-navigate';
import Dashboard from './components/Dashboard/Dashboard';
import Quotes from './components/Quotes/Quotes';
import Customers from './components/Customers/Customers';
import Contacts from './components/Contacts/Contacts';
import Accounts from './components/Accounts/Accounts';
import ExpenseCategories from './components/ExpenseCategories/ExpenseCategories'
import EditQuotePage from './components/Quotes/EditQuotePage';
import ExpenseItemForm from './components/ExpenseCategories/ExpenseItemForm';
import GLCodes from './components/GLCodes/GLCodes';
import AccountingSettings from "./components/AccountingSettings/AccountingSettings";
import ChooseAccount from './components/Development/ChooseAccount';
import Settings from './components/Settings/Settings';
import Login from './components/Login/Login';
import Invoices from './components/Invoices/Invoices';
import EditInvoicePage from './components/Invoices/EditInvoicePage'
import Employees from './components/Employees/Employees';
import PayrollRuns from './components/PayrollRuns/PayrollRuns';
import EditPayrollPage from './components/PayrollRuns/EditPayrollPage'
import Expenses from './components/Expenses/Expenses';
import Payments from './components/Payments/Payments';
import LoginSuccess from "./components/LoginSuccess/LoginSuccess";
import ReportTemplates from './components/ReportTemplates/ReportTemplates';
import { store, persistor } from './redux/store';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import PayrollSettings from "./components/PayrollSettings/PayrollSettings";
import ClaimCodesSheet from "./components/ClaimCodesSheet/ClaimCodesSheet";
import ExpensePayments from "./components/ExpensePayments/ExpensePayments";
import ImageSettings from "./components/ImageSettings/ImageSettings";
import AccountSettings from "./components/AccountSettings/AccountSettings";
import ExpensesQuickEntry from "./components/ExpensesQuickEntry/Expenses";

export default function App() {
  const AccountGuard = () => {
    let isAuthenticated = false;
    const rawStorage = localStorage.getItem('persist:root');
    if (rawStorage) {
      const localStorage = JSON.parse(rawStorage);
      const authStorage = JSON.parse(localStorage.auth);
      isAuthenticated = authStorage?.isAuthenticated;
    }

    return (
      isAuthenticated ? <Outlet /> : <Navigate to="/login" />
    )
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Auth0ProviderWithNavigate>
            <Layout>
              <Routes>
                <Route element={<AccountGuard />}>
                  <Route path='/dashboard' element={<Dashboard />} />
                  <Route path='/accounts' element={<Accounts />} />
                  <Route path='/customers' element={<Customers />} />
                  <Route path='/contacts' element={<Contacts />} />
                  <Route path='/quotes' element={<Quotes />} />
                  <Route path='/quotes/edit-quote' element={<EditQuotePage />} />
                  <Route path='/invoices' element={<Invoices />} />
                  <Route path='/invoices/edit-invoice' element={<EditInvoicePage />} />
                  <Route path='/expense-item-form' element={<ExpenseItemForm />} />
                  <Route path='/employees' element={<Employees />} />
                  <Route path='/payroll-runs' element={<PayrollRuns />} />
                  <Route path='/edit-payroll-run' element={<EditPayrollPage />} />

                  <Route path='/payments' element={<Payments />} />
                  <Route path='/expense-payments' element={<ExpensePayments />} />

                  <Route path="/expenses">
                    <Route path='/expenses/default' element={<Expenses />} />
                    <Route path='/expenses/quick-entry' element={<ExpensesQuickEntry />} />
                  </Route>

                  <Route path="/settings">
                    <Route index element={<Settings />} />
                    <Route path="/settings/gl-codes" element={<GLCodes />} />
                    <Route path='/settings/report-templates' element={<ReportTemplates />} />
                    <Route path='/settings/accounting-settings' element={<AccountingSettings />} />
                    <Route path='/settings/payroll-settings' element={<PayrollSettings />} />
                    <Route path='/settings/expense-categories' element={<ExpenseCategories />} />
                    <Route path='/settings/image-settings' element={<ImageSettings />} />
                    <Route path='/settings/account-settings' element={<AccountSettings />} />
                  </Route>
                </Route>
                <Route exact path='/' element={<Login />} />
                <Route path='/login' element={<Login />} />
                <Route path='/login-success' element={<LoginSuccess />} />
                <Route path='/dev-choose-account' element={<ChooseAccount />} />
                <Route path='/claim-codes' element={<ClaimCodesSheet />} />
              </Routes>
            </Layout>
          </Auth0ProviderWithNavigate>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement)
root.render(<App />);

