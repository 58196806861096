import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import _ from 'lodash';
import { showToast } from '../../utils/toastUtils';
import { isABMUser } from '../../utils/roleUtils';
import { useDispatch, useSelector } from 'react-redux';
import { addAccountSettings, updateAccountSettings } from '../../services/accountSettingsServices';
import { setAccountSettingsData } from '../../redux/settings/settingsReducer';

function AccountSettingsForm({
  isFormEdit = false,
  setIsFormEdit,
  isFormHidden
}) {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, clearErrors, reset, setFocus, control, formState: { errors } } = useForm();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const settingsState = useSelector((state) => state.settings);
  const [accountSettings, setAccountSettings] = useState();
  const userType = useSelector((state) => state.auth.user.userType);
  const accountState = useSelector((state) => state.account.selectedAccount);

  const [defaultEmail, setDefaultEmail] = useState("");
  const [payrollNotificationEmail, setPayrollNotificationEmail] = useState("");

  useEffect(() => {
    if (accountSettings !== undefined) {
      setValue("defaultEmail", accountSettings.defaultEmail);
      setValue("payrollNotificationEmail", accountSettings.payrollNotificationEmail);
    }
  }, [accountSettings])

  // useEffects
  useEffect(() => {
    clearErrors();
    setFocus("defaultEmail");
  }, []);

  useEffect(() => {
    clearErrors();
    reset();
  }, [isFormHidden]);

  useEffect(() => {
    setAccountSettings(settingsState.accountSettings);
    if (!_.isEmpty(settingsState.accountSettings)) {
      setIsFormEdit(true);
    }
  }, [settingsState.accountSettings]);

  const onSubmit = async (payload) => {
    let response = null;

    if (isFormEdit) {
      _.set(payload, 'accountSettingsId', accountSettings.accountSettingsId);
      _.set(payload, 'accountId', accountSettings.accountId);
      response = await updateAccountSettings(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully updated' : 'Failed to update'} default account settings.`
      });
    } else {
      _.set(payload, 'accountSettingsId', 0);
      if(isABMUser(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }
      response = await addAccountSettings(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully added' : 'Failed to add'} default account settings.`
      });
    }
    if (response.ok) {
      dispatch(setAccountSettingsData(await response.json()));
      setIsFormEdit(true);
      setFocus("tD1Province");
    }
  }
  
  return (
    <>
      <div className='content-section-container'>
        <div className='d-flex flex-row align-items-center mb-5'>
          <div className='form-title me-auto'>

          </div>
          <button className="btn btn-primary" form='customer-form' type="submit" tabIndex={3}>Save</button>
        </div>
        <form className="row form-container" id="customer-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="defaultEmail" className="form-label">Default Email:</label>
            <input type="text" className="form-control" id="defaultEmail" tabIndex={1}
              defaultValue={isFormEdit ? accountSettings?.defaultEmail || '' : ''}
              autoComplete='on'
              {...register("defaultEmail", { required: "email is required" })}
            />
            <small className='form-error-message'>
              {errors?.defaultEmail && errors.defaultEmail.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="payrollNotificationEmail" className="form-label">Payroll Notification Email:</label>
            <input type="text" className="form-control" id="payrollNotificationEmail" tabIndex={2}
              defaultValue={isFormEdit ? accountSettings?.payrollNotificationEmail || '' : ''}
              autoComplete='on'
              {...register("payrollNotificationEmail", { required: "email is required" })}
            />
            <small className='form-error-message'>
              {errors?.payrollNotificationEmail && errors.payrollNotificationEmail.message}
            </small>
          </div>
        </form>
      </div>
    </>
  )
}

export default AccountSettingsForm
