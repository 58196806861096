import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { addGLCode, updateGLCode } from '../../services/glCodeServices';
import { showToast } from '../../utils/toastUtils';
import { isAccountAdmin, isSuperAdmin, isABMUser } from '../../utils/roleUtils';
import { fetchAccountType } from '../../services/accountTypeServices';
import ModalRender from '../Commons/ModalRender';
import { useDispatch, useSelector } from 'react-redux';
import { CURRENCY_TYPE, TAX_TYPES } from '../../constants';
import { addGLCodeData, setGLCodeData } from '../../redux/glCode/glCodeReducer';

function GLCodeForm({
  isFormEdit = false,
  selectedGLCode = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton
}) {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, clearErrors, reset, setFocus, formState: { errors, isDirty } } = useForm();
  const userType = useSelector((state) => state.auth.user.userType);
  const [accountType, setAccountType] = useState("")
  const [currencyType, setCurrencyType] = useState("")
  const [defaultTaxCode, setDefaultTaxCode] = useState("")
  const accountId = useSelector((state) => state.auth.user.accountId);
  const accountState = useSelector((state) => state.account.selectedAccount);
  
  useEffect(() => {
    if (isFormEdit) {
      setAccountType(parseInt(selectedGLCode['accountTypeId']))
      setCurrencyType(parseInt(selectedGLCode['currencyType']))
      setDefaultTaxCode(parseInt(selectedGLCode['defaultTaxCode']))
      setValue("accountTypeId", parseInt(selectedGLCode['accountTypeId']))
      setValue("currencyType", parseInt(selectedGLCode['currencyType']))
      setValue("defaultTaxCode", parseInt(selectedGLCode['defaultTaxCode']))
    } else {
      setAccountType("")
      setCurrencyType("")
      setDefaultTaxCode("")
      reset()
    }

  }, [selectedGLCode["glCodeId"]])

  // form values
  setValue('glCodeId', 0);
  // setValue('accountId', accountId);

  // useStates
  const [accountTypeList, setAccountTypeList] = useState([])

  // useEffects
  useEffect(() => {
    clearErrors();
    fillAccountType();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();
    if(!isFormHidden){
      setTimeout(() => {
        setFocus("glCodeName");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    setIsFormDirty(isDirty)
  }, [isDirty])

  const fillAccountType = async () => {
    const response = await fetchAccountType();
    setAccountTypeList(response);
  }

  const onSubmit = async (payload) => {
    let response = null;
    if (isFormEdit) {
      _.set(payload, 'glCodeId', selectedGLCode.glCodeId);
      _.set(payload, 'accountId', accountId);
      response = await updateGLCode(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully updated' : 'Failed to update'} GL code.`
      });
    } else {
      _.set(payload, 'glCodeId', 0);
      if(isABMUser(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }
      response = await addGLCode(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully added' : 'Failed to add'} GL code.`
      });
    }
    
    if (response.ok) {
      const glCodeData = await response.json();
      dispatch(isFormEdit ? setGLCodeData(glCodeData) : addGLCodeData(glCodeData));

      toggleFormDisplay();

      if (isDirty) {
        setModal(false);
      }
      setIsFormDirty(false);
      reset();
      focusOnOpenOrCloseButton();
    }
  }

  return (
    <>
      <div className='content-section-container'>
        <div className='d-flex flex-row align-items-center mb-5'>
          <div className='form-title me-auto'>
            {`${isFormEdit ? 'Update' : 'Add'} GL Code`}
          </div>
          <button className="btn btn-primary" form='gl-code-form' type="submit" tabIndex={7}>Save</button>
        </div>
        <form className="row form-container" id="gl-code-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="name" className="form-label">Name</label>
            <input type="text" className="form-control" id="name" tabIndex={1}
              defaultValue={isFormEdit ? selectedGLCode?.glCodeName || '' : ''}
              autoComplete='on'
              {...register("glCodeName", { required: "Name is required" })}
            />
            <small className='form-error-message'>
              {errors?.glCodeName && errors.glCodeName.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="description" className="form-label">Description</label>
            <input type="text" className="form-control" id="description" tabIndex={2}
              defaultValue={isFormEdit ? selectedGLCode?.description || '' : ''}
              {...register("description", { required: "Name is required" })}
            />
            <small className='form-error-message'>
              {errors?.description && errors.description.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="accountType" className="form-label" >Account Type:</label>
            <select className="form-select" value={accountType} tabIndex={3} id="accountType"{...register("accountTypeId", { valueAsNumber: true, onChange: (e) => setAccountType(e.target.value), required: "Account type is required" })}>
              <option key="none" value="" hidden>Account Type</option>
              {accountTypeList.map((accountType, index) =>
                <option key={index} value={accountType.accountTypeId}>{accountType.accountTypeName} - {accountType.description}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.accountType && errors.accountType.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="currencyType" className="form-label" >Currency Type:</label>
            <select className="form-select" value={currencyType} tabIndex={4} id="currencyType"{...register("currencyType", { valueAsNumber: true, onChange: (e) => setCurrencyType(e.target.value), required: "Currency type is required" })}>
              <option key="none" value="" hidden>Currency Type</option>
              {CURRENCY_TYPE.map((currencyType, index) =>
                <option key={index} value={currencyType.value}>{currencyType.label}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.currencyType && errors.currencyType.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="defaultTaxCode" className="form-label" >Default Tax Code:</label>
            <select className="form-select" value={defaultTaxCode} tabIndex={5} id="defaultTaxCode"{...register("defaultTaxCode", { valueAsNumber: true, onChange: (e) => setDefaultTaxCode(e.target.value), required: "Default Tax Code is required" })}>
              <option key="none" value="" hidden>Default Tax Code</option>
              {TAX_TYPES.map((taxCode, index) =>
                <option key={index} value={taxCode.value}>{taxCode.label}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.defaultTaxCode && errors.defaultTaxCode.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="notes" className="form-label">Notes</label>
            <input type="text" className="form-control" id="notes" tabIndex={6}
              defaultValue={isFormEdit ? selectedGLCode?.notes || '' : ''}
              {...register("notes")}
            />
            <small className='form-error-message'>
              {errors?.notes && errors.notes.message}
            </small>
          </div>
        </form>
        <ModalRender modal={modal} handleSubmit={handleSubmit} onSubmit={onSubmit} setModal={setModal} toggle={toggle} isFormEdit={isFormEdit} formType={"gl-code"} toggleFormDisplay={toggleFormDisplay}/>
      </div>
    </>
  )
}

export default GLCodeForm
