import React, { useState, useRef, useEffect, memo, forwardRef, useImperativeHandle } from 'react';
import { Input } from 'reactstrap';
import { PAY_METHOD_TYPE } from '../../../constants';

export default memo(forwardRef((props, ref) => {

  const refInput = useRef(null);
  const [value, setValue] = useState();

  useEffect(() => {
    // focus on the input
    setTimeout(() => refInput.current.focus());
  }, []);

  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        if (value === undefined) {
          return parseInt(props.data["Pay Method Type"])
        }
        return parseInt(value);
      },
    };
  });

  return (
    <div className="cell-editor">
      <Input
        id="payMethodType"
        name="select"
        type="select"
        defaultValue={props.value}
        innerRef={refInput}
        className="cell-editor-input"
        onChange={(event) => setValue(event.target.value)}
      >
        <option key="none" value="" hidden>Pay Method Type</option>
        {PAY_METHOD_TYPE.map((method, index) =>
          <option key={index} value={method.value}>{method.label}</option>
        )}
      </Input>
    </div>

  )
}));
