import React from 'react';
import { Link } from 'react-router-dom';

function ActionRenderer(props, args) {

  return (
    <div className='cell-renderer-content'>
      <Link to="/invoices/edit-invoice" state={{ invoiceId: props.data.id }}>
        <button className={`btn btn-primary btn-sm`}><i className="fa-solid fa-magnifying-glass"></i></button>
      </Link>
    </div>
  )
}

export default ActionRenderer