import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import ContentHeader from '../Commons/Layouts/ContentHeader';
import EditPayrollRunRenderer from '../Templates/CellRenderers/PayrollRuns/EditPayrollRunRenderer';
import { fetchPayrollRunsFiltered } from '../../services/payrollRunServices';
import { formatDate, formatDateOnly } from '../../utils/formatUtils';
import _ from 'lodash';
import StatusRenderer from '../Templates/CellRenderers/PayrollRuns/StatusRenderer';
import TypeRenderer from '../Templates/CellRenderers/PayrollRuns/TypeRenderer';
import { isABMUser, isAccountUser } from '../../utils/roleUtils';
import { useSelector } from 'react-redux';
import PayrollRunForm from './PayrollRunForm';
import { Tooltip as ReactTooltip } from "react-tooltip";
import AuthorizationError from '../Commons/Authorization/AuthorizationError';
import { fetchPayrollSettingsCreation } from '../../services/payrollSettingsServices';
import LoadingPage from '../Commons/Authorization/LoadingPage';
import SetupSettings from '../Commons/Authorization/SetupSettings';
import { fetchAccountingSettings } from '../../services/accountingSettingsServices';
import { fetchVoidLastPayrollRun } from '../../services/payrollRunServices';
import { showToast } from '../../utils/toastUtils';
import { ConfirmationModal } from '../Commons/Modals/ConfirmationModal';
import OneOffPayrollRunForm from './OneOffPayrollRunForm';
import { fetchUsersDropdown } from '../../services/userServices';
import { PAYROLL_RUN_TYPES } from '../../constants';
import moment from 'moment';

function PayrollRuns() {
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const [rowData, setRowData] = useState([]);
  const [disabledAddButton, setDisabledAddButton] = useState(true);
  const [disabledVoidButton, setDisabledVoidButton] = useState(true);
  const [voidLastPayrollRunModal, setVoidLastPayrollRunModal] = useState(false);
  const [payrollType, setPayrollType] = useState(1);
  const voidLastPayrollRunToggle = () => setVoidLastPayrollRunModal(!voidLastPayrollRunModal);
  const [userList, setUserList] = useState([]);
  const [oneOffMinDate, setOneOffMinDate] = useState([]);

  // useRefs
  const gridRef = useRef();


  const toggleFormDisplay = (isEdit = false, contactId = null, payrollType = 1) => {
    if (modal) {
      setModal(!modal)
    }
    setPayrollType(payrollType);
    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);

    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 50);
  }

  const toggleFormCancelDisplay = (isEdit = false, accountId = null, payrollType = 1) => {
    if (isFormDirty) {
      toggle()
      return
    }
    toggleFormDisplay(isEdit, accountId, payrollType)
  }

  const fillPayrollRunList = async () => {
    let response = null;
    let hasDraft = false;
    if (!isAccountUser(userType)) {
      if (isABMUser(userType)) {
        response = selectedAccountState.accountId === null ? await fetchPayrollRunsFiltered(accountId) : await fetchPayrollRunsFiltered(selectedAccountState.accountId);
      }
      else {
        response = await fetchPayrollRunsFiltered(accountId);

      }

      response.map((item, index) => {
        if (item.statusRun === false && item.payrollRunType === 1) {
          hasDraft = true;
        }
        return hasDraft
      })
    }

    const payrollRunCount = await response.length;

    setDisabledAddButton(hasDraft);
    setDisabledVoidButton(!(!hasDraft && payrollRunCount > 0));
    setPayrollRunList(response);

    return response;
  }

  const defaultColumnDefs = [
    {
      field: 'index',
      headerName: "#",
      width: 50,
      minWidth: 50,
      suppressSizeToFit: true,
      cellClass: 'no-borders',
      cellStyle: { fontWeight: 'bold' }
    },
    {
      field: 'Start Date',
      minWidth: 150,
      cellClass: 'no-borders'
    },
    {
      field: 'End Date',
      minWidth: 150,
      cellClass: 'no-borders'
    },
    {
      field: 'Run Date',
      minWidth: 150,
      cellClass: 'no-borders'
    },
    {
      field: 'Created',
      minWidth: 150,
      cellClass: 'no-borders'
    },
    // TODO: Unhide for Payroll run types
    // {
    //   field: 'Type',
    //   minWidth: 150,
    //   cellRenderer: TypeRenderer,
    //   cellClass: 'no-borders'
    // },
    {
      field: 'Status',
      minWidth: 150,
      cellRenderer: StatusRenderer,
      cellClass: 'no-borders'
    },
    {
      field: 'Edit',
      minWidth: 150,
      headerName: '',
      cellClass: 'd-flex flex-row-reverse',
      autoHeight: true,
      cellRenderer: EditPayrollRunRenderer,
      cellRendererParams: {
        gridRef,
        rowData,
        setRowData,
        toggleFormDisplay,
        fillPayrollRunList,
      }
    },
  ]

  const columnDefsWithRow = [
    {
      field: 'index',
      headerName: "#",
      width: 50,
      minWidth: 50,
      suppressSizeToFit: true,
    },
    {
      field: 'Start Date',
      minWidth: 150,
      suppressSizeToFit: true
    },
    {
      field: 'End Date',
      minWidth: 110,
      suppressSizeToFit: true,
    },
    {
      field: 'Created',
      minWidth: 110,
      suppressSizeToFit: true,
    },
  ];

  // useStates
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [payrollRunList, setPayrollRunList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [accountingSettings, setAccountingSettings] = useState();
  const [payrollSettings, setPayrollSettings] = useState();
  const [isFormDirty, setIsFormDirty] = useState(null)
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toggle = () => setModal(!modal);


  // useEffects
  useEffect(() => {
    const fetchSettings = async () => {
      await fillAccountingAndPayrollSettings();
    };

    fetchSettings();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        await fillPayrollRunList();
        await fetchUserList();
      }
    };

    fetchData();
  }, [isLoading])

  const openOrCloseButtonRef = useRef(null);

  useEffect(() => {
    setColumnDefs(isFormHidden ? defaultColumnDefs : columnDefsWithRow);
  }, [isFormHidden]);

  useEffect(() => {
    let data = [];
    var minDate = fillOneOffMinDate()
    _.each(payrollRunList, (payrollRun, index) => {
      data.push({
        id: payrollRun.payrollRunId,
        'index': index + 1,
        'Start Date': formatDateOnly(payrollRun.startDate),
        'End Date': formatDateOnly(payrollRun.endDate),
        'Run Date': formatDateOnly(payrollRun.payrollRunDate),
        'Type': payrollRun.payrollRunType,
        'Status': payrollRun.statusRun,
        'Created': formatDate(payrollRun.createdTimeStamp),
        'minDate': minDate,
        // 'Edit': { id: contact.contactId }
      });
    });

    setRowData(data);
  }, [payrollRunList])

  useEffect(() => {
    if (!isLoading && (!_.isEmpty(payrollSettings) || !_.isEmpty(accountingSettings))) {
      setTimeout(() => {
        focusOnOpenOrCloseButton();
      }, 150);

    }
  }, [isLoading])

  const fillOneOffMinDate = () => {
    const payrollSettingsCheck = payrollSettings !== null && payrollSettings !== undefined && !_.isEmpty(payrollSettings);
    const payrollRunListCheck = payrollRunList !== null && payrollRunList !== undefined && payrollRunList.length > 0;
    var minDate;
    if (payrollRunListCheck) {
      var periodicalPayrollRuns = payrollRunList.filter((payrollRun) => { return payrollRun.payrollRunType === 1 && payrollRun.statusRun });
      if (periodicalPayrollRuns.length > 0) {
        const lastPayroll = periodicalPayrollRuns.reduce((prev, current) => {
          return (prev.endDate > current.endDate) ? prev : current
        });
        minDate = moment(lastPayroll.endDate).add(1, 'days').startOf('day').hour(7).minute(0).toDate();
      } else {
        minDate = moment(payrollSettings.payrollStartDate).startOf('day').hour(7).minute(0).toDate()
      }


      setOneOffMinDate(minDate)
    }
    else if (payrollSettingsCheck) {

      minDate = moment(payrollSettings.payrollStartDate).startOf('day').hour(7).minute(0).toDate()
      setOneOffMinDate(minDate);
    }

    return minDate;
  }

  const fetchUserList = async () => {
    var response = await fetchUsersDropdown();
    setUserList(response);
  }

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const fillAccountingAndPayrollSettings = async () => {
    if (isABMUser(userType)) {
      const response = selectedAccountState.accountId === null ? await fetchAccountingSettings(accountId) : await fetchAccountingSettings(selectedAccountState.accountId);
      setAccountingSettings(response)

      const secondResponse = selectedAccountState.accountId === null ? await fetchPayrollSettingsCreation(accountId) : await fetchPayrollSettingsCreation(selectedAccountState.accountId);
      setPayrollSettings(secondResponse)
    }
    else {
      const response = await fetchAccountingSettings(accountId)
      setAccountingSettings(response)

      const secondResponse = await fetchPayrollSettingsCreation(accountId)
      setPayrollSettings(secondResponse)
    }

    setIsLoading(false);
  }

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  }

  if (_.isEmpty(payrollSettings) || _.isEmpty(accountingSettings)) {
    return isLoading ? <LoadingPage /> : isAccountUser(userType) ? <AuthorizationError /> : <SetupSettings payrollSettingsIsEmpty={_.isEmpty(payrollSettings)} accountingSettingsIsEmpty={_.isEmpty(accountingSettings)} />
  }

  const focusOnOpenOrCloseButton = () => {
    openOrCloseButtonRef.current.focus();
  }

  const voidLastPayrollRun = async () => {
    var response = null;

    if (isABMUser(userType)) {
      response = selectedAccountState.accountId === null ? await fetchVoidLastPayrollRun({}, accountId) : await fetchVoidLastPayrollRun({}, selectedAccountState.accountId);
    }
    else {
      response = await fetchVoidLastPayrollRun({});
    }
    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully voided' : 'Failed to void'} last payroll run.`
    });
    var newData = await fillPayrollRunList();
    voidLastPayrollRunToggle()
  }

  return (
    <div>
      {
        isAccountUser(userType) &&
        (
          <AuthorizationError />
        )
      }
      {
        !isAccountUser(userType) &&
        (
          <>
            <div className='content-header-container d-flex flex-row align-items-center'>
              <div className='me-auto'>
                <ContentHeader title="Payroll" dataCount={payrollRunList.length} filterData={filterData} />
              </div>
              <button
                className='btn btn-lg btn-danger me-2'
                onClick={voidLastPayrollRunToggle}
                disabled={disabledVoidButton}
              >
                Void Last Payroll Run
              </button>
              <div id={'add-btn-container'}>

                <button
                  className={`btn btn-lg ${isFormHidden ? 'btn-primary' : 'btn-secondary'}`}
                  type="submit"
                  onClick={() => toggleFormCancelDisplay(false, null, 1)}
                  ref={openOrCloseButtonRef}
                  disabled={disabledAddButton}
                >
                  {isFormHidden ? 'Add Payroll Run' : 'Close'}
                </button>

                {/* TODO: Unhide for Payroll run types */}
                {/* <div className="btn-group">
                  <button
                    type="button"
                    onClick={() => toggleFormCancelDisplay(false, null, 1)}
                    className={`btn btn-lg ${isFormHidden ? 'btn-primary' : 'btn-secondary'}`}
                    ref={openOrCloseButtonRef}
                    disabled={disabledAddButton && isFormHidden}
                  >
                    {isFormHidden ? 'Add Payroll Run' : 'Close'}
                  </button>
                  <button
                    type="button"
                    className={`btn btn-split ${isFormHidden ? 'btn-primary' : 'btn-secondary'} dropdown-toggle dropdown-toggle-split`}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    disabled={!isFormHidden}
                  >
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul className="dropdown-menu">
                    {!disabledAddButton &&
                      <li>
                        <a
                          className={`dropdown-item ${disabledAddButton ? 'disabled' : ''}`}
                          href="#"

                          onClick={(e) => {
                            e.stopPropagation()
                            toggleFormCancelDisplay(false, null, 1);
                            document.querySelector('.dropdown-toggle-split').click();
                          }}
                        >
                          Add Payroll Run
                        </a>
                      </li>
                    }
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                          e.stopPropagation()
                          toggleFormCancelDisplay(false, null, 2);
                          document.querySelector('.dropdown-toggle-split').click();
                        }}
                      >
                        Add One-Off Payroll Run
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>

              {disabledAddButton &&
                <ReactTooltip
                  anchorId="add-btn-container"
                  className='tooltip-container'
                  place="top"
                  content="There can only be one drafted payroll run at a time"
                />
              }

            </div>
            <div className="content-body-container row">
              <div className={`${isFormHidden ? 'col-12' : 'col-12 col-lg-5 min-vh-25'} mb-2`}>
                <div className={`ag-theme-alpine content-section-container`} >
                  <AgGridReact
                    className='no-header'
                    rowData={rowData}
                    columnDefs={columnDefs}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    onColumnSizeChanged={onColumnsSizeChanged}
                    onGridSizeChanged={onGridSizeChanged}
                    rowHeight={70}
                    rowSelection={'single'}
                  >
                  </AgGridReact>
                </div>
              </div>
              <div className={`col-12 col-lg-7 ${isFormHidden ? 'd-none' : ''}`}>
                {
                  payrollType === 1 ?
                    <PayrollRunForm
                      isFormEdit={isFormEdit}
                      isFormHidden={isFormHidden}
                      toggleFormDisplay={toggleFormDisplay}
                      setIsFormDirty={setIsFormDirty}
                      modal={modal}
                      setModal={setModal}
                      toggle={toggle}
                      payrollRunList={payrollRunList}
                      payrollSettings={payrollSettings}
                    />
                    :
                    <OneOffPayrollRunForm
                      isFormEdit={isFormEdit}
                      isFormHidden={isFormHidden}
                      toggleFormDisplay={toggleFormDisplay}
                      setIsFormDirty={setIsFormDirty}
                      modal={modal}
                      setModal={setModal}
                      toggle={toggle}
                      payrollRunList={payrollRunList}
                      payrollSettings={payrollSettings}
                      userList={userList}
                      oneOffMinDate={oneOffMinDate}
                    />
                }

              </div>
            </div>
            <ConfirmationModal
              isOpen={voidLastPayrollRunModal}
              toggle={voidLastPayrollRunToggle}
              onClick={voidLastPayrollRun}
              headerText="Void Last Payroll Run"
              bodyText="Are you sure you want to void the last payroll run?"
              confirmButtonText="Yes"
              cancelButtonText="No"
              buttonColor="danger"
            />
          </>
        )
      }
    </div >
  )
}

export default PayrollRuns
