import { get, post, patch } from "./apiService";

export const fetchInvoices = async () => {
  return get({ path: 'Invoice' });
}

export const fetchInvoicesFiltered = async (accountId) => {
return get({ path: `Invoice/filtered` });
}

export const fetchInvoiceData = async (state) => {
  return get({ path: `Invoice/${state.invoiceId}` });
}

export const fetchTableData = async (state) => {
  return get({ path: `Invoice/invoiceItems/invoice/${state.invoiceId}` });
}

export const createInvoice = async (payload) => {
  return post({ path: 'Invoice/createInvoice', body: payload });
}

export const updateInvoice = async (payload) => {
  return patch({ path: `Invoice/${payload.invoiceId}`, body: payload });
}

export const voidInvoice = async (invoiceId) => {
  return post({ path: `Invoice/void/${invoiceId}` });
}