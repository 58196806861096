import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import _ from 'lodash';
import { showToast } from '../../utils/toastUtils';
import { isSuperAdmin, isABMUser, isAccountAdmin } from '../../utils/roleUtils';
import ModalRender from '../Commons/ModalRender';
import { addQuote, updateQuote } from '../../services/quoteServices';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { addQuoteData, setQuoteData } from '../../redux/quote/quoteReducer';
import ReactDatePicker from "react-datepicker";
import moment from 'moment';
import { CUSTOMER_TYPES } from '../../constants';

function QuoteForm({
  isFormEdit = false,
  selectedQuote = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton,
  customerFilter,
  setCustomerFilter
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    reset,
    control,
    setFocus,
    formState: { errors, isDirty }
  } = useForm();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const customerState = useSelector((state) => state.customer);
  const [customerId, setCustomerId] = useState(selectedQuote['customerId'])
  const accountState = useSelector((state) => state.account.selectedAccount);

  useEffect(() => {
    if (isFormEdit) {
      setCustomerId(parseInt(selectedQuote['customerId']));
      setValue("customerId", parseInt(selectedQuote['customerId']));
      setValue("active", selectedQuote['active']);
    } else {
      setCustomerId("");
      reset();
    }
  }, [selectedQuote["quoteId"]])

  // Form Values
  setValue('accountId', accountId);

  // useStates
  const [customerList, setCustomerList] = useState([]);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    const customerType = CUSTOMER_TYPES.find(type => type.label === "Customer").value;
    let filteredCustomerList;

    if(customerFilter) {
      filteredCustomerList = customerState.data.filter(customer => customer.customerType === 2 || customer.customerType === customerType || customer.customerId === getValues?.('customerId'));
    } else {
      filteredCustomerList = customerState.data.filter(customer => customer.customerType === customerType || customer.customerId === getValues?.('customerId'));
    }

    setCustomerList(filteredCustomerList);
  }, [customerState.data, customerFilter]);


  useEffect(() => {
    setValue("customerId", customerId);
  }, [customerList])

  useEffect(() => {
    clearErrors();
    reset();
    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("customerId");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    setIsFormDirty(isDirty)
  }, [isDirty])

  const getActiveDefaultValue = () => {
    // if (selectedQuote.length <= 0) {
    //   return 'true';
    // }

    // return selectedQuote.active ? 'true' : '';
  }

  const onSubmit = async (payload) => {
    let response = null;

    var quoteDate = moment(payload.quoteDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    var paymentDueDate = moment(payload.paymentDueDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');

    _.set(payload, 'quoteDate', quoteDate);
    _.set(payload, 'paymentDueDate', paymentDueDate);

    if (isFormEdit) {
      _.set(payload, 'quoteId', selectedQuote.quoteId);
      _.set(payload, 'accountId', selectedQuote.accountId);

      // response = await updateQuote(payload);
      // showToast({
      //   type: `${response.ok ? 'success' : 'error'}`,
      //   message: `${response.ok ? 'Successfully updated' : 'Failed to update'} quote.`
      // });
    } else {
      _.set(payload, 'quoteId', 0);
      if (isABMUser(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }

      response = await addQuote(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully added' : 'Failed to add'} quote.`
      });
    }

    if (response.ok && !isFormEdit) {
      const quoteData = await response.json();
      dispatch(isFormEdit ? setQuoteData(quoteData) : addQuoteData(quoteData));
      navigate("/quotes/edit-quote", { state: { quoteId: quoteData.quoteId } });
      return;
    }

    toggleFormDisplay();
  };

  return (
    <div className='content-section-container'>
      <div className='d-flex flex-row align-items-center mb-3'>
        <div className='form-title me-auto'>
          {`${isFormEdit ? 'Update' : 'Add'} Quote`}
        </div>
        <button className="btn btn-primary" form='quote-form' type="submit">{isFormEdit ? 'Update' : 'Save'}</button>
      </div>
      <form className="row form-container" id="quote-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-6 mb-2">
          <label htmlFor="customerId" className="form-label">Customer</label>
          <select className="form-select" id="customerId"
            defaultValue={isFormEdit ? selectedQuote?.customerId || '' : ''}
            {...register("customerId", { valueAsNumber: true, onChange: (e) => setCustomerId(e.target.value), required: "Customer is required" })}>
            <option key="none" value="" hidden>Select Customer</option>
            {customerList.map((customer, index) =>
              <option key={index} value={customer.customerId}>{customer.customerName}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.customerId && errors.customerId.message}
          </small>
        </div>
        <div className="col-6 mb-2">
          <label htmlFor="quoteName" className="form-label">Invoice Id</label>
          <input type="text" className="form-control" id="quoteName"
            defaultValue={isFormEdit ? selectedQuote?.quoteName || '' : ''}
            {...register("quoteName", { required: "Quote Name is required" })}
          />
          <small className='form-error-message'>
            {errors?.quoteName && errors.quoteName.message}
          </small>
        </div>

        <div className="col-6 mb-2">
          <label htmlFor="quoteDate" className="form-label">Invoice Date:</label>
          <Controller
            defaultValue={new Date()}
            control={control}
            name='quoteDate'
            register={register}
            rules={{ required: "Invoice date is required" }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <ReactDatePicker
                className='datepicker-field'
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
                id="quoteDate"
                dateFormat='yyyy-MM-dd'
                ref={(elem) => {
                  elem && ref(elem.input);
                }}
              />
            )}
          />
          <small className='form-error-message'>
            {errors?.quoteDate && errors.quoteDate.message}
          </small>
        </div>

        <div className="col-6 mb-2">
          <label htmlFor="paymentDueDate" className="form-label">Invoice Due Date:</label>
          <Controller
            defaultValue={new Date()}
            control={control}
            name='paymentDueDate'
            register={register}
            rules={{ required: "Payment due date is required" }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <ReactDatePicker
                className='datepicker-field'
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
                id="paymentDueDate"
                dateFormat='yyyy-MM-dd'
                ref={(elem) => {
                  elem && ref(elem.input);
                }}
              />
            )}
          />
          <small className='form-error-message'>
            {errors?.paymentDueDate && errors.paymentDueDate.message}
          </small>
        </div>
        {
          (isSuperAdmin(userType) || isAccountAdmin(userType)) &&
          <div className="col-12 mb-2 mt-3">
            <div className="form-check">
              <input className="form-check-input align-middle" type="checkbox" value="" id="active"
                defaultChecked={getActiveDefaultValue}
                {...register("active")}
              />
              <label className="form-check-label align-middle " htmlFor="active"> Active</label>
              <small className='form-error-message'>
                {errors?.active && errors.active.message}
              </small>
            </div>
          </div>
        }
      </form>
      <ModalRender modal={modal} handleSubmit={handleSubmit} onSubmit={onSubmit} setModal={setModal} toggle={toggle} isFormEdit={isFormEdit} formType={"quote"} toggleFormDisplay={toggleFormDisplay} />
    </div>
  )
}

export default QuoteForm
