import React, { useEffect, useState, useRef } from 'react'

function EditFormRenderer({
  data,
  api,
  node,
  column,
  toggleFormDisplay,
  submitData,
  easyEdit = false,
  editButtonRef
}) {
  const currentData = data["Edit"]
  const buttonRef = useRef(null);
  const buttonId = `save-button-${node?.rowPinned === 'top' ? 'top' : node.rowIndex}`

  const handleFocus = () => {
    api.ensureColumnVisible('Edit');
  }

  const handleSubmitData = async () => {
    await submitData(node, api)
  }

  return (
    <div className='cell-renderer-content d-flex flex-row-reverse top-row'>
      {
        (!easyEdit) ?
          (<button
            className="btn btn-primary btn-sm"
            type="submit"
            tabIndex={0}
            onClick={() => toggleFormDisplay(true, currentData.id)}
          >
            <i className='fa-solid fa-pen'></i>
          </button>)
          :
          (
            (node?.rowPinned === 'top' || data?.['Payments'] < 1) &&
            <button
              className="btn btn-primary btn-sm btn-editor"
              ref={editButtonRef}
              id={buttonId}
              type="button"
              disabled={node?.rowPinned === 'top' ? !data?.["FormCompleted"] : !data?.["FormUpdated"]}
              tabIndex={0}
              onFocus={handleFocus}
              onClick={async () => await submitData(node, api)}
              // onClick={handleSubmitData}
            >
              <i className={`fa-solid ${node.rowPinned === 'top' ? 'fa-plus' : 'fa-check'}`}></i>
            </button>
          )
      }
    </div>
  )
}

export default EditFormRenderer