import React from 'react';



const PayrollRunNotesTooltip = ({ data }) => {
  return (
    !(data["Notes"] === "" || data["Notes"] === null || data["Notes"] === undefined) && !data["Pay Method Type"] !== 3 &&
    <div
      className="ag-custom-tooltip"
    >
      <span>{data["Notes"]}</span>
    </div>
  );
};

export default PayrollRunNotesTooltip