import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";

export const Auth0ProviderWithNavigate = ({ children }) => {
  const domain = process.env.REACT_APP_DOMAIN;
  const clientId = process.env.REACT_APP_SAP_CLIENT_ID;
  const redirectURI = process.env.REACT_APP_AUTH0_REDIRECT_URI;
  const audience = process.env.REACT_APP_API_AUDIENCE
  if (!(domain && clientId)) {
    return null;
  }
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectURI,
        audience: audience
      }}
      issuer={domain}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};
