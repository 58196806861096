import React from 'react'
import { formatPhoneNumber } from '../../../../utils/formatUtils'

function PhoneAndMobileRenderer(props) {
  const data = props.data["PhoneAndMobile"]
  return (
    <div className="phone-and-mobile-renderer-container">
      <div className="phone">
        {formatPhoneNumber(data['phone'])}
      </div>
      <div className="mobile">
        {formatPhoneNumber(data['mobile'])}
      </div>
    </div>
  )
}

export default PhoneAndMobileRenderer