import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import AccountForm from './AccountForm';
import './css/accounts.css'
import EditFormRenderer from '../Templates/CellRenderers/Commons/EditFormRenderer';
import ContentHeader from '../Commons/Layouts/ContentHeader';
import { fetchAccountById, fetchAccounts } from '../../services/accountServices';
import PhoneAndMobileRenderer from '../Templates/CellRenderers/Commons/PhoneAndMobileRenderer';
import _ from 'lodash';
import { isABMUser, isAccountAdmin, isAccountUser, isSuperAdmin } from '../../utils/roleUtils';
import { formatPhoneNumber } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAccountData, deleteSelectedAccountData, setAccountsListData } from '../../redux/account/accountReducer';

function Accounts() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const accountState = useSelector((state) => state.account);

  const toggleFormDisplay = (isEdit = false, accountId = null) => {
    if (isFormHidden) {
      setSelectedAccountId(accountId);
    } else {
      setSelectedAccountId(null);
    }

    if (modal) {
      setModal(!modal);
    }
    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 50);
  }

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    if (isFormDirty) {
      toggle();
      return;
    }
    toggleFormDisplay()
  }

  const defaultColumnDefs = [
    { field: '#', width: 50, minWidth: 50, suppressSizeToFit: true, cellClass: 'no-borders', cellStyle: { fontWeight: 'bold' } },
    { field: 'Account Name', minWidth: 150, autoHeight: true, getQuickFilterText: params => { return params.value; } },
    { field: 'Phone', minWidth: 150, autoHeight: true, valueFormatter: params => { return formatPhoneNumber(params.value) }, getQuickFilterText: params => { return formatPhoneNumber(params.value); } },
    { field: 'Mobile', minWidth: 150, autoHeight: true, valueFormatter: params => { return formatPhoneNumber(params.value) }, getQuickFilterText: params => { return formatPhoneNumber(params.value); } },
    { field: 'TaxId', minWidth: 150, autoHeight: true, getQuickFilterText: params => { return params.value; } },
    {
      field: 'Edit',
      minWidth: 150,
      headerName: '',
      cellClass: 'd-flex flex-row-reverse',
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay }
    },
  ]

  const columnDefsWithRow = [
    { field: '#', width: 50, minWidth: 50, suppressSizeToFit: true },
    { field: 'Account Name', minWidth: 150, autoHeight: true, getQuickFilterText: params => { return params.value; } },
    { field: 'PhoneAndMobile', minWidth: 150, headerName: 'Contacts', cellRenderer: PhoneAndMobileRenderer, getQuickFilterText: params => { return params.value; } },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [accountList, setAccountList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton();
  }, [])

  useEffect(() => {
    setAccountList(accountState.data);
  }, [accountState.data]);

  useEffect(() => {
    setColumnDefs(isFormHidden ? defaultColumnDefs : columnDefsWithRow);
  }, [isFormHidden]);

  useEffect(() => {
    let data = [];
    _.each(accountList, (account, index) => {
      data.push({
        '#': index + 1,
        'Account Id': account.accountId,
        'Account Name': account.accountName,
        'Phone': account.phone,
        'Mobile': account.cell,
        'TaxId': account.taxId,
        'PhoneAndMobile': { phone: account.phone, mobile: account.cell },
        'Edit': { id: account.accountId }
      });
    });
    setRowData(data);
  }, [accountList])

  useEffect(() => {
    fillSelectedAccount(selectedAccountId);
  }, [selectedAccountId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef();

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    sizeColumnsToFit(gridRef.current);
  }, []);

  const init = async () => {
    const response = await getAccountsData();
    dispatch(setAccountsListData(_.isArray(response) ? response : [response]));

    if (isAccountAdmin(userType) || isAccountUser(userType)) {
      setSelectedAccountId(accountId);
      setIsFormEdit(true);
      setIsFormHidden(false);
    }
  }

  const getAccountsData = async () => {
    if (isAccountAdmin(userType) || isAccountUser(userType)) {
      return await fetchAccountById(accountId);
    }
    
    return await fetchAccounts();
  }

  const fillSelectedAccount = (id) => {
    const account = _.find(accountList, { accountId: id });
    setSelectedAccount(account);
  }

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    sizeColumnsToFit(params);
  }

  const onCellClicked = (event) => {
    if (event.colDef.field === "Edit") {
      return
    }
    if (isABMUser(userType)) {
      event.node.setSelected(true);
      dispatch(setSelectedAccountData(
        {
          accountId: event.data["Account Id"],
          accountName: event.data["Account Name"]
        }
      ));
    }
  }

  const onRowDataUpdated = (event) => {
    gridRef.current.api.forEachNode((rowNode, index) => {
      if (isABMUser(userType))
        if (rowNode.data["Account Id"] === selectedAccountState.accountId) {
          rowNode.setSelected(true);
        }
    });
  }

  const clearAccountFilter = (event) => {
    dispatch(deleteSelectedAccountData());
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.deselectAll();
    }
  }


  const sizeColumnsToFit = (params) => {
    if(isSuperAdmin(userType) || isABMUser(userType)) {
      params.api.sizeColumnsToFit();
    }
  }

  const focusOnOpenOrCloseButton = () => {
    openOrCloseButtonRef.current.focus();
  }

  return (
    <div>
      <div className={`content-header-container d-flex flex-row align-items-center ${isSuperAdmin(userType) || isABMUser(userType) ? '' : 'd-none'}`}>
        <div className='me-auto'>
          <ContentHeader title="Accounts" dataCount={accountList.length} filterData={filterData} />
        </div>
        {(selectedAccountState?.accountName !== '' && !isSuperAdmin(userType)) && <button className="btn btn-lg btn-primary me-2 " onClick={clearAccountFilter} >Remove Filter</button>}
        <button
          className={`btn btn-lg ${isFormHidden ? 'btn-primary' : 'btn-secondary'}`}
          type="submit"
          onClick={() => toggleFormCancelDisplay()}
          ref={openOrCloseButtonRef}
        >
          {isFormHidden ? 'Add Account' : 'Close'}
        </button>
      </div>
      <div className="content-body-container row">
        <div className={`${isFormHidden ? 'col-12' : 'col-12 col-lg-5 min-vh-25'} ${isSuperAdmin(userType) || isABMUser(userType) ? '' : 'd-none'} mb-2`}>
          <div className={`ag-theme-alpine content-section-container`} >
            <AgGridReact
              className='no-header'
              rowData={rowData}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onColumnSizeChanged={onColumnsSizeChanged}
              onGridSizeChanged={onGridSizeChanged}
              rowHeight={70}
              onCellClicked={onCellClicked}
              onRowDataUpdated={onRowDataUpdated}
            >
            </AgGridReact>
          </div>
        </div>
        <div className={`${isSuperAdmin(userType) || isABMUser(userType) ? 'col-12 col-lg-7' : 'col-12'} ${isFormHidden ? 'd-none' : ''} mb-2`}>
          <AccountForm
            isFormEdit={isFormEdit}
            selectedAccount={selectedAccount}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
          />
        </div>
      </div>
    </div>
  )
}

export default Accounts
