import React from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Link } from 'react-router-dom';

function ContentHeader({
  title,
  dataCount,
  filterData,
  setBalanceFilter,
  hideTotal = false,
  hideSearch = false,
  showStatus = false,
  showBalanceFilter = false,
  balanceDisabledCheck = false,
  applyCustomFilter,
  editablePage = false,
  date = null,
  backButton = false,
  backButtonLink = "",
}) {

  const handleChange = (e) => {
    filterData(e.target.value);
  }

  const handleBalanceFilterChange = (e) => {
    setBalanceFilter(e.target.checked);
  }

  return (
    <div className="d-flex flex-row flex-wrap align-items-center">
      {/* <div className=' me-3'> */}
      <div className={` ${backButton ? 'me-3' : 'd-none'}`}>
        <Link className="back-button" to={backButtonLink}>
          <i className="fa-solid fa-arrow-left"></i>
        </Link>
      </div>
      <div className={`content-title ${showStatus && !editablePage ? 'me-2' : ''}`}>
        {title}
      </div>
      <div className={`icon ${showStatus && !editablePage ? '' : 'd-none'}`}>
        <i id="locked-tooltip" className="fa-solid fa-lock"></i>
        <ReactTooltip
          anchorId="locked-tooltip"
          className='tooltip-container'
          place="top"
          content="This payroll run has already been commited"
        />
      </div>
      <div className={`divider ms-4 me-3 ${hideTotal && hideSearch ? 'd-none' : ''}`}></div>
      <div className={`content-data-count me-4 ${hideTotal ? 'd-none' : ''}`}>
        {dataCount} Total
      </div>

      <div className={`search-input-field-container ${hideSearch ? 'd-none' : ''}`}>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">
            <i className="fa-solid fa-search"></i>
          </span>
          <input
            type="text"
            className="search-input-field form-control shadow-none"
            placeholder="Search..."
            id="search"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>

      <div className={`form-check balance-filter-container ${showBalanceFilter ? '' : 'd-none'}`}>
        {
          title === "Expenses" &&
          <div id='customer-filter' className='d-flex flex-row '>
            <div className='col-12'>
              <span id='balance-filter'>
                <label className="form-check-label" htmlFor="balanceFilterCheckbox">
                  <input className="form-check-input" type="checkbox" value="" disabled={balanceDisabledCheck} id="balanceFilterCheckbox" defaultChecked={true} onChange={(e) => handleBalanceFilterChange(e)} />
                  With Balances
                </label>
              </span>
            </div>
            <div className='col-12'>
              <span id='customer-filter'>
                <label className="form-check-label" htmlFor="customerFilterCheckbox">
                  <input className="form-check-input" type="checkbox" value="" id="customerFilterCheckbox" defaultChecked={true} onChange={applyCustomFilter} />
                  Show Both Company Types
                </label>
              </span>
            </div>
          </div>
        }

        {
          title === "Quotes" &&
          <span id='customer-filter'>
            <label className="form-check-label" htmlFor="customerFilterCheckbox">
              <input className="form-check-input" type="checkbox" value="" id="customerFilterCheckbox" defaultChecked={true} onChange={applyCustomFilter} />
              Show Both Customer Types
            </label>
          </span>
        }

        {
          title === "Customers" &&
          <div id='customer-filter' className='d-flex flex-row '>
            <div className='col-12'>
              <label className="form-check-label" htmlFor="allCheckbox">
                <input
                  className="form-check-input"
                  type="radio"
                  id="allCheckbox"
                  defaultChecked={true}
                  onChange={() => { applyCustomFilter("Customer Type", null) }}
                  name='filter'
                />

                All
              </label>
            </div>
            <div className='col-12'>
              <label className="form-check-label" htmlFor="customerCheckbox">
                <input
                  className="form-check-input"
                  type="radio"
                  id="customerCheckbox"
                  defaultChecked={false}
                  onChange={() => { applyCustomFilter("Customer Type", 0) }}
                  name='filter'
                />
                Customer
              </label>
            </div>
            <div className='col-12'>
              <label className="form-check-label" htmlFor="vendorCheckbox">
                <input
                  className="form-check-input"
                  type="radio"
                  id="vendorCheckbox"
                  defaultChecked={false}
                  onChange={() => { applyCustomFilter("Customer Type", 1) }}
                  name='filter'
                />
                Vendor
              </label>
            </div>
          </div>
        }
      </div>



      {balanceDisabledCheck && (
        (<ReactTooltip
          anchorId="balance-filter"
          className='tooltip-container'
          place="top"
          content="Filtering cannot be changed during editing"
        />)
      )}
    </div>

  )
}

export default ContentHeader