import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const reportTemplateReducer = createSlice({
  name: "reportTemplate",
  initialState,
  reducers: {
    setReportTemplatesListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setReportTemplateData: (state, action) => {
      const index = state.data.findIndex(reportTemplate => reportTemplate.reportTemplateId === action.payload.reportTemplateId);
      const reportTemplatesListData = [...state.data];
      reportTemplatesListData[index] = action.payload;
      return {
        ...state,
        data: reportTemplatesListData
      }
    },
    addReportTemplateData: (state, action) => {
      const reportTemplatesListData = [...state.data];
      reportTemplatesListData.push(action.payload);
      return {
        ...state,
        data: reportTemplatesListData
      }
    },
  },
});

export const {
  setReportTemplatesListData,
  setReportTemplateData,
  addReportTemplateData,
} = reportTemplateReducer.actions;

export default reportTemplateReducer.reducer;