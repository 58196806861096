import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import _ from 'lodash';
import { addPayrollRun, addOneOffPayrollRun } from '../../services/payrollRunServices';

import { showToast } from '../../utils/toastUtils';
import { useSelector } from 'react-redux';
import ReactDatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import { nextPayrollDateRange } from '../../utils/payrollRunUtils';
import { isABMUser } from '../../utils/roleUtils';
import { sendSingleEmail } from '../../services/emailServices';
import moment from 'moment';
import { selectStyle } from '../../utils/styleUtils';
import Select from 'react-select'

function OneOffPayrollRunForm({
  isFormEdit = false,
  isFormHidden,
  payrollRunList,
  payrollSettings,
  userList,
  oneOffMinDate,
}) {
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, clearErrors, reset, setFocus, control, formState: { errors } } = useForm();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const settingsState = useSelector((state) => state.settings);
  const currentUserState = useSelector((state) => state.auth.user);
  const [userOptions, setUserOptions] = useState([]);


  // // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("payrollRunDate");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    const users = userList.map((user, index) => ({
      value: user.userId,
      label: `${user.firstName} ${user.lastName}`,
    }));
    setUserOptions(users);
  }, [userList])

  const onSubmit = async (payload) => {
    let response = null;

    var payrollRunDate = moment(payload.payrollRun.payrollRunDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    _.set(payload, 'payrollRun.startDate', payrollRunDate);
    _.set(payload, 'payrollRun.endDate', payrollRunDate);
    _.set(payload, 'payrollRun.payrollRunDate', payrollRunDate);
    _.set(payload, 'payrollRun.processingDate', moment().hour(7).minute(0).second(0).format('YYYY-MM-DD'));
    _.set(payload, 'payrollRun.payrollRunId', 0);
    _.set(payload, 'payrollRun.accountId', isABMUser(userType) && selectedAccountState.accountId !== null ? selectedAccountState.accountId : accountId);
    _.set(payload, 'userId', payload.userId.value);
    _.set(payload, 'payrollRun.payrollRunType', 2);
    response = await addOneOffPayrollRun(payload);
    showToast({
      type: `${response.ok ? 'success' : 'error'}`,
      message: `${response.ok ? 'Successfully added' : 'Failed to add'} Payroll Run.`
    });

    if (response.ok) {
      if (!isFormEdit) {
        const data = await response.json();
        const newPayrollRunId = data.payrollRunId;

        navigate("/edit-payroll-run", { state: { payrollRunId: newPayrollRunId, status: false } });

        // Send email notification to default email
        await sendPayrollRunEmailNotification({
          toEmail: settingsState.accountSettings.defaultEmail || currentUserState.email,
          subject: `Payroll Run Created: ${selectedAccountState?.accountName || currentUserState?.account?.accountName}`,
          body: `Payroll Notification   : ${selectedAccountState?.accountName || currentUserState?.account?.accountName}\n` +
            `Payroll Run Date      : ${moment(payload.startDate).format("YYYY-MM-DD")} - ${moment(payload.endDate).format("YYYY-MM-DD")}\n` +
            `Created By               : ${currentUserState.firstName} ${currentUserState.lastName}\n` +
            `Total Paystubs          : ${data.payrollRunLineItem?.length}`
        });

        // Send email notification to payroll notification email
        if (!_.isNil(settingsState.accountSettings.payrollNotificationEmail)) {
          await sendPayrollRunEmailNotification({
            toEmail: settingsState.accountSettings.payrollNotificationEmail,
            subject: `Payroll Run Created: ${selectedAccountState?.accountName || currentUserState?.account?.accountName}`,
            body: `Payroll Notification   : ${selectedAccountState?.accountName || currentUserState?.account?.accountName}\n` +
              `Payroll Run Date      : ${moment(payload.startDate).format("YYYY-MM-DD")} - ${moment(payload.endDate).format("YYYY-MM-DD")}\n` +
              `Created By               : ${currentUserState.firstName} ${currentUserState.lastName}\n` +
              `Total Paystubs          : ${data.payrollRunLineItem?.length}`
          });
        }
      }
    }
  }

  const sendPayrollRunEmailNotification = async (payload) => {
    await sendSingleEmail(payload);
  }

  return (
    <>
      <div className='content-section-container'>
        <div className='d-flex flex-row align-items-center mb-5'>
          <div className='form-title me-auto'>
            {`${isFormEdit ? 'Update' : 'Add'} Payroll Run`}
          </div>
          <button className="btn btn-primary" form='customer-form' type="submit">Create Payroll Run</button>
        </div>
        <form className="row form-container" id="customer-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="payrollRunDate" className="form-label">Payroll Run Date:</label>
            <Controller
              defaultValue={new Date()}
              control={control}
              name='payrollRun.payrollRunDate'
              register={register}
              rules={{ required: "Payroll run date is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactDatePicker
                  className='datepicker-field'
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  id="payrollRunDate"
                  dateFormat='yyyy-MM-dd'
                  minDate={oneOffMinDate}
                  ref={(elem) => {
                    elem && ref(elem.input);
                  }}
                />
              )}
            />
            <small className='form-error-message'>
              {errors?.payrollRunDate && errors.payrollRunDate.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="userId" className="form-label">User</label>
            <div className="custom-selector">
              <Controller
                name="userId"
                control={control}
                rules={{ required: "User is required" }}
                render={({ field }) => (
                  <Select
                    onChange={field.onChange}
                    value={field.value}
                    // ref={accountIdRef}
                    options={userOptions}
                    className="expense-select"
                    placeholder={'Enter user'}
                    // defaultValue={accountIdValue}
                    id="accountId"
                    styles={selectStyle}
                    isClearable
                    tabIndex={1}
                  />
                )}
              />
            </div>
            <small className='form-error-message'>
              {errors?.userId && errors.userId?.message}
            </small>
          </div>

        </form>
      </div>
    </>
  )
}

export default OneOffPayrollRunForm
