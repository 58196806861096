import React from 'react';

const VacationPayUnit =(props) => {

  return (
    props.data["Vacation Type"] === 1 && props.isEditable === true ?
    <div
      className="ag-custom-tooltip"
    >
        <span>This employee's vacation pay is calculated by day</span>
    </div>
    :
    <div></div>
  );
};

export default VacationPayUnit