import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  selectedAccount: {
    accountId: null,
    accountName: '',
  }
};

const accountReducer = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccountsListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setAccountData: (state, action) => {
      const index = state.data.findIndex(account => account.accountId === action.payload.accountId);
      const accountsListData = [...state.data];
      accountsListData[index] = action.payload;
      return {
        ...state,
        data: accountsListData
      }
    },
    addAccountData: (state, action) => {
      const accountsListData = [...state.data];
      accountsListData.push(action.payload);
      return {
        ...state,
        data: accountsListData
      }
    },
    setSelectedAccountData: (state, action) => {
      return {
        ...state,
        selectedAccount: {
          accountId: action.payload.accountId,
          accountName: action.payload.accountName
        },
      }
    },
    deleteSelectedAccountData: (state, action) => {
      return {
        ...state,
        selectedAccount: {
          accountId: null,
          accountName: '',
        },
      }
    }
  },
});

export const {
  setAccountsListData,
  setAccountData,
  addAccountData,
  setSelectedAccountData,
  deleteSelectedAccountData
} = accountReducer.actions;

export default accountReducer.reducer;