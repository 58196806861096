import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import ContactForm from './ContactForm';
import './css/contacts.css'
import ContentHeader from '../Commons/Layouts/ContentHeader';
import NameAndEmailRenderer from '../Templates/CellRenderers/Contacts/NameAndEmailRenderer';
import ProfilePictureRenderer from '../Templates/CellRenderers/Commons/ProfilePictureRenderer';
import PhoneAndMobileRenderer from '../Templates/CellRenderers/Commons/PhoneAndMobileRenderer';
import EditFormRenderer from '../Templates/CellRenderers/Commons/EditFormRenderer';
import { fetchContacts, fetchContactsFiltered } from '../../services/contactServices';
import { formatPhoneNumber } from '../../utils/formatUtils';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { isSuperAdmin, isABMUser } from '../../utils/roleUtils';
import { setContactsListData } from '../../redux/contact/contactReducer';
import { setCustomersListData } from '../../redux/customer/customerReducer';
import { fetchCustomersByAccount } from '../../services/customerServices';

function Contacts() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId)
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const contactState = useSelector((state) => state.contact);

  const toggleFormDisplay = (isEdit = false, contactId = null) => {
    fillCustomerList();
    if (isFormHidden) {
      setSelectedContactId(contactId);
    } else {
      setSelectedContactId(null);
    }

    if(modal) {
      setModal(!modal)
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 50);
  }

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    if(isFormDirty) {
      toggle()
      return
    }
    toggleFormDisplay()
  }

  const defaultColumnDefs = [
    { field: '#', width: 50, minWidth: 50, suppressSizeToFit: true, cellClass: 'no-borders', cellStyle: { fontWeight: 'bold' } },
    { field: 'ProfilePicture', minWidth: 70, headerName: '', cellRenderer: ProfilePictureRenderer, width: 80, suppressSizeToFit: true, cellClass: 'no-borders' },
    { field: 'Name', minWidth: 150,  cellClass: 'no-borders', getQuickFilterText: params => { return params.value; } },
    { field: 'Customer', minWidth: 150, cellClass: 'no-borders', getQuickFilterText: params => { return params.value; } },
    { field: 'Phone', minWidth: 150, cellClass: 'no-borders', valueFormatter: params => { return formatPhoneNumber(params.value) }, getQuickFilterText: params => { return params.value; } },
    { field: 'Mobile', minWidth: 150, cellClass: 'no-borders', valueFormatter: params => { return formatPhoneNumber(params.value) }, getQuickFilterText: params => { return params.value; } },
    { field: 'Email', minWidth: 150, cellClass: 'no-borders', getQuickFilterText: params => { return params.value; } },
    {
      field: 'Edit',
      minWidth: 150,
      headerName: '',
      cellClass: 'd-flex flex-row-reverse',
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay }
    },
  ]

  const columnDefsWithRow = [
    { field: '#', width: 50, minWidth: 50, suppressSizeToFit: true },
    { field: 'ProfilePicture', minWidth: 70, headerName: '', cellRenderer: ProfilePictureRenderer, width: 80, suppressSizeToFit: true },
    { field: 'NameAndEmail', minWidth: 150, headerName: 'Name', cellRenderer: NameAndEmailRenderer },
    { field: 'PhoneAndMobile', minWidth: 150, headerName: 'Contacts', cellRenderer: PhoneAndMobileRenderer },
  ];


  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [contactList, setContactList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null)
  const [modal, setModal] = useState(false);
  const [customersFetched, setCustomersFetched] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton()
  }, [])

  useEffect(() => {
    setContactList(contactState.data);
  }, [contactState.data])

  useEffect(() => {
    setColumnDefs(isFormHidden ? defaultColumnDefs : columnDefsWithRow);
  }, [isFormHidden]);

  useEffect(() => {
    let data = [];
    _.each(contactList, (contact, index) => {
      data.push({
        '#': index + 1,
        'ProfilePicture': { url: `images/profile-picture-fallback.png` },
        'Name': `${contact.firstName} ${contact.lastName}`,
        'Email': contact.email,
        'Phone': contact.phone,
        'Mobile': contact.cell,
        'Customer': contact.customer.customerName,
        'PhoneAndMobile': { phone: contact.phone, mobile: contact.cell },
        'NameAndEmail': { name: `${contact.firstName} ${contact.lastName}`, email: contact.email },
        'Edit': { id: contact.contactId }
      });
    });
    setRowData(data);
  }, [contactList])

  useEffect(() => {
    fillSelectedContact(selectedContactId);
  }, [selectedContactId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const init = async () => {
    const response = await getContactsData();
    dispatch(setContactsListData(response));
  }

  const getContactsData = async () => {
    if(isSuperAdmin(userType)) {
      return await fetchContacts();
    }
    
    if (isABMUser(userType)) {
      return selectedAccountState.accountId === null ? await fetchContactsFiltered(accountId) : await fetchContactsFiltered(selectedAccountState.accountId); 
    }

    return await fetchContactsFiltered(accountId);
  }
  
  const fillCustomerList = async () => {
    if (!customersFetched) {
      const response = await getCustomersData();
      dispatch(setCustomersListData(response));
      setCustomersFetched(true);
    }
  }

  const getCustomersData = async () => {
    return await fetchCustomersByAccount(userType, selectedAccountState.accountId, accountId);
  }

  const fillSelectedContact = (id) => {
    const contact = _.find(contactList, { contactId: id });
    setSelectedContact(contact);
  }

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  }

  const focusOnOpenOrCloseButton = () => {
    openOrCloseButtonRef.current.focus();
  }

  return (
    <div>
      <div className='content-header-container d-flex flex-row align-items-center'>
        <div className='me-auto'>
          <ContentHeader title="Contacts" dataCount={contactList.length} filterData={filterData} />
        </div>
        <button
          className={`btn btn-lg ${isFormHidden ? 'btn-primary' : 'btn-secondary'}`}
          type="submit"
          onClick={() => toggleFormCancelDisplay()}
          ref={openOrCloseButtonRef}
        >
          {isFormHidden ? 'Add Contact' : 'Close'}
        </button>
      </div>
      <div className="content-body-container row">
        <div className={`${isFormHidden ? 'col-12' : 'col-12 col-lg-5 min-vh-25'} mb-2`}>
          <div className={`ag-theme-alpine content-section-container`} >
            <AgGridReact
              className='no-header'
              rowData={rowData}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onColumnSizeChanged={onColumnsSizeChanged}
              onGridSizeChanged={onGridSizeChanged}
              rowHeight={70}
            >
            </AgGridReact>
          </div>
        </div>
        <div className={`col-12 col-lg-7 ${isFormHidden ? 'd-none' : ''}`}>
          <ContactForm
            isFormEdit={isFormEdit}
            selectedContact={selectedContact}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
          />
        </div>
      </div>
    </div>
  )
}

export default Contacts
