import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { formTitleSelector } from '../../utils/formatUtils'

function ModalRender({
  modal,
  toggle,
  isFormEdit,
  formType,
  toggleFormDisplay,
  args
}) {

  return (
    <Modal isOpen={modal} toggle={toggle} {...args}>
      <ModalHeader toggle={toggle}>{isFormEdit ? "Editing " : "Adding "} {formTitleSelector(formType)}</ModalHeader>
      <ModalBody>
        {isFormEdit ? "There are changes in the form, do you want to save?" : `Do you want to cancel adding a new ${formTitleSelector(formType)}`}
      </ModalBody>
      <ModalFooter>
        <Button color="primary"
          form={isFormEdit ? `${formType}-form` : ""}
          type={isFormEdit ? "submit" : "button"}
          onClick={isFormEdit ? null : () => toggleFormDisplay()}
        >
          Yes
        </Button>{' '}
        <Button color="secondary" onClick={isFormEdit ? () => toggleFormDisplay() : toggle}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalRender