import { reportTypeRenderer } from "./formatUtils";
import { REPORT_TYPES } from "../constants";
import _ from 'lodash'

export function reportTypeFilter(gridRef, reportType) {
  var filterComponent = gridRef.current.api.getFilterInstance('Report Type');
  filterComponent.setModel({
      filterType: 'text',
      type: 'contains',
      filter: reportTypeRenderer(reportType),
  });
  gridRef.current.api.onFilterChanged();
}

export const getReportValue = (label) => {
  const reportTemplate = _.find(REPORT_TYPES, { label });
  return reportTemplate;
}