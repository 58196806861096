import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const quoteReducer = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setQuotesListData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    },
    setQuoteData: (state, action) => {
      const index = state.data.findIndex(quote => quote.quoteId === action.payload.quoteId);
      const quotesListData = [...state.data];
      quotesListData[index] = action.payload;
      return {
        ...state,
        data: quotesListData
      }
    },
    addQuoteData: (state, action) => {
      const quotesListData = [...state.data];
      quotesListData.push(action.payload);
      return {
        ...state,
        data: quotesListData
      }
    },
    deleteQuoteData: (state, action) => {
      const index = state.data.findIndex(quote => quote.quoteId === action.payload);
      const quotesListData = [...state.data];

      if (index > -1) {
        quotesListData.splice(index, 1);
      }

      return {
        ...state,
        data: quotesListData
      }
    },
  },
});

export const {
  setQuotesListData,
  setQuoteData,
  addQuoteData,
  deleteQuoteData,
} = quoteReducer.actions;

export default quoteReducer.reducer;
