import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import Select from 'react-select';
import { useSelector } from "react-redux";
import _ from 'lodash';
import { CUSTOMER_TYPES } from '../../../constants';

const CompanyEditor = forwardRef(({ customerFilter, value, data, api, column, eGridCell }, ref) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const customerState = useSelector((state) => state.customer);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [inputStyle, setInputStyle] = useState({ width: '100%', height: '100%' });
  const [refreshOptions, setRefreshOptions] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(customerState)) {
      const customerType = CUSTOMER_TYPES.find(type => type.label === "Vendor").value;
      let filteredCompanyOptions;

      if(customerFilter()) {
        filteredCompanyOptions = customerState.data.filter(customer => customer.customerType === 2 || customer.customerType === customerType || customer.customerId === data['Customer Id'] || customer.customerId === selectedOption?.value).map((customer) => ({
          value: customer.customerId,
          label: customer.customerName,
        }));
      } else {
        filteredCompanyOptions = customerState.data.filter(customer => customer.customerType === customerType || customer.customerId === data['Customer Id'] || customer.customerId === selectedOption?.value).map((customer) => ({
          value: customer.customerId,
          label: customer.customerName,
        }));
      }
      setCompanyOptions(filteredCompanyOptions);
      setFilteredOptions(filteredCompanyOptions); // Initialize filtered options with all options
    }
  }, [customerState, refreshOptions]);

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      data['Customer Id'] = selectedOption ? selectedOption.value : 0;
      return selectedOption ? selectedOption.label : null;
    },
  }));

  useEffect(() => {
    if (eGridCell) {
      setInputStyle({
        width: `${eGridCell.clientWidth}px`,
        height: `${eGridCell.clientHeight}px`,
      });
    }
  }, [eGridCell]);

  useEffect(() => {
    if (!_.isEmpty(companyOptions)) {
      let currentValue;
      if(selectedOption?.value === null) {
        currentValue = companyOptions?.find((a) => a.value === data["Customer Id"]) || companyOptions[0];
        
      } else {
        currentValue = companyOptions?.find((a) => a.value === selectedOption?.value) || companyOptions[0];
      }
      setSelectedOption(currentValue);
    }
  }, [companyOptions]);

  const customFilter = (option, rawInput) => {
    const inputValue = rawInput.trim().toLowerCase();
    const label = option.label.toLowerCase();
    return label.includes(inputValue);
  };

  const onKeyDown = (event) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      const currentIndex = filteredOptions.findIndex(option => option.value === selectedOption?.value);
      let newIndex = currentIndex;
      if (event.key === 'ArrowUp') {
        newIndex = (currentIndex > 0) ? currentIndex - 1 : filteredOptions.length - 1;
      } else if (event.key === 'ArrowDown') {
        newIndex = (currentIndex < filteredOptions.length - 1) ? currentIndex + 1 : 0;
      }
      setSelectedOption(filteredOptions[newIndex]);
    }
  };

  const handleInputChange = (inputValue) => {
    const filtered = filteredOptions.filter(option =>
      customFilter(option, inputValue)
    );

    setFilteredOptions(filtered);
  };

  const handleMenuOpen = () => {
    setRefreshOptions(!refreshOptions);
  }

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
      <Select
        value={selectedOption}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={companyOptions}
        className="company-select"
        classNamePrefix="react-select"
        placeholder="Manually Enter Company"
        id="company"
        isClearable
        autoFocus={true}
        openMenuOnFocus
        menuPortalTarget={document.body}
        menuPosition="fixed"
        onMenuOpen={handleMenuOpen}
        styles={{
          control: (provided) => ({
            ...provided,
            width: inputStyle.width || '100%',
            height: inputStyle.height || '100%',
          }),
        }}
        onKeyDown={onKeyDown}
      />
    </div>
  );
});

export default CompanyEditor;
