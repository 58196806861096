import _ from 'lodash';

export function getTotalPayments(payments) {
  return _.sumBy(payments, 'paymentAmount') || 0;
}

export function getInvoiceBalance(invoice) {
  const totalPayments = getTotalPayments(_.get(invoice, 'payment', []));
  const totalInvoice = _.get(invoice, 'totalInvoice', 0);

  return totalInvoice - totalPayments;
}

export function getExpenseBalance(expense) {
  const totalPayments = getTotalPayments(_.get(expense, 'payment', []));
  const totalExpense = _.get(expense, 'total', 0);

  return totalExpense - totalPayments;
}