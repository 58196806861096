import { get, post, patch } from "./apiService";
import { isABMUser } from '../utils/roleUtils';

export const fetchGLCodes = async () => {
  return get({ path: 'GLCode' });
}

export const fetchGLCodesFiltered = async (accountId) => {
  return get({ path: `GLCode/filtered` });
}

export const fetchGLCodeFilteredByType = async (id) => {
  return get({ path: `GLCode/accountType/${id}` });
}

export const fetchGLCodeFilteredByAccountAndType = async (accountId, accountTypeId) => {
  return get({ path: `GLCode/accountTypesFilteredByAccount/${accountTypeId}` });
}


export const addGLCode = async (payload) => {
  return post({ path: 'GLCode', body: payload });
}

export const updateGLCode = async (payload) => {
  return patch({ path: `GLCode/update/${payload.glCodeId}`, body: payload });
}

export const fetchGLCodesByUserType = async (userType, selectedAccountId, accountId, accountTypeId) => {
  let response = null;
  if (isABMUser(userType)) {
    response = selectedAccountId === null ? await fetchGLCodeFilteredByAccountAndType(accountId, accountTypeId) : await fetchGLCodeFilteredByAccountAndType(selectedAccountId, accountTypeId);
  }
  else {
    response = await fetchGLCodeFilteredByAccountAndType(accountId, accountTypeId);
  }

  return response;
}