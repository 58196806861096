import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
// import CreatableSelect from 'react-select/creatable';
import { useSelector } from "react-redux";
import _ from 'lodash';
import Select from 'react-select';
import { fetchGLCodesFiltered } from '../../../services/glCodeServices';

const GLCodeEditor = forwardRef(({
  value,
  data,
  api,
  column,
  glCodeList,
  eGridCell
}, ref) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const customerState = useSelector((state) => state.customer);
  // const [companyOptions, setCompanyOptions] = useState([]);
  const [inputStyle, setInputStyle] = useState({ width: '100%', height: '100%' });
  const [options, setOptions] = useState([])
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(glCodeList?.current)) {
      
      setOptions(glCodeList?.current);
      setFilteredOptions(glCodeList?.current);
    }
  }, [glCodeList?.current]);

  useEffect(() => {
    if(!_.isEmpty(options)) {
      const currentValue = options?.find((a) => a.value === data["GL Code"]) || options?.[0];
      setSelectedOption(currentValue);
    }
  }, [options])

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      if (selectedOption.value) {
        data['GL Code Name'] = selectedOption.label
      } else {
        data['GL Code Name'] = 'None'
      }
      return selectedOption ? selectedOption.value : null;
    },
  }));

  useEffect(() => {
    if (eGridCell) {
      setInputStyle({
        width: `${eGridCell.clientWidth}px`,
        height: `${eGridCell.clientHeight}px`,
      });
    }
  }, [eGridCell]);

  const onKeyDown = (event) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      const currentIndex = filteredOptions.findIndex(option => option.value === selectedOption?.value);
      let newIndex = currentIndex;
      if (event.key === 'ArrowUp') {
        newIndex = (currentIndex > 0) ? currentIndex - 1 : filteredOptions.length - 1;
      } else if (event.key === 'ArrowDown') {
        newIndex = (currentIndex < filteredOptions.length - 1) ? currentIndex + 1 : 0;
      }
      setSelectedOption(filteredOptions[newIndex]);
    }
  };

  const handleInputChange = (inputValue) => {
    const filtered = options.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
      <Select
        value={selectedOption}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={onKeyDown}
        options={options}
        className="company-select"
        classNamePrefix="react-select"
        placeholder="Select GL Code"
        id="company"
        isClearable
        autoFocus
        openMenuOnFocus
        select
        styles={{
          control: (provided) => ({
            ...provided,
            width: inputStyle.width || '100%',
            height: inputStyle.height || '100%',
          }),
        }}
      />
    </div>

  );
});

export default GLCodeEditor;