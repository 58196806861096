import React from 'react';

const VacationPayUnit = (props) => {

  return (
    props.data["Pay Method Type"] === 0 && props.isEditable === true ?
    <div
      className="ag-custom-tooltip"
    >
        <span>Employees on a yearly salary has a unit of pay of 1</span>
    </div>
    :
    <div></div>
  );
};

export default VacationPayUnit