import React from 'react';
import {formatCurrency} from '../../../../utils/formatUtils';

function numberRenderer(props) {
  const value = props.value;
  const formatValue = () => {
    return value !== undefined ? formatCurrency(props.value) : undefined;
  };

  return (
    <span>
      {formatValue()}
    </span>
  );
};

export default numberRenderer